<script>
    import Gallery from "../../components/Gallery.svelte";
    import Videos from "../../components/Videos.svelte";
    import Articles from "../../components/Articles.svelte";
    import Testimonial from "../../components/Testimonial.svelte"
    import Appointment from "../../components/Appointment.svelte";
    import { urlRoot } from "../../constants";
    import { onMount } from "svelte";

    const title = {
        en: "QUOTES",
        mm: "QUOTES"
    };

    let quotes = [];
    let isLoaded = false;
    let featuredQuote = {};

    async function fetchQuotes() {
        const response = await fetch(`${urlRoot}/quotes`);
        quotes = await response.json();
        if (quotes.length !== 0) {
            quotes.forEach(quote => {
                quote.created = new Date(quote.created).toLocaleString();
                quote.image = `${urlRoot}/files/${quote.image}`;

                if (quote.article_id) {
                    quote.link = `/articles/view/${quote.article_id}`;
                }
                else {
                    quote.link = '/#';
                }
            });
        }
        // Get the featured quote
        featuredQuote = quotes.find(quote => quote.is_featured);
        // Remove the featured quote from the list
        quotes = quotes.filter(quote => !quote.is_featured);
        isLoaded = true;
    }

    onMount(fetchQuotes);
</script>

<svelte:head>
    <title>Blogs | NL Mung | nlmung.com</title>
    <meta name="description" content="A blog by an experienced business coach offers expert insights, practical tips, inspiration, latest trends, engaging articles and videos, and valuable business strategies." />
</svelte:head>

{#if isLoaded}
    <Gallery
        featuredImage={featuredQuote}
        title={title}
        images={quotes}
    />
{/if}

<Videos />

<Articles />

<Testimonial />

<Appointment />
