<script>
    import { currentLanguage } from "../stores/languageStore";
    import { onMount } from "svelte";
    import { urlRoot } from "../constants";

    const coachingData = {
        title: {
            en: "MUNG'S MISSION",
            mm: "MUNG'S MISSION"
        },
        description: {
            en: "My mission is to strategically work with business leaders, unlocking their potential, realizing their visions, and helping them attain their next level of success and happiness through coaching and training.\nI strongly believe that all of us are in some kind of business and if we have the opportunity to change in the community we are in. There will be huge transformation in our society.\nLet us strategically transform your business and life to reach your highest possible potential.\nLook forward to welcoming you and your team to one of our programs.",
            mm: "ကျနော့်ခံယူချက်တာဝန်ကတော့ လုပ်ငန်းဦးဆောင်သူတွေနှင့် မဟာဗျူဟာကျကျ အလုပ်လုပ်ရင်း သူတို့ရဲ့ အလားအလာတွေကိုဖော်ထုတ်၊ အနာဂတ်မျှော်မှန်းချက်တွေကို ရှာဖွေကြပြီး တစ်ဆင့်မြင့်တဲ့ အောင်မြင်ပျော်ရွှင်မှုတွေကိုရရှိအောင် Coaching နှင့် Training များဖြင့် ကူညီပေးဖို့ပဲဖြစ်ပါတယ်။\nကျနော်အခိုင်အမာယုံကြည်တယ်။ ကျနော်တို့ တစ်ဦးချင်းစီဟာ လုပ်ငန်းတစ်မျိုးမျိုးမှာ ပါဝင်ဆောင်ရွက်နေကြပြီး အကယ်၍ အခွင့်အရေးသာရခဲ့မယ်ဆိုရင် ကျနော်တို့နေထိုင်ရာ လူမှုပတ်ဝန်းကျင်ကို သိသာထင်ရှားတဲ့ ပြုပြင်ပြောင်းလဲမှုတွေ ပေးစွမ်းနိုင်ကြပါတယ်။\nသင့်လုပ်ငန်းနှင့် ဘဝကို မဟာဗျူဟာကျကျ ပြုပြင်ပြောင်းလဲပြီး သင့်ရဲ့ အမြင့်ဆုံးအလားအလာအထိ တက်လှမ်းပါ။\nကျနော်တို့ ရဲ့ Program တွေမှာ ပါဝင်ဖို့ သင်နှင့်သင့်ရဲ့ Team ကို ဖိတ်ခေါ်လိုက်ပါတယ်။"
        }
    };

    function formatText(text) {
        return text.split("\n").map((paragraph) => {
            return `<p>${paragraph}</p>`;
        }).join("");
    }

    const isMobile = window.innerWidth <= 768;

    let banner_image = null;
    async function fetchBanner() {
        const response = await fetch(`${urlRoot}/banners`);
        const data = await response.json();
        if (data.meet_mung_banner_image) {
            banner_image = `${urlRoot}/files/${data.meet_mung_banner_image}`;
        }
        else {
            banner_image = "/assets/img/coaching-spotlight.jpg";
        }
    }

    onMount(fetchBanner);
</script>

<div class="spotlight">
    {#if isMobile}
        <div class="image">
            <img src={banner_image} alt="Coaching Spotlight" />
        </div>
    {/if}
    <div class="content">
        <div class="title-section">
            <h1>{$currentLanguage === 'en' ? coachingData.title.en : coachingData.title.mm}</h1>
        </div>
        <p class="description">
            {@html formatText($currentLanguage === 'en' ? coachingData.description.en : coachingData.description.mm)}
        </p>
    </div>
    {#if !isMobile}
        <div class="image">
            <img src={banner_image} alt="Coaching Spotlight" />
        </div>
    {/if}
</div>

<style>
    .spotlight {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border-radius: 20px;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .spotlight {
            flex-direction: column;
            gap: 20px;
        }
    }

    .content {
        flex: 1;
        padding-right: 20px;
    }

    .image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image img {
        border-radius: 20px;
        max-width: 100%;
        max-height: 400px;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        .image {
            max-width: 100%;
        }
    }

    .title-section {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }

    h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 36px;
        margin: 0;
    }

    .description {
        font-size: 22px;
        font-family: "Roboto Flex", sans-serif;
        margin-top: 20px;
        line-height: 1.5;
        text-align: justify;
    }

    @media (max-width: 768px) {
        .description {
            font-size: 18px;
            text-align: left;
        }
    }
</style>
