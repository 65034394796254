<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let constants = [];

    async function fetchConstants() {
        const response = await fetch(`${urlRoot}/constants`);
        constants = await response.json();
    }
    onMount(fetchConstants);
</script>

<Breadcrumb path="/admin/constants" />
<div class="constants-list">
    <h2>Constant List</h2>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Value</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each constants as constant, index (constant.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{constant.name}</td>
                    <td>{constant.value}</td>
                    <td>
                        <button on:click={() => navigate(`/admin/constants/edit/${constant.id}`)}>Edit</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .constants-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
