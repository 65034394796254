<script>
    import TitleSection from "./TitleSection.svelte";
    import TextSection from "./TextSection.svelte";
    import MilestoneContainer from "./MilestoneContainer.svelte";
    import { onMount } from "svelte";
    import { urlRoot } from "../constants";

    export let id = '';
    const title = {
        en: "MUNG’S WORKING MILESTONE",
        mm: "MUNG’S WORKING MILESTONE"
    };

    const subtitle = {
        en: "LET THE NUMBERS SPEAK FOR YOUR DECISIONS!",
        mm: "မိတ်ဆွေရဲ့ ဆုံးဖြတ်ချက်အတွက် ကိန်းဂဏန်းတွေက စကားပြောပါစေ.."
    };

    const paragraphs = {
        en: [
            "My greatest opportunities unfold when I activate my mission of strategically work with business leaders, unlocking their potential, realizing their visions, and helping them attain their next level of success and happiness through coaching and training.",
            "By far these are my Happiness:"
        ],
        mm: [
            "ကျနော့်ခံယူချက်တာဝန်ဖြစ်တဲ့  “လုပ်ငန်းဦးဆောင်သူတွေနှင့် မဟာဗျူဟာကျကျ အလုပ်လုပ်ရင်း သူတို့ရဲ့ အလားအလာတွေကိုဖော်ထုတ်၊ အနာဂတ်မျှော်မှန်းချက်တွေကို ရှာဖွေကြပြီး တစ်ဆင့်မြင့်တဲ့ အောင်မြင်ပျော်ရွှင်မှု တွေကိုရရှိအောင် Coaching နှင့် Training များဖြင့် ကူညီပေးဖို့” ဆိုတာကို အကောင်အထည်ဖော်ခြင်းက ကျနော်ရဲ့ အကြီးမားဆုံး အခွင့်အရေးတွေကို ဖွင့်ထုတ်ပေးလိုက်တာပဲ။",
            "ဒါတွေကတော့ ကျနော့်ကို ကျေနပ်ပျော်ရွှင်စေခဲ့တဲ့  ဒီနေ့အထိ ဆောင်ရွက်ချက်တွေပါ။"
        ]
    };

    let business_coaching_leaders = 0;
    let business_coaching_count = 0;
    let strategic_planning_businesses = 0;
    let strategic_planning_count = 0;
    let team_coaching_businesses = 0;
    let team_coaching_count = 0;
    let business_development_training_leaders = 0;
    let leadership_development_training_leaders = 0;
    let nlp_certification_training_leaders = 0;
    let milestones = [];
    async function fetchData() {
        const response = await fetch(`${urlRoot}/stats`);
        const data = await response.json();
        business_coaching_leaders = data.business_coaching_leaders;
        business_coaching_count = data.business_coaching_count;
        strategic_planning_businesses = data.strategic_planning_businesses;
        strategic_planning_count = data.strategic_planning_count;
        team_coaching_businesses = data.team_coaching_businesses;
        team_coaching_count = data.team_coaching_count;
        business_development_training_leaders = data.business_development_training_leaders;
        leadership_development_training_leaders = data.leadership_development_training_leaders;
        nlp_certification_training_leaders = data.nlp_certification_training_leaders;
        milestones = [
            {
                title: {
                    en: "BUSINESS COACHING",
                    mm: "BUSINESS COACHING"
                },
                description: {
                    en: `Coaches ${business_coaching_leaders} leaders through transformative coaching in ${business_coaching_count} sessions`,
                    mm: `ခေါင်းဆောင်ပေါင်း ${englishNumberToBurmese(business_coaching_leaders)} ဦးကို ${englishNumberToBurmese(business_coaching_count)} ကြိမ် Coaching လုပ်ခဲ့ပါတယ်။`
                },
                image: data.business_coaching_image ? `${urlRoot}/files/${data.business_coaching_image}` : "/assets/img/business-coaching.jpeg",
                stats: [
                    { label: { en: "LEADERS", mm: "LEADERS" }, value: `${business_coaching_leaders}+` },
                    { label: { en: "SESSIONS", mm: "SESSIONS" }, value: `${business_coaching_count}+` }
                ]
            },
            {
                title: {
                    en: "STRATEGIC PLANNING",
                    mm: "STRATEGIC PLANNING"
                },
                description: {
                    en: `Facilitation: Facilitated ${strategic_planning_businesses} businesses to refine their strategies in ${strategic_planning_count} sessions.`,
                    mm: `လုပ်ငန်း ${englishNumberToBurmese(strategic_planning_businesses)} ခုကို မဟာဗျူဟာတွေ ပြင်ဆင်ရေးဆွဲဖို့ အကြိမ်ပေါင်း ${englishNumberToBurmese(strategic_planning_count)} ကြိမ်ကူညီခဲ့ပါတယ်။`
                },
                image: data.strategic_planning_image ? `${urlRoot}/files/${data.strategic_planning_image}` : "/assets/img/strategic-planning.jpeg",
                stats: [
                    { label: { en: "BUSINESSES", mm: "BUSINESSES" }, value: `${strategic_planning_businesses}+` },
                    { label: { en: "SESSIONS", mm: "SESSIONS" }, value: `${strategic_planning_count}+` }
                ]
            },
            {
                title: {
                    en: "TEAM COACHING",
                    mm: "TEAM COACHING"
                },
                description: {
                    en: `Team Coaching: Coaches ${team_coaching_businesses} businesses with collaborative coaching across ${team_coaching_count} sessions`,
                    mm: `လုပ်ငန်း ${englishNumberToBurmese(team_coaching_businesses)} ခုကို ${englishNumberToBurmese(team_coaching_count)} ကြိမ် Team Coaching လုပ်ခဲ့ပါတယ်။`
                },
                image: data.team_coaching_image ? `${urlRoot}/files/${data.team_coaching_image}` : "/assets/img/team-coaching.jpeg",
                stats: [
                    { label: { en: "BUSINESSES", mm: "BUSINESSES" }, value: `${team_coaching_businesses}+` },
                    { label: { en: "SESSIONS", mm: "SESSIONS" }, value: `${team_coaching_count}` }
                ]
            },
            {
                title: {
                    en: "BUSINESS DEVELOPMENT TRAINING",
                    mm: "BUSINESS DEVELOPMENT TRAINING"
                },
                description: {
                    en: `Trained ${business_development_training_leaders} leaders to drive business growth and innovation.`,
                    mm: `ခေါင်းဆောင်ပေါင်း ${business_development_training_leaders} ဦးကို လုပ်ငန်းတိုးတက်မှုနှင့် ဆန်းသစ်တီထွင်မှုတွေကို ဦးဆောင်နိုင်ဖို့ သင်တန်းပေးခဲ့ပါတယ်။`
                },
                image: data.business_development_training_image ? `${urlRoot}/files/${data.business_development_training_image}` : "/assets/img/hero.jpeg",
                stats: [
                    { label: { en: "LEADERS", mm: "LEADERS" }, value: `${business_development_training_leaders}+` }
                ]
            },
            {
                title: {
                    en: "LEADERSHIP DEVELOPMENT TRAINING",
                    mm: "LEADERSHIP DEVELOPMENT TRAINING"
                },
                description: {
                    en: `Trained ${leadership_development_training_leaders} leaders with essential leadership skills for success.`,
                    mm: `ခေါင်းဆောင်ပေါင်း ${englishNumberToBurmese(leadership_development_training_leaders)} ဦးကို အောင်မြင်မှုအတွက်လိုအပ်တဲ့ ခေါင်းဆောင်မှုစွမ်းရည်များပို့ချခဲ့ပါတယ်`
                },
                image: data.leadership_development_training_image ? `${urlRoot}/files/${data.leadership_development_training_image}` : "/assets/img/leadership-development.jpeg",
                stats: [
                    { label: { en: "LEADERS", mm: "LEADERS" }, value: `${leadership_development_training_leaders}+` }
                ]
            },
            {
                title: {
                    en: "NLP CERTIFICATE TRAINING",
                    mm: "NLP CERTIFICATE TRAINING"
                },
                description: {
                    en: `Certified ${nlp_certification_training_leaders} leaders in NLP techniques for enhanced communication and leadership.`,
                    mm: `ခေါင်းဆောင် ${englishNumberToBurmese(nlp_certification_training_leaders)} ဦးကို ပေါင်းသင်းဆက်ဆံရေးနှင့် ခေါင်းဆောင်မှုအတွက် NLP နည်းစနစ်များ သင်ကြားပေးပြီး တက်မြောက်ကြောင်း အသိအမှတ်ပြုနိုင်ခဲ့ပါတယ်။`
                },
                image: data.nlp_certification_training_image ? `${urlRoot}/files/${data.nlp_certification_training_image}` : "/assets/img/nlp-certification.jpeg",
                stats: [
                    { label: { en: "LEADERS", mm: "LEADERS" }, value: `${nlp_certification_training_leaders}+` }
                ]
            }
        ];
    }

    function englishNumberToBurmese(number) {
        const numbers = {
            0: "၀",
            1: "၁",
            2: "၂",
            3: "၃",
            4: "၄",
            5: "၅",
            6: "၆",
            7: "၇",
            8: "၈",
            9: "၉"
        };

        return number.toString().split("").map(digit => numbers[digit]).join("");
    }

    onMount(fetchData);
</script>

<TitleSection
    title={title}
    id={id}
/>
<TextSection
    title={subtitle}
    paragraphs={paragraphs}
/>
<MilestoneContainer
    milestones={milestones}
/>
