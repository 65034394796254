<script>
    import { currentLanguage } from "../stores/languageStore";
    import { urlRoot } from "../constants";
    import { onMount } from "svelte";
    export let title = {
        en: "MUNG'S LEARNING MILESTONE",
        mm: "သင်ယူမှုမှတ်တိုင်များ"
    };

    export let milestones = [
        { year: 2023, description: "NLP Master Trainer by American Board of NLP" },
        { year: 2019, description: "Master Trainer, Facilitator and Transformational Entrepreneur by NLP University" },
        { year: 2019, description: "Marketing Online Train the Trainer Certification by Alex Mandossian" },
        { year: 2018, description: "Certified John C. Maxwell Coach, Teacher, Trainer and Speaker" },
        { year: 2018, description: "Certified Extended DISC Master Trainer by Extended DISC Australasia Ltd" },
        { year: 2018, description: "Certified Professional Coach by Professional Business Coaches Alliance" },
        { year: 2017, description: "Certified Canfield Trainer of Success Principles" },
        { year: 2017, description: "Certified Trainer of NLP by American Board of NLP" },
        { year: 2016, description: "Master Hypnotherapy Practitioner by The American Board of Hypnotherapy" },
        { year: 2016, description: "Time Line Therapy Master Practitioner by TLT Association" },
        { year: 2016, description: "Master Practitioner of NLP Coaching by American Board of NLP" },
        { year: 2016, description: "Master NLP Practitioner by American Board of NLP" },
        { year: 2016, description: "Hypnotherapy Practitioner by The American Board of Hypnotherapy" },
        { year: 2016, description: "Time Line Therapy Practitioner by TLT Association" },
        { year: 2016, description: "NLP Coaching by American Board of NLP" },
        { year: 2016, description: "NLP Practitioner by American Board of NLP" },
        { year: 2015, description: "Master of Business Administration by MIU campus, University of Northampton, UK." }
    ];
    let isLoaded = false;

    async function fetchData() {
        const response = await fetch(`${urlRoot}/learning_milestones`);
        milestones = await response.json();
        isLoaded = true;
    }

    onMount(fetchData);
</script>

<div class="learning-milestone" id="learning-milestone">
    <h1>{$currentLanguage == 'en' ? title.en : title.mm}</h1>
    <p>My investment for your success and happiness:</p>
    {#if isLoaded}
        <div class="milestone-list">
            {#each milestones as milestone}
                <div class="milestone-item">
                    <div class="milestone-year">
                        {milestone.year}
                    </div>
                    <div class="milestone-description">
                        {milestone.name}
                    </div>
                </div>
            {/each}
        </div>
    {:else}
        <p>Loading...</p>
    {/if}
</div>

<style>
    .learning-milestone {
        color: var(--primary-color);
        padding: 40px;
        border-radius: 20px;
        margin-top: 20px;
    }

    .learning-milestone h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .learning-milestone p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .milestone-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .milestone-item {
        display: flex;
        align-items: center;
    }

    .milestone-year {
        background-color: var(--tertiary-color);
        border-radius: 10px;
        padding: 10px 20px;
        margin-right: 10px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: var(--secondary-color);
    }

    .milestone-description {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        color: var(--primary-color);
    }
</style>
