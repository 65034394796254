<script>
    import Button from "./Button.svelte";

    export let buttons = [
        { text: { en: "BUSINESS COACHING", mm: "စီးပွားရေး လမ်းညွှန်" }, active: false },
        { text: { en: "DISC ASSESSMENT", mm: "DISC အကဲဖြတ်ခြင်း" }, active: true },
        { text: { en: "NLP COACHING", mm: "NLP လမ်းညွှန်" }, active: false }
    ];
</script>

<div class="button-row">
    {#each buttons as button}
        <Button button={button} />
    {/each}
</div>

<style>
    .button-row {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .button-row {
            flex-direction: column;
            align-items: center;
        }
    }
</style>
