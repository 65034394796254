<script>
  import Navbar from './components/Navbar.svelte';
  import Footer from './components/Footer.svelte';
  import { currentLanguage } from './stores/languageStore';
  import { Router, Route, link, navigate } from 'svelte-routing';
  import Home from './routes/index.svelte';
  import OneOnOneCoaching from './routes/one-on-one-coaching/index.svelte';
  import Admin from './routes/admin/index.svelte';
  import Login from './routes/login.svelte';
  import Register from './routes/register.svelte';
  import ContentHome from './routes/admin/content-home/index.svelte';
  import Appointments from './routes/admin/appointments/index.svelte';
  import ContactUs from './routes/contact-us/index.svelte';
  import Payments from './routes/admin/payments/index.svelte';
  import Blogs from './routes/blogs/index.svelte';
  import TeamCoaching from './routes/team-coaching/index.svelte';
  import Training from './routes/training/index.svelte';
  import MeetMung from './routes/meet-mung/index.svelte';
  import Shops from "./routes/shops/index.svelte";
  import Stats from './routes/admin/stats/index.svelte';
  import Banners from './routes/admin/banners/index.svelte';
  import Events from './routes/admin/events/index.svelte';
  import MainCategories from './routes/admin/main-categories/index.svelte';
  import SubCategories from './routes/admin/sub-categories/index.svelte';
  import Products from './routes/admin/products/index.svelte';
  import Clients from './routes/admin/clients/index.svelte';
  import Quotes from './routes/admin/quotes/index.svelte';
  import Colors from './routes/admin/colors/index.svelte';
  import Testimonial from './routes/testimonial/index.svelte';
  import Testimonials from './routes/admin/testimonials/index.svelte';
  import Articles from './routes/admin/articles/index.svelte';
  import BlogVideos from './routes/admin/blog-videos/index.svelte';
  import Press from './routes/admin/collaborations/index.svelte';
  import ArticleView from './routes/articles/view.svelte';
  import Constants from './routes/admin/constants/index.svelte';
  import LearningMilestones from './routes/admin/learning-milestones/index.svelte';
  import Awards from './routes/admin/awards/index.svelte';

  let isAuthenticated = false;

  function checkAuth() {
    const token = localStorage.getItem('token');
    isAuthenticated = !!token;
  }

  function handleLogin() {
    checkAuth();
    navigate('/admin');
  }

  function handleLogout() {
    localStorage.removeItem('token');
    isAuthenticated = false;
    navigate('/');
  }

  checkAuth();
</script>

<svelte:head>
  <!-- Google Tag Manager -->
  <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WGWGLCBP');</script>
    <!-- End Google Tag Manager -->
  <title>Professional Coaching & Training Services | NL Mung Gaulnum</title>
  <meta name="description" content="Enhance your skills with our one-on-one coaching, team coaching, and professional training services. Access our comprehensive admin tools and resources today." />
  <link rel="icon" type="image/png" href="/assets/img/favicon.png" />
</svelte:head>
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WGWGLCBP"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->
<Navbar {isAuthenticated} on:logout={handleLogout} />

<Router>
  <Route path="/" component={Home} />
  <Route path="/contact-us" component={ContactUs} />
  <Route path="/one-on-one-coaching" component={OneOnOneCoaching} />
  <Route path="/team-coaching" component={TeamCoaching} />
  <Route path="/trainings" component={Training} />
  <Route path="/admin" component={isAuthenticated ? Admin : Login} />
  <Route path="/admin/content-home/*" component={ContentHome} />
  <Route path="/admin/appointments" component={Appointments} />
  <Route path="/admin/payments" component={Payments} />
  <Route path="/admin/stats" component={Stats} />
  <Route path="/login" component={Login} on:login={handleLogin} />
  <Route path="/register" component={Register} />
  <Route path="/blogs" component={Blogs} />
  <Route path="/meet-mung" component={MeetMung} />
  <Route path="/shops" component={Shops} />
  <Route path="/admin/events/*" component={Events} />
  <Route path="/admin/main-categories/*" component={MainCategories} />
  <Route path="/admin/sub-categories/*" component={SubCategories} />
  <Route path="/admin/products/*" component={Products} />
  <Route path="/admin/clients/*" component={Clients} />
  <Route path="/admin/quotes/*" component={Quotes} />
  <Route path="/admin/colors/*" component={Colors} />
  <Route path="/testimonial" component={Testimonial} />
  <Route path="/admin/testimonials" component={Testimonials} />
  <Route path="/admin/articles/*" component={Articles} />
  <Route path="/admin/blog-videos/*" component={BlogVideos} />
  <Route path="/admin/collaborations/*" component={Press} />
  <Route path="/articles/view/:id" component={ArticleView} />
  <Route path="/admin/constants/*" component={Constants} />
  <Route path="/admin/learning-milestones/*" component={LearningMilestones} />
  <Route path="/admin/awards/*" component={Awards} />
  <Route path="/admin/banners" component={Banners} />
</Router>

<Footer />

<style>
  main {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
  }
</style>
