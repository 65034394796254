<script>
    import { Router, Route } from "svelte-routing";
    import ArticleList from "./list.svelte";
    import ArticleCreate from "./create.svelte";
    import ArticleEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={ArticleList} />
    <Route path="/create" component={ArticleCreate} />
    <Route path="/edit/:id" component={ArticleEdit} />
</Router>
