<script>
    import { currentLanguage } from "../stores/languageStore";

    export let milestone;
</script>

<div class="card">
    <div class="image-container">
        <img src={milestone.image} alt={milestone.title.en} />
    </div>
    <div class="content">
        <h3>{$currentLanguage === 'en' ? milestone.title.en : milestone.title.mm}</h3>
        <p>{$currentLanguage === 'en' ? milestone.description.en : milestone.description.mm}</p>
    </div>
    <div class="stats">
        {#each milestone.stats as stat}
            <div class="stat">
                <span class="value">{stat.value}</span>
                <span class="label">{$currentLanguage === 'en' ? stat.label.en : stat.label.mm}</span>
            </div>
        {/each}
    </div>
</div>

<style>
    .card {
        background: var(--primary-color);
        border-radius: 20px;
        overflow: hidden;
        color: var(--secondary-color);
        display: flex;
        flex-direction: column;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .image-container {
        width: 100%;
        height: 200px; /* Set a fixed height for the image container */
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensure the image covers the container */
    }

    .content {
        padding: 20px;
        flex-grow: 1;
    }

    h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
    }

    p {
        font-size: 16px;
        margin: 10px 0;
    }

    .stats {
        display: flex;
        gap: 10px;
        margin-top: auto; /* Push stats to the bottom */
        padding: 20px;
    }

    .stat {
        background: var(--tertiary-color);
        color: var(--primary-color);
        border-radius: 12px;
        padding: 10px 20px;
        text-align: center;
        flex: 1;
    }

    .value {
        font-size: 24px;
        font-weight: 700;
    }

    .label {
        font-size: 14px;
    }
</style>
