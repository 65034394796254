<script>
    import Mission from "../../components/Mission.svelte";
    import ButtonsRow from "../../components/ButtonsRow.svelte";
    import Milestone from "../../components/Milestone.svelte";
    import LearningMilestones from "../../components/LearningMilestones.svelte";
    import TitleSection from "../../components/TitleSection.svelte";
    import ImagesSlider from "../../components/ImagesSlider.svelte";
    import Testimonial from "../../components/Testimonial.svelte";
    import Press from "../../components/Press.svelte";
    import Appointment from "../../components/Appointment.svelte";
    import { urlRoot } from "../../constants";
    import { onMount } from "svelte";

    const buttons = [
        { text: { en: "1 ON 1 COACHING", mm: "1 ON 1 COACHING" }, active: false, link: "/one-on-one-coaching" },
        { text: { en: "TEAM COACHING", mm: "TEAM COACHING" }, active: false, link: "/team-coaching" },
        { text: { en: "TRAINING", mm: "TRAINING" }, active: false, link: "/trainings" }
    ];

    const awardingBodyTitle = {
        "en": "AWARDING BODIES",
        "mm": "AWARDING BODIES"
    };


    let isLoaded = false;
    let awards = [
        {
            en: "Myanmar Imperial University",
            mm: "Myanmar Imperial University",
            image: "/assets/img/institutions/1.jpeg",
        },
        {
            en: "University of Northampton",
            mm: "University of Northampton",
            image: "/assets/img/institutions/2.jpeg",
        },
        {
            en: "American Board of NLP",
            mm: "American Board of NLP",
            image: "/assets/img/institutions/3.jpeg",
        },
        {
            en: "NLP University",
            mm: "NLP University",
            image: "/assets/img/institutions/4.jpeg",
        },
        {
            en: "Timeline Therapy Association",
            mm: "Timeline Therapy Association",
            image: "/assets/img/institutions/5.jpeg",
        },
        {
            en: "John C. Maxwell",
            mm: "John C. Maxwell",
            image: "/assets/img/institutions/6.jpeg",
        },
        {
            en: "Marketing Online",
            mm: "Marketing Online",
            image: "/assets/img/institutions/7.jpeg",
        },
        {
            en: "Extended DISC Australasia Ltd.,",
            mm: "Extended DISC Australasia Ltd.,",
            image: "/assets/img/institutions/8.jpeg",
        },
        {
            en: "The American Board of Hypnotherapy",
            mm: "The American Board of Hypnotherapy",
            image: "/assets/img/institutions/9.jpeg",
        },
        {
            en: "Canfield Trainer of Success Principles",
            mm: "Canfield Trainer of Success Principles",
            image: "/assets/img/institutions/10.jpeg",
        },
        {
            en: "Professional Business coaches Alliance",
            mm: "Professional Business coaches Alliance",
            image: "/assets/img/institutions/11.jpeg",
        },
    ];

    async function fetchAwards() {
        const response = await fetch(`${urlRoot}/awards`);
        awards = await response.json();
        awards.forEach(award => {
            award.image = `${urlRoot}/files/${award.image}`;
            award.en = award.name;
            award.mm = award.name;
        });
        isLoaded = true;
    }

    onMount(fetchAwards);
</script>

<svelte:head>
    <title>Meet Mung | NL Mung | nlmung.com</title>
    <meta name="description" content="My greatest opportunities unfold when I work with business leaders, realizing their visions, and helping them attain their next level of success and happiness through business coaching and training." />
</svelte:head>

<Mission />

<ButtonsRow buttons={buttons} />

<Milestone
    id='milestones'
/>

<ButtonsRow buttons={buttons} />

<LearningMilestones />

<TitleSection
    title={awardingBodyTitle}
    id="awarding-body"
/>

{#if isLoaded}
    {#if awards.length < 12}
        <ImagesSlider
            images={awards}
            isSlider={false}
        />
    {:else}
        <ImagesSlider
            images={awards}
            isSlider={true}
            autoSlide={false}
        />
    {/if}
{/if}

<Testimonial />

<Press />

<Appointment
    appointmentFor="Collaboration"
    id="appointment"
/>
