<script>
    import EventCard from './EventCard.svelte';
    import Pagination from './Pagination.svelte';
    import { writable } from 'svelte/store';
    import { urlRoot } from '../constants';
    import { onMount } from 'svelte';

    let events = [];
    let paginatedEvents = writable([]);
    const ITEMS_PER_PAGE = 4;
    let currentPage = writable(1);

    async function fetchData() {
        const response = await fetch(`${urlRoot}/events`);
        let responseData = await response.json();
        events = responseData.map(data => {
            let eventTitle = {
                en: data.name,
                mm: data.name
            };
            let eventDescription = {
                en: data.description,
                mm: data.description
            };
            return {
                image: `${urlRoot}/files/${data.image}`,
                date: data.time,
                type: data.tag,
                title: eventTitle,
                description: eventDescription,
                link: data.link,
                trainer: data.trainer,
                location: data.location,
            };
        });
        paginate($currentPage);
    }

    function paginate(page = 1) {
        const offset = (page - 1) * ITEMS_PER_PAGE;
        paginatedEvents.set(events.slice(offset, offset + ITEMS_PER_PAGE));
    }

    $: currentPage.subscribe(value => {
        paginate(value);
    });

    onMount(fetchData);
</script>

<section class="event-container">
    {#each $paginatedEvents as event}
        <EventCard {event} />
    {/each}
</section>

<Pagination
    totalItems={events.length}
    itemsPerPage={ITEMS_PER_PAGE}
    currentPage={currentPage}
/>

<style>
    .event-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding: 20px;
    }

    @media (max-width: 768px) {
        .event-container {
            grid-template-columns: 1fr;
        }
    }
</style>
