<script>
    import TitleSection from "./TitleSection.svelte";
    import Pagination from "./Pagination.svelte";
    import Button from "./Button.svelte";
    import { writable } from "svelte/store";
    import { currentLanguage } from "../stores/languageStore";
    import { urlRoot } from "../constants";
    import { afterUpdate, onMount } from "svelte";

    import { buildQuery } from "../utils";

    export let title = {
        en: "ARTICLES",
        mm: "ဆောင်းပါးများ"
    };

    export let articles = [];

    async function fetchArticles() {
        const filters = {
            publish_status: true
        };
        const query = buildQuery(`${urlRoot}/articles`, filters);
        const response = await fetch(query);
        articles = await response.json();
        if (articles.length !== 0) {
            articles.forEach(article => {
                article.created = new Date(article.created).toLocaleString();
                article.heading = {
                    en: article.name,
                    mm: article.name
                };
                article.description = {
                    en: article.description,
                    mm: article.description
                };
                article.image = `${urlRoot}/files/${article.image}`;
                article.button = {
                    text: {
                        en: "READ MORE",
                        mm: "READ MORE"
                    },
                    link: `/articles/view/${article.id}`,
                    openNewTab: true
                };
            });
            paginateArticles();
        }
    }

    onMount(fetchArticles);
    afterUpdate(paginateArticles);
    export let itemsPerPage = 4;
    const currentPage = writable(1);

    let paginatedArticles = [];

    function paginateArticles() {
        const start = ($currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        paginatedArticles = articles.slice(start, end);
    }
</script>

<TitleSection
    title={title}
    backgroundColor={'var(--secondary-color)'}
    textColor={'var(--primary-color)'}
    id="articles"
/>

<div class="articles-gallery">
    {#each paginatedArticles as article}
        <div class="article-container">
            <h3>{$currentLanguage === 'en' ? article.heading.en : article.heading.mm}</h3>
            <p>{$currentLanguage === 'en' ? article.description.en : article.description.mm}</p>
            <div class="button-group">
                <Button button={article.button} />
            </div>
        </div>
    {/each}
</div>

<Pagination
    totalItems={articles.length}
    itemsPerPage={itemsPerPage}
    currentPage={currentPage}
/>

<style>
    .articles-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .articles-gallery {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .article-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--primary-color);
        border-radius: 10px;
        overflow: hidden;
        color: var(--secondary-color);
        padding: 20px;
        position: relative;
    }

    .article-container h3 {
        margin: 0 0 10px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .article-container h3 {
            font-size: 16px;
        }
    }

    .article-container p {
        margin: 0 0 10px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        .article-container p {
            font-size: 12px;
        }
    }

    .button-group {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        margin-top: auto;
    }

    @media (max-width: 768px) {
        .button-group {
            justify-content: flex-start;
            margin-top: 10px;
        }
    }

    .cta-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--tertiary-color);
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #0c1674;
        text-transform: uppercase;
    }

    @media (max-width: 768px) {
        .cta-button {
            padding: 10px 20px;
        }
    }

    .cta-button span {
        margin-right: 10px;
    }
</style>
