<script>
    import { currentLanguage } from "../stores/languageStore";
    import { onMount } from "svelte";

    export let images = [];
    console.log("Images: ", images);
    export let numOfImagePerPage = 12;
    export let numOfColumn = 6;
    export let autoSlide = false;
    export let isSlider = true;

    let currentPage = 1;
    let totalPages = Math.ceil(images.length / numOfImagePerPage);

    function nextPage() {
        currentPage = currentPage < totalPages ? currentPage + 1 : 1;
    }

    function prevPage() {
        currentPage = currentPage > 1 ? currentPage - 1 : totalPages;
    }

    const width = window.innerWidth;
    if (width <= 480) {
        numOfColumn = numOfColumn / 3;
    } else if (width <= 768) {
        numOfColumn = numOfColumn / (2 / 3);
    }

    if (autoSlide) {
        setInterval(nextPage, 5000); // Slide every 5 seconds
    }
</script>

<div class="slider-container">
    <div class="slider" style="grid-template-columns: repeat({numOfColumn}, 1fr); transform: translateX(calc(-100% * (currentPage - 1)))">
        {#each images.slice((currentPage - 1) * numOfImagePerPage, currentPage * numOfImagePerPage) as image}
            <div class="image-item">
                <img src={image.image} alt={$currentLanguage === 'en' ? image.en : image.mm} />
                <p>{$currentLanguage === 'en' ? image.en : image.mm}</p>
            </div>
        {/each}
    </div>
    {#if isSlider}
        <div class="arrows">
            <div class="arrow arrow-left" on:click={prevPage}>⬅</div>
            <div class="arrow arrow-right" on:click={nextPage}>➡</div>
        </div>
    {/if}
</div>

<style>
    .slider-container {
        margin-top: 20px;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .slider {
        display: grid;
        gap: 20px;
        transition: transform 0.5s ease-in-out;
    }

    .image-item {
        text-align: center;
    }

    .image-item img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
    }

    .arrows {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
    }

    .arrow {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        color: var(--secondary-color);
        padding: 5px;
        border-radius: 50%;
    }

    .arrow-left,
    .arrow-right {
        background: transparent;
        border: none;
        color: var(--secondary-color);
        background-color: var(--primary-color);
        border-radius: 50%;
        font-size: 24px;
        cursor: pointer;
    }

    .arrow-left:hover,
    .arrow-right:hover {
        color: var(--tertiary-color);
    }

    @media (max-width: 480px) {
        .image-item img {
            width: 50px;
            height: 50px;
        }
    }

    @media (max-width: 768px) {
        .image-item img {
            width: 60px;
            height: 60px;
        }
    }
</style>
