<script>
    import { navigate } from 'svelte-routing';
    import { urlRoot } from '../constants';
    
    let username = "";
    let email = "";
    let password = "";

    async function register() {
        const response = await fetch(`${urlRoot}/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ username, email, password })
        });

        if (response.ok) {
            console.log("User registered successfully");
            navigate('/admin'); // Navigate to /admin on successful registration
        } else {
            console.error("Failed to register user");
        }
    }
</script>

<div class="register-container">
    <h2>Register</h2>
    <form on:submit|preventDefault={register} class="register-form">
        <div class="form-group">
            <label for="username">Username</label>
            <input type="text" id="username" bind:value={username} placeholder="Username" required />
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" bind:value={email} placeholder="Email" required />
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" id="password" bind:value={password} placeholder="Password" required />
        </div>
        <button type="submit" class="register-btn">Register</button>
    </form>
</div>

<style>
    .register-container {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .register-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    .register-btn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .register-btn:hover {
        background-color: var(--tertiary-color);
    }
</style>
