<script>
    import { currentLanguage } from "../stores/languageStore";
    import { navigate } from "svelte-routing";

    export let button = {
        active: false,
        text: {
            en: "READ NOW",
            mm: "အခုဖတ်ပါ"
        },
        sub: false,
        openNewTab: false,
    };

    export let handleClick = () => {};
    const compare = () => {};
    export let includeArrow = true;
    const isSameFunction = handleClick.toString() === compare.toString();
</script>

{#if button.sub}
    <button class:active={button.active} class="sub">
        <a href={button.link}>{$currentLanguage === 'en' ? button.text.en : button.text.mm}</a>
    </button>
{:else}
    {#if isSameFunction}
        <a href={button.link} target={button.openNewTab ? "_blank" : "_self"}>
            <button class:active={button.active}>
                {#if includeArrow}
                    <div class="circle">
                        <img src={`assets/img/icons/arrow${button.active ? '' : '-light'}.svg`} alt="Arrow" />
                    </div>
                {/if}
                <span>
                    {$currentLanguage === 'en' ? button.text.en : button.text.mm}
                </span>
            </button>
        </a>
    {:else}
        <button class:active={button.active} on:click={handleClick}>
            {#if includeArrow}
                <div class="circle">
                    <img src={`assets/img/icons/arrow${button.active ? '' : '-light'}.svg`} alt="Arrow" />
                </div>
            {/if}
            <span>{$currentLanguage === 'en' ? button.text.en : button.text.mm}</span>
        </button>
    {/if}
{/if}

<style>
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--tertiary-color);
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: var(--primary-color);
        transition: background-color 0.3s ease;
    }

    @media (max-width: 768px) {
        button {
            padding: 10px 20px;
        }
    }

    button.sub {
        background: transparent;
        color: var(--primary-color);
    }

    button.active {
        background: var(--primary-color);
        color: var(--secondary-color);
    }

    .circle {
        background: var(--primary-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: background-color 0.3s ease;
    }

    @media (max-width: 768px) {
        .circle {
            width: 24px;
            height: 24px;
        }
    }

    button.active .circle {
        background: var(--tertiary-color);
    }

    button img {
        width: 16px;
        height: 16px;
    }

    button a {
        text-decoration: none;
        color: inherit;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
</style>