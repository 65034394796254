<script>
    import Appointment from "../../components/Appointment.svelte";
</script>

<svelte:head>
    <title>Contact Me | NL Mung | nlmung.com</title>
    <meta name="description" content="Engage with Myanmar's top business coach, renowned for the highest number of strategic plans for various businesses, and the first NLP Double Master Trainer." />
</svelte:head>

<Appointment />
