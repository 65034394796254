<script>
    import { Router, Route } from "svelte-routing";
    import QuoteList from "./list.svelte";
    import QuoteCreate from "./create.svelte";
    import QuoteEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={QuoteList} />
    <Route path="/create" component={QuoteCreate} />
    <Route path="/edit/:id" component={QuoteEdit} />
</Router>
