<script>
    import { onMount } from "svelte";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { downloadCSV, buildQuery } from "../../../utils";

    let appointments = [];
    let startDate = "";
    let endDate = "";
    let customerName = "";
    let resolveStatus = "";
    const titles = [
        "Appointment Date",
        "Name",
        "Mode of Contact",
        "Role",
        "Contact Information",
        "Business",
        "Appointment For",
        "Message",
        "Resolve Status"
    ]

    const headers = [
        "created",
        "name",
        "mode_of_contact",
        "role",
        "contact_information",
        "business",
        "appointment_for",
        "message",
        "resolve_status"
    ]

    async function fetchAppointments() {
        const filters = {
            start_date: startDate,
            end_date: endDate,
            customer_name: customerName,
            resolved: resolveStatus
        };
        const query = buildQuery(`${urlRoot}/appointments`, filters);
        const response = await fetch(query);
        appointments = await response.json();
        appointments.forEach(appointment => {
            appointment.created = new Date(appointment.created).toLocaleString();
            appointment.resolve_status = appointment.resolved ? "Resolved" : "Not Resolved";
        });
    }

    async function resolveAppointment(id) {
        const response = await fetch(`${urlRoot}/appointments/${id}/resolve`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ resolved: true })
        });
        if (response.ok) {
            fetchAppointments();
        } else {
            console.error("Failed to resolve appointment");
        }
    }

    function clearFilters() {
        startDate = "";
        endDate = "";
        customerName = "";
        resolveStatus = "";
        fetchAppointments();
    }

    onMount(fetchAppointments)
</script>

<Breadcrumb path="/admin/appointments" />
<div class="filter-container">
    <label>
        Start Date:
        <input type="date" bind:value={startDate} />
    </label>
    <label>
        End Date:
        <input type="date" bind:value={endDate} />
    </label>
    <label>
        Customer Name:
        <input type="text" bind:value={customerName} placeholder="Customer Name" />
    </label>
    <label>
        Resolved:
        <select bind:value={resolveStatus}>
            <option value="">All</option>
            <option value="true">Resolved</option>
            <option value="false">Unresolved</option>
        </select>
    </label>
    <button on:click={fetchAppointments}>Apply Filters</button>
    <button on:click={clearFilters}>Clear Filters</button>
    <button on:click={() => downloadCSV(appointments, titles, headers, "appointments")}>Download CSV</button>
</div>
<div class="appointment-list">
    <h2>Appointment List</h2>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Appointment Date</th>
                <th>Name</th>
                <th>Mode of Contact</th>
                <th>Role</th>
                <th>Contact Information</th>
                <th>Business</th>
                <th>Appointment For</th>
                <th>Message</th>
                <th>Resolve Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each appointments as appointment, index}
                <tr>
                    <td>{index + 1}</td>
                    <td>{appointment.created}</td>
                    <td>{appointment.name}</td>
                    <td>{appointment.mode_of_contact}</td>
                    <td>{appointment.role}</td>
                    <td>{appointment.contact_information}</td>
                    <td>{appointment.business}</td>
                    <td>{appointment.appointment_for}</td>
                    <td>{appointment.message}</td>
                    <td>{appointment.resolve_status}</td>
                    <td>
                        {#if !appointment.resolved}
                            <button on:click={() => resolveAppointment(appointment.id)}>Resolve</button>
                        {/if}
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .filter-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
    }

    .filter-container label {
        display: flex;
        flex-direction: column;
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
    }

    .filter-container input,
    .filter-container select {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .filter-container button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    .filter-container button:hover {
        background-color: var(--tertiary-color);
    }
    .appointment-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
