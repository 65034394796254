<script>
    import { onMount } from "svelte";
    import { writable } from "svelte/store"; // Import writable for the modal logic
    import { urlRoot } from "../constants";
    import TitleSection from "./TitleSection.svelte";
    import TextSection from "./TextSection.svelte";

    export let id = "";

    let name = "";
    let description = "";
    let content = "";
    let created = "";
    let image = "";
    let isLoaded = false;

    // Modal logic
    const showModal = writable(false);
    const selectedImageSrc = writable("");

    async function fetchArticle() {
        const response = await fetch(`${urlRoot}/articles/${id}`);
        const data = await response.json();
        name = {
            en: data.name,
            mm: data.name
        };
        content = {
            en: [data.content],
            mm: [data.content]
        };
        created = data.created;
        image = `${urlRoot}/files/${data.image}`;
        isLoaded = true;
    }

    function openImageModal(src) {
        selectedImageSrc.set(src);
        showModal.set(true);
    }

    function closeImageModal() {
        showModal.set(false);
    }

    onMount(fetchArticle);
</script>

<svelte:head>
    <title>{name.en} | NL Mung Gualnum | nlmung.com</title>
    <meta name="description" content={description} />
</svelte:head>

{#if isLoaded}
    <div class="article">
        <img 
            src={image} 
            alt={name.en} 
            class="gallery-image" 
            on:click={() => openImageModal(image)} 
        />
    </div>
    <TitleSection
        title={name}
    />
    <TextSection
        paragraphs={content}
    />
{/if}

{#if $showModal}
    <div class="modal-overlay" on:click={closeImageModal}>
        <div class="modal-content" on:click|stopPropagation>
            <img src={$selectedImageSrc} alt="Full Image" class="full-image"/>
            <button class="close-button" on:click={closeImageModal}>Close</button>
        </div>
    </div>
{/if}

<style>
    .article {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
    }

    .article img {
        width: 100%;
        height: 700px !important;
        object-fit: cover;
        cursor: pointer;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        position: relative;
        background: var(--secondary-color);
        border-radius: 10px;
        padding: 20px;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;
    }

    .full-image {
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: var(--primary-color);
        border: none;
        color: var(--secondary-color);
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
    }
</style>
