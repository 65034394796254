<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let business_coaching_leaders = 0;
    let business_coaching_count = 0;
    let strategic_planning_businesses = 0;
    let strategic_planning_count = 0;
    let team_coaching_businesses = 0;
    let team_coaching_count = 0;
    let business_development_training_leaders = 0;
    let leadership_development_training_leaders = 0;
    let nlp_certification_training_leaders = 0;
    let previewForBusinessCoachingImage = "";
    let previewForStrategicPlanningImage = "";
    let previewForTeamCoachingImage = "";
    let previewForBusinessDevelopmentTrainingImage = "";
    let previewForLeadershipDevelopmentTrainingImage = "";
    let previewForNlpCertificationTrainingImage = "";
    let business_coaching_image = "";
    let strategic_planning_image = "";
    let team_coaching_image = "";
    let business_development_training_image = "";
    let leadership_development_training_image = "";
    let nlp_certification_training_image = "";

    function handleFileChange(event, imageType) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (imageType === "business_coaching") {
                    previewForBusinessCoachingImage = e.target.result;
                } else if (imageType === "strategic_planning") {
                    previewForStrategicPlanningImage = e.target.result;
                } else if (imageType === "team_coaching") {
                    previewForTeamCoachingImage = e.target.result;
                } else if (imageType === "business_development_training") {
                    previewForBusinessDevelopmentTrainingImage = e.target.result;
                } else if (imageType === "leadership_development_training") {
                    previewForLeadershipDevelopmentTrainingImage = e.target.result;
                } else if (imageType === "nlp_certification_training") {
                    previewForNlpCertificationTrainingImage = e.target.result;
                }
            };
            reader.readAsDataURL(file);
        }
    }

    async function fetchData() {
        const response = await fetch(`${urlRoot}/stats`);
        const data = await response.json();
        business_coaching_leaders = data.business_coaching_leaders;
        business_coaching_count = data.business_coaching_count;
        strategic_planning_businesses = data.strategic_planning_businesses;
        strategic_planning_count = data.strategic_planning_count;
        team_coaching_businesses = data.team_coaching_businesses;
        team_coaching_count = data.team_coaching_count;
        business_development_training_leaders = data.business_development_training_leaders;
        leadership_development_training_leaders = data.leadership_development_training_leaders;
        nlp_certification_training_leaders = data.nlp_certification_training_leaders;
        business_coaching_image = data.business_coaching_image;
        strategic_planning_image = data.strategic_planning_image;
        team_coaching_image = data.team_coaching_image;
        business_development_training_image = data.business_development_training_image;
        leadership_development_training_image = data.leadership_development_training_image;
        nlp_certification_training_image = data.nlp_certification_training_image;
        previewForBusinessCoachingImage = `${urlRoot}/files/${business_coaching_image}`;
        previewForStrategicPlanningImage = `${urlRoot}/files/${strategic_planning_image}`;
        previewForTeamCoachingImage = `${urlRoot}/files/${team_coaching_image}`;
        previewForBusinessDevelopmentTrainingImage = `${urlRoot}/files/${business_development_training_image}`;
        previewForLeadershipDevelopmentTrainingImage = `${urlRoot}/files/${leadership_development_training_image}`;
        previewForNlpCertificationTrainingImage = `${urlRoot}/files/${nlp_certification_training_image}`;
    }

    async function updateData(event) {
        event.preventDefault();

        const fileInputForBusinessCoaching = document.getElementById("business_coaching_image");
        const fileInputForStrategicPlanning = document.getElementById("strategic_planning_image");
        const fileInputForTeamCoaching = document.getElementById("team_coaching_image");
        const fileInputForBusinessDevelopmentTraining = document.getElementById("business_development_training_image");
        const fileInputForLeadershipDevelopmentTraining = document.getElementById("leadership_development_training_image");
        const fileInputForNlpCertificationTraining = document.getElementById("nlp_certification_training_image");

        if (fileInputForBusinessCoaching.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForBusinessCoaching.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                business_coaching_image = fileData.id;
            } else {
                alert("Failed to upload image for business coaching");
                return;
            }
        }

        if (fileInputForStrategicPlanning.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForStrategicPlanning.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                strategic_planning_image = fileData.id;
            } else {
                alert("Failed to upload image for Strategic Planning");
                return;
            }
        }

        if (fileInputForTeamCoaching.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForTeamCoaching.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                team_coaching_image = fileData.id;
            } else {
                alert("Failed to upload image for team coaching");
                return;
            }
        }

        if (fileInputForBusinessDevelopmentTraining.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForBusinessDevelopmentTraining.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                business_development_training_image = fileData.id;
            } else {
                alert("Failed to upload image for business development training");
                return;
            }
        }

        if (fileInputForLeadershipDevelopmentTraining.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForLeadershipDevelopmentTraining.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                leadership_development_training_image = fileData.id;
            } else {
                alert("Failed to upload image for leadership development training");
                return;
            }
        }

        if (fileInputForNlpCertificationTraining.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForNlpCertificationTraining.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                nlp_certification_training_image = fileData.id;
            } else {
                alert("Failed to upload image for NLP certification training");
                return;
            }
        }

        const data = {
            'business_coaching_leaders': business_coaching_leaders,
            'business_coaching_count': business_coaching_count,
            'strategic_planning_businesses': strategic_planning_businesses,
            'strategic_planning_count': strategic_planning_count,
            'team_coaching_businesses': team_coaching_businesses,
            'team_coaching_count': team_coaching_count,
            'business_development_training_leaders': business_development_training_leaders,
            'leadership_development_training_leaders': leadership_development_training_leaders,
            'nlp_certification_training_leaders': nlp_certification_training_leaders,
            'business_coaching_image': business_coaching_image,
            'strategic_planning_image': strategic_planning_image,
            'team_coaching_image': team_coaching_image,
            'business_development_training_image': business_development_training_image,
            'leadership_development_training_image': leadership_development_training_image,
            'nlp_certification_training_image': nlp_certification_training_image
        };
        const response = await fetch(`${urlRoot}/stats`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Stats updated successfully");
            navigate('/admin');
        } else {
            alert("Failed to update stats");
        }
    }

    onMount(fetchData);
</script>

<Breadcrumb path="/admin/stats" />
<div class="data-form">
    <h2>Edit Stats</h2>
    <form on:submit|preventDefault={updateData}>
        <div class="form-group">
            <label for="business_coaching_leaders">
                Business Coaching Leaders
            </label>
            <input
                type="number"
                bind:value={business_coaching_leaders}
                id="business_coaching_leaders"
                required
            />
        </div>
        <div class="form-group">
            <label for="business_coaching_count">
                Business Coaching Count
            </label>
            <input
                type="number"
                bind:value={business_coaching_count}
                id="business_coaching_count"
                required
            />
        </div>
        <div class="form-group">
            <label for="business_coaching_image">
                Business Coaching Image (593px x 200px[2.9:1])
            </label>
            <input
                type="file"
                id="business_coaching_image"
                bind:value={business_coaching_image}
                on:change={(e) => handleFileChange(e, "business_coaching")}
            />
            {#if previewForBusinessCoachingImage}
                <img src={previewForBusinessCoachingImage} alt="Business Coaching Image" />
            {/if}
        </div>
        <div class="form-group">
            <label for="strategic_planning_businesses">
                Strategic Planning Businesses
            </label>
            <input
                type="number"
                bind:value={strategic_planning_businesses}
                id="strategic_planning_businesses"
                required
            />
        </div>
        <div class="form-group">
            <label for="strategic_planning_count">
                Strategic Planning Count
            </label>
            <input
                type="number"
                bind:value={strategic_planning_count}
                id="strategic_planning_count"
                required
            />
        </div>
        <div class="form-group">
            <label for="strategic_planning_image">
                Strategic Planning Image (593px x 200px[2.9:1])
            </label>
            <input
                type="file"
                id="strategic_planning_image"
                bind:value={strategic_planning_image}
                on:change={(e) => handleFileChange(e, "strategic_planning")}
            />
            {#if previewForStrategicPlanningImage}
                <img src={previewForStrategicPlanningImage} alt="Strategic Planning Image" />
            {/if}
        </div>
        <div class="form-group">
            <label for="team_coaching_businesses">
                Team Coaching Businesses
            </label>
            <input
                type="number"
                bind:value={team_coaching_businesses}
                id="team_coaching_businesses"
                required
            />
        </div>
        <div class="form-group">
            <label for="team_coaching_count">
                Team Coaching Count
            </label>
            <input
                type="number"
                bind:value={team_coaching_count}
                id="team_coaching_count"
                required
            />
        </div>
        <div class="form-group">
            <label for="team_coaching_image">
                Team Coaching Image (593px x 200px[2.9:1])
            </label>
            <input
                type="file"
                id="team_coaching_image"
                bind:value={team_coaching_image}
                on:change={(e) => handleFileChange(e, "team_coaching")}
            />
            {#if previewForTeamCoachingImage}
                <img src={previewForTeamCoachingImage} alt="Team Coaching Image" />
            {/if}
        </div>
        <div class="form-group">
            <label for="business_development_training_leaders">
                Business Development Training Leaders
            </label>
            <input
                type="number"
                bind:value={business_development_training_leaders}
                id="business_development_training_leaders"
                required
            />
        </div>
        <div class="form-group">
            <label for="business_development_training_image">
                Business Development Training Image (593px x 200px[2.9:1])
            </label>
            <input
                type="file"
                id="business_development_training_image"
                bind:value={business_development_training_image}
                on:change={(e) => handleFileChange(e, "business_development_training")}
            />
            {#if previewForBusinessDevelopmentTrainingImage}
                <img src={previewForBusinessDevelopmentTrainingImage} alt="Business Development Training Image" />
            {/if}
        </div>
        <div class="form-group">
            <label for="leadership_development_training_leaders">
                Leadership Development Training Leaders (593px x 200px[2.9:1])
            </label>
            <input
                type="number"
                bind:value={leadership_development_training_leaders}
                id="leadership_development_training_leaders"
                required
            />
        </div>
        <div class="form-group">
            <label for="leadership_development_training_image">
                Leadership Development Training Image (593px x 200px[2.9:1])
            </label>
            <input
                type="file"
                id="leadership_development_training_image"
                bind:value={leadership_development_training_image}
                on:change={(e) => handleFileChange(e, "leadership_development_training")}
            />
            {#if previewForLeadershipDevelopmentTrainingImage}
                <img src={previewForLeadershipDevelopmentTrainingImage} alt="Leadership Development Training Image" />
            {/if}
        </div>
        <div class="form-group">
            <label for="nlp_certification_training_leaders">
                NLP Certificate Training Leaders
            </label>
            <input
                type="number"
                bind:value={nlp_certification_training_leaders}
                id="nlp_certification_training_leaders"
                required
            />
        </div>
        <div class="form-group">
            <label for="nlp_certification_training_image">
                NLP Certificate Training Image (593px x 200px[2.9:1])
            </label>
            <input
                type="file"
                id="nlp_certification_training_image"
                bind:value={nlp_certification_training_image}
                on:change={(e) => handleFileChange(e, "nlp_certification_training")}
            />
            {#if previewForNlpCertificationTrainingImage}
                <img src={previewForNlpCertificationTrainingImage} alt="NLP Certificate Training Image" />
            {/if}
        </div>
        <button type="submit">Update</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
