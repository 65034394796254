<script>
    import { currentLanguage } from "../stores/languageStore";
    import { urlRoot } from "../constants";

    let name = "";
    let organization = "";
    let programs = "";
    let take_away = "";
    let differences = "";
    let role = "";
    let previewForPhoto = "";
    export let id = "";

    function handleFileChangeForPhoto(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                previewForPhoto = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const fileInputForPhoto = document.getElementById("photo");

        if (fileInputForPhoto.files.length === 0) {
            alert("Please select a photo");
            return;
        }

        const fileFormDataForPhoto = new FormData();
        fileFormDataForPhoto.append("file", fileInputForPhoto.files[0]);

        const fileResponseForPhoto = await fetch(`${urlRoot}/files`, {
            method: "POST",
            body: fileFormDataForPhoto
        });

        if (!fileResponseForPhoto.ok) {
            alert("Failed to upload photo");
            return;
        }

        const fileDataForPhoto = await fileResponseForPhoto.json();
        const photoId = fileDataForPhoto.id;

        const data = {
            name,
            organization,
            programs,
            take_away,
            differences,
            role,
            photo: photoId
        };

        const response = await fetch(`${urlRoot}/testimonials`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            clearForm();
            alertMessage();
        } else {
            alert("Failed to send message");
        }
    }

    function alertMessage() {
        if ($currentLanguage === "en") {
            alert("Thanks for sharing your transformation. Admin will approve soon!");
        } else {
            alert("မျှဝေမှုအတွက် ကျေးဇူးတင်ပါတယ်။ Admin မှ မကြာခင် approve လုပ်ပေးပါလိမ့်မယ်!");
        }
    }

    function clearForm() {
        name = "";
        organization = "";
        programs = "";
        take_away = "";
        differences = "";
        previewForPhoto = "";
        role = "";
        photo = "";
    }
</script>

<svelte:head>
    <title>Testimonial Form | NL Mung | nlmung.com</title>
    <meta name="description" content="Share your transformation with NL Mung" />
</svelte:head>
<div class="appointment-form-container" id={id}>
    <form class="appointment-form" on:submit|preventDefault={handleSubmit}>
        <div class="header">
            <div class="header-left">
                <h1>
                    SHARE YOUR TRANSFORMATION
                </h1>
                <img src="/assets/img/icons/conversation.svg" alt="Conversation Icon" />
            </div>
        </div>
        <div class="form-content">
            <div class="form-fields">
                <div class="form-group">
                    <label for="name">
                        {#if $currentLanguage === "en"}
                            YOUR NAME:
                        {:else}
                            အမည်:
                        {/if}
                    </label>
                    <input type="text" id="name" bind:value={name} placeholder="NL Mung" required />
                </div>
                <div class="form-group">
                    <label for="organization">
                        {#if $currentLanguage === "en"}
                            YOUR ORGANIZATION:
                        {:else}
                            အဖွဲ့အစည်း:
                        {/if}
                    </label>
                    <input type="text" id="organization" bind:value={organization} placeholder="Breakthrough Company Ltd." required />
                </div>
                <div class="form-group">
                    <label for="programs">
                        {#if $currentLanguage === "en"}
                            WHAT PROGRAM(S) DID YOU TAKE WITH ME?
                        {:else}
                            ဘယ်ပရိုဂရမ်တွေမှာ ပါဝင်ဖူးပါသလဲ?
                        {/if}
                    </label>
                    <input type="text" id="programs" bind:value={programs} placeholder="One-on-One Coaching" required />
                </div>
                <div class="form-group">
                    <label for="role">
                        {#if $currentLanguage === "en"}
                            YOUR ROLE:
                        {:else}
                            ရာထူး:
                        {/if}
                    </label>
                    <input type="text" id="role" bind:value={role} placeholder="Founder and CEO" required />
                </div>
                <div class="form-group">
                    <label for="differences">
                        {#if $currentLanguage === "en"}
                            HOW IS MUNG'S SERVICE DIFFERENT FROM OTHERS?
                        {:else}
                            အခြား coaching များနှင့် ကွာခြားမှု?
                        {/if}
                    </label>
                    <textarea id="differences" bind:value={differences} placeholder="I am more confident in my decisions" class="textarea" required />
                </div>
                <div class="form-group">
                    <label for="takeAway">
                        {#if $currentLanguage === "en"}
                            WHAT ARE YOUR TAKE AWAY?
                        {:else}
                            ဘာတွေတိုးတက်ပြောင်းလဲလာပါသလဲ?
                        {/if}
                    </label>
                    <textarea id="takeAway" bind:value={take_away} placeholder="I learned how to be a better leader" class="textarea" required />
                </div>
                <div class="form-group">
                    <label for="photo">
                        {#if $currentLanguage === "en"}
                            LOGO/ PHOTO:
                        {:else}
                            လိုဂို/ ဓာတ်ပုံ:
                        {/if}
                    </label>
                    <input type="file" id="photo" on:change={handleFileChangeForPhoto} accept="image/*" required />
                    {#if previewForPhoto}
                        <img src={previewForPhoto} alt="Selected Photo" width="200"/>
                    {/if}
                </div>
                <div class="form-group">
                </div>
                <button type="submit">SEND YOUR MESSAGE</button>
            </div>
        </div>
    </form>
</div>

<style>
    .appointment-form-container {
        justify-content: space-between;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        padding: 20px;
        border: var(--primary-color) 2px solid;
        border-radius: 20px;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .appointment-form-container {
            padding: 10px;
        }
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header-left {
            margin-bottom: 10px;
        }
    }

    .header-left h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        font-weight: 600;
        line-height: 87px;
        color: var(--primary-color);
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        .header-left h1 {
            font-size: 24px;
            line-height: 36px;
        }
    }

    .placeholder {
        color: var(--secondary-color);
        opacity: 0.5;
    }

    .header-left img {
        width: 50px;
    }

    @media (max-width: 768px) {
        .header-left img {
            width: 40px;
        }
    }

    .header-right {
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header-right {
            margin-top: 10px;
        }
    }

    .header-right label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 25.78px;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        .header-right label {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .header-right input {
        width: 20px;
        height: 20px;
    }

    .header-right input:checked {
        background-color: var(--tertiary-color);
    }

    @media (max-width: 768px) {
        .header-right input {
            width: 18px;
            height: 18px;
        }
    }

    .form-content {
        display: flex;
        width: 100%;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .form-content {
            flex-direction: column;
        }
    }

    .contact-info {
        flex: 1;
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        .contact-info {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .contact-info h2 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        .contact-info h2 {
            font-size: 20px;
        }
    }

    .contact-info p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 21px;
        margin: 10px 0;
    }

    @media (max-width: 768px) {
        .contact-info p {
            font-size: 18px;
        }
    }

    .contact-info p small {
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .contact-info p small {
            font-size: 16px;
        }
    }

    .form-fields {
        flex: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto auto 1fr;
        gap: 20px;
        align-items: start;
    }

    @media (max-width: 768px) {
        .form-fields {
            flex: 1;
            display: block;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 768px) {
        .form-group {
            margin-bottom: 20px;
        }
    }

    .form-group label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        color: var(--primary-color);
        font-weight: 500;
        margin-bottom: 5px;
    }

    @media (max-width: 768px) {
        .form-group label {
            font-size: 16px;
        }
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        padding: 10px;
        border-radius: 10px;
        border: none;
        background-color: var(--tertiary-color);
        color: var(--secondary-color) !important;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .form-group input,
        .form-group select,
        .form-group textarea {
            font-size: 16px;
        }
    }

    .form-group input {
        height: 40px;
    }

    @media (max-width: 768px) {
        .form-group input {
            height: 60px;
        }
    }

    .form-group select {
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'black\'%3E%3Cpath d=\'M7 10l5 5 5-5z\'/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 16px;
        color: var(--secondary-color);
    }

    @media (max-width: 768px) {
        .form-group select {
            height: 60px;
        }
    }

    .form-group select::before {
        opacity: 0.5;
    }

    .form-group select:focus {
        outline: none;
    }

    .form-group select option {
        background-color: var(--tertiary-color);
        color: var(--secondary-color);
    }

    .form-group input::placeholder,
    .form-group select::placeholder,
    .form-group textarea::placeholder {
        color: var(--secondary-color);
        opacity: 0.5;
    }

    .textarea-group {
        grid-column: span 2;
        grid-row: span 2;
    }

    @media (max-width: 768px) {
        .textarea-group {
            grid-column: span 1;
            grid-row: span 2;
        }
    }

    .form-group textarea {
        resize: none;
        height: 100px;
    }

    @media (max-width: 768px) {
        .form-group textarea {
            height: 80px;
        }
    }

    .checkbox-group {
        display: flex;
        align-items: center;
    }

    .checkbox-group input {
        margin-right: 10px;
    }

    .checkbox-group label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        color: var(--tertiary-color);
    }

    button {
        grid-column: span 2;
        padding: 20px;
        border-radius: 10px;
        border: none;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        cursor: pointer;
        text-transform: uppercase;
    }

    @media (max-width: 768px) {
        button {
            padding: 10px;
            font-size: 16px;
        }
    }

    button:hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border: var(--primary-color) 2px solid;
    }

    .textarea {
        min-height: 200px;
        vertical-align: top;
    }
</style>
