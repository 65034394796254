<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let previewForHomeBannerImage = "";
    let previewForMeetMungBannerImage = "";
    let previewForOneOnOneCoachingBannerImage = "";
    let previewForTeamCoachingBannerImage = "";
    let previewForTrainingsBannerImage = "";
    let home_banner_image = null;
    let meet_mung_banner_image = null;
    let one_on_one_coaching_banner_image = null;
    let team_coaching_banner_image = null;
    let trainings_banner_image = null;

    function handleFileChange(event, imageType) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                if (imageType === "home") {
                    previewForHomeBannerImage = reader.result;
                } else if (imageType === "meet_mung") {
                    previewForMeetMungBannerImage = reader.result;
                } else if (imageType === "one_on_one_coaching") {
                    previewForOneOnOneCoachingBannerImage = reader.result;
                } else if (imageType === "team_coaching") {
                    previewForTeamCoachingBannerImage = reader.result;
                } else if (imageType === "trainings") {
                    previewForTrainingsBannerImage = reader.result;
                }
            };
            reader.readAsDataURL(file);
        }
    }

    async function fetchData() {
        const response = await fetch(`${urlRoot}/banners`);
        const data = await response.json();
        home_banner_image = data.home_banner_image;
        meet_mung_banner_image = data.meet_mung_banner_image;
        one_on_one_coaching_banner_image = data.one_on_one_coaching_banner_image;
        team_coaching_banner_image = data.team_coaching_banner_image;
        trainings_banner_image = data.trainings_banner_image;
        previewForHomeBannerImage = `${urlRoot}/files/${home_banner_image}`;
        previewForMeetMungBannerImage = `${urlRoot}/files/${meet_mung_banner_image}`;
        previewForOneOnOneCoachingBannerImage = `${urlRoot}/files/${one_on_one_coaching_banner_image}`;
        previewForTeamCoachingBannerImage = `${urlRoot}/files/${team_coaching_banner_image}`;
        previewForTrainingsBannerImage = `${urlRoot}/files/${trainings_banner_image}`;
    }

    async function updateData(event) {
        event.preventDefault();

        const fileInputForHomeBanner = document.getElementById("home_banner_image");
        const fileInputForMeetMungBanner = document.getElementById("meet_mung_banner_image");
        const fileInputForOneOnOneCoachingBanner = document.getElementById("one_on_one_coaching_banner_image");
        const fileInputForTeamCoachingBanner = document.getElementById("team_coaching_banner_image");
        const fileInputForTrainingsBanner = document.getElementById("trainings_banner_image");

        if (fileInputForHomeBanner.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForHomeBanner.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                home_banner_image = fileData.id;
            } else {
                alert("Failed to upload image for home banner");
                return;
            }
        }

        if (fileInputForMeetMungBanner.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForMeetMungBanner.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                meet_mung_banner_image = fileData.id;
            } else {
                alert("Failed to upload image for meet mung banner");
                return;
            }
        }

        if (fileInputForOneOnOneCoachingBanner.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForOneOnOneCoachingBanner.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                one_on_one_coaching_banner_image = fileData.id;
            } else {
                alert("Failed to upload image for one on one coaching banner");
                return;
            }
        }

        if (fileInputForTeamCoachingBanner.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForTeamCoachingBanner.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                team_coaching_banner_image = fileData.id;
            } else {
                alert("Failed to upload image for team coaching banner");
                return;
            }
        }

        if (fileInputForTrainingsBanner.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInputForTrainingsBanner.files[0]);
            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });
            if (fileResponse.ok) {
                const fileData = await fileResponse.json();
                trainings_banner_image = fileData.id;
            } else {
                alert("Failed to upload image for trainings banner");
                return;
            }
        }

        const data = {
            home_banner_image,
            meet_mung_banner_image,
            one_on_one_coaching_banner_image,
            team_coaching_banner_image,
            trainings_banner_image
        };
        const response = await fetch(`${urlRoot}/banners`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Banners updated successfully");
            navigate('/admin');
        } else {
            alert("Failed to update banners");
        }
    }

    onMount(fetchData);
</script>

<Breadcrumb path="/admin/banners" />
<div class="data-form">
    <h2>Edit Banner</h2>
    <form on:submit|preventDefault={updateData}>
        <div class="form-group">
            <label for="home_banner_image">Home Banner Image (1819px x 615px [2.96:1])</label>
            <input type="file" id="home_banner_image" on:change={(event) => handleFileChange(event, "home")} />
            <img src={previewForHomeBannerImage} alt="Home Banner Image" style="width: 100%; margin-top: 10px;" />
        </div>
        <div class="form-group">
            <label for="meet_mung_banner_image">Meet Mung Banner Image (386px x 400px [0.97:1])</label>
            <input type="file" id="meet_mung_banner_image" on:change={(event) => handleFileChange(event, "meet_mung")} />
            <img src={previewForMeetMungBannerImage} alt="Meet Mung Banner Image" style="width: 100%; margin-top: 10px;" />
        </div>
        <div class="form-group">
            <label for="one_on_one_coaching_banner_image">One On One Coaching Banner Image (386px x 400px [0.97:1])</label>
            <input type="file" id="one_on_one_coaching_banner_image" on:change={(event) => handleFileChange(event, "one_on_one_coaching")} />
            <img src={previewForOneOnOneCoachingBannerImage} alt="One On One Coaching Banner Image" style="width: 100%; margin-top: 10px;" />
        </div>
        <div class="form-group">
            <label for="team_coaching_banner_image">Team Coaching Banner Image (386px x 400px [0.97:1])</label>
            <input type="file" id="team_coaching_banner_image" on:change={(event) => handleFileChange(event, "team_coaching")} />
            <img src={previewForTeamCoachingBannerImage} alt="Team Coaching Banner Image" style="width: 100%; margin-top: 10px;" />
        </div>
        <div class="form-group">
            <label for="trainings_banner_image">Trainings Banner Image (386px x 400px [0.97:1])</label>
            <input type="file" id="trainings_banner_image" on:change={(event) => handleFileChange(event, "trainings")} />
            <img src={previewForTrainingsBannerImage} alt="Trainings Banner Image" style="width: 100%; margin-top: 10px;" />
        </div>
        <button type="submit">Update</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
