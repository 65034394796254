<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let clients = [];

    async function fetchClients() {
        const response = await fetch(`${urlRoot}/clients`);
        clients = await response.json();
        if (clients.length !== 0) {
            clients.forEach(client => {
                client.created = new Date(client.created).toLocaleString();
                client.image = `${urlRoot}/files/${client.image}`;
            });
        }
    }

    async function deleteEvent(id) {
        if (confirm("Are you sure you want to delete this client?")) {
            const response = await fetch(`${urlRoot}/clients/${id}`, {
                method: "DELETE"
            });

            if (response.ok) {
                alert("Client deleted successfully");
                fetchClients();
            } else {
                alert("Failed to delete client");
            }
        }
    }
    onMount(fetchClients);
</script>

<Breadcrumb path="/admin/clients" />
<div class="clients-list">
    <h2>Clients List</h2>
    <button on:click={() => navigate('/admin/clients/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Rank</th>
                <th>Image</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each clients as client, index (client.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{client.name}</td>
                    <td>{client.rank}</td>
                    <td><img src={client.image} alt={client.name} width="100" /></td>
                    <td>
                        <button on:click={() => navigate(`/admin/clients/edit/${client.id}`)}>Edit</button>
                        <button on:click={() => deleteEvent(client.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .clients-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
