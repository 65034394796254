<script>
    import TitleSection from "./TitleSection.svelte";
    import Pagination from "./Pagination.svelte";
    import { writable } from "svelte/store";
    import { currentLanguage } from "../stores/languageStore";
    import { afterUpdate, onMount } from "svelte";
    import { urlRoot } from "../constants";
    import { buildQuery } from "../utils";

    export let title = {
        en: "VIDEOS",
        mm: "ဗီဒီယိုများ"
    };

    async function fetchVideos() {
        const filters = {
            publish_status: true
        };
        const query = buildQuery(`${urlRoot}/blog_videos`, filters);
        const response = await fetch(query);
        videos = await response.json();
        videos.forEach(video => {
            video.heading = {
                en: video.name,
                mm: video.name
            };
            video.description = {
                en: video.description,
                mm: video.description
            };
            video.src = video.video_url;
        });
        paginateVideos();
    }

    export let videos = [];

    const ITEMS_PER_PAGE = 4;
    const currentPage = writable(1);

    const isMobile = window.innerWidth < 768;
    let paginatedVideos = [];

    function paginateVideos() {
        const start = ($currentPage - 1) * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE;
        paginatedVideos = videos.slice(start, end);
        return paginatedVideos;
    }
    

    onMount(fetchVideos);
    afterUpdate(paginateVideos);
</script>

<TitleSection
    title={title}
    backgroundColor={'var(--secondary-color)'}
    textColor={'var(--primary-color)'}
    id="videos"
/>

<div class="video-gallery">
    {#if isMobile}
        {#each paginatedVideos as video}
            <div class="video-container large">
                <iframe width="100%" height="200" src={video.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div class="video-info
                ">
                    <h3>{$currentLanguage === 'en' ? video.heading.en : video.heading.mm}</h3>
                    <p>{$currentLanguage === 'en' ? video.description.en : video.description.mm}</p>
                </div>
            </div>
        {/each}
    {:else}
        {#each paginatedVideos.slice(0, 1) as video}
            <div class="video-container large">
                <iframe width="100%" height="370" src={video.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div class="video-info">
                    <h3>{$currentLanguage === 'en' ? video.heading.en : video.heading.mm}</h3>
                    <p>{$currentLanguage === 'en' ? video.description.en : video.description.mm}</p>
                </div>
            </div>
        {/each}
        {#each paginatedVideos.slice(1) as video}
            <div class="video-container small">
                <iframe width="100%" height="370" src={video.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div class="video-info">
                    <h3>{$currentLanguage === 'en' ? video.heading.en : video.heading.mm}</h3>
                    <p>{$currentLanguage === 'en' ? video.description.en : video.description.mm}</p>
                </div>
            </div>
        {/each}
    {/if}
</div>

<Pagination
    totalItems={videos.length}
    itemsPerPage={ITEMS_PER_PAGE}
    currentPage={currentPage}
/>

<style>
    .video-gallery {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 10px;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .video-gallery {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .video-container {
        display: flex;
        flex-direction: column;
        background-color: var(--primary-color);
        border-radius: 10px;
        overflow: hidden;
        color: var(--secondary-color);
    }

    @media (max-width: 768px) {
        .video-container {
            border-radius: 10px;
        }
    }

    .large {
        grid-column: span 12;
    }

    @media (max-width: 768px) {
        .large {
            grid-column: span 4;
        }
    }

    .small {
        grid-column: span 4;
    }

    .video-info {
        padding: 10px;
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        .video-info {
            padding: 10px;
        }
    }

    .video-info h3 {
        margin: 0 0 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .video-info h3 {
            font-size: 16px;
            margin-top: 5px;
        }
    }

    .video-info p {
        margin: 0;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        .video-info p {
            font-size: 12px;
            margin-bottom: 5px;
        }
    }
</style>
