<script>
    import { currentLanguage } from "../stores/languageStore";

    export let title = {
        en: "",
        mm: ""
    };

    export let id = '';
    
    export let button = null;
    export let backgroundColor = 'var(--primary-color)';
    export let textColor = 'var(--secondary-color)';
    let isMobile = window.innerWidth <= 768;
</script>

<section class="title-section" style="background-color: {backgroundColor}; color: {textColor}" id={id}>
    <h2>{$currentLanguage === 'en' ? title.en : title.mm}</h2>
    {#if button && !isMobile}
        <a href={button.link}>
            <button class="cta-button">
                <div class="circle">
                    <img src="/assets/img/icons/arrow-up.svg" alt="Arrow" />
                </div>
                <span>{$currentLanguage === 'en' ? button.en : button.mm}</span>
            </button>
        </a>
    {/if}
</section>

<style>
    .title-section {
        padding: 20px;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        margin: 0;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 20px;
        }
    }

    .cta-button {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary-color);
        text-decoration: none;
    }

    a {
        text-decoration: none;
    }

    @media (max-width: 768px) {
        .cta-button {
            margin-top: 20px;
        }
    }

    .circle {
        background: var(--tertiary-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        .circle {
            width: 24px;
            height: 24px;
        }
    }

    .cta-button img {
        width: 16px;
        height: 16px;
    }

    @media (max-width: 768px) {
        .cta-button img {
            width: 12px;
            height: 12px;
        }
    }
</style>
