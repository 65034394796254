<script>
    import { currentLanguage, switchLanguage } from "../stores/languageStore";
    import { activeLink } from "../stores/activeLinkStore";
    import { onMount } from "svelte";
    let menuOpen = false;

    const mapLink = {
        '/': 'home',
        '/meet-mung': 'meetMung',
        '/one-on-one-coaching': 'oneOnOneCoaching',
        '/team-coaching': 'teamCoaching',
        '/trainings': 'trainings',
        '/blogs': 'blogs',
        '/shops': 'shops',
        '/contact-us': 'contactMe',
    }

    const translations = {
        en: {
            home: "HOME",
            meetMung: "MEET MUNG",
            oneOnOneCoaching: "ONE ON ONE COACHING",
            teamCoaching: "TEAM COACHING",
            trainings: "TRAININGS",
            blogs: "BLOGS",
            shops: "SHOPS",
            contactMe: "CONTACT ME",
        },
        mm: {
            home: "HOME",
            meetMung: "MEET MUNG",
            oneOnOneCoaching: "ONE ON ONE COACHING",
            teamCoaching: "TEAM COACHING",
            trainings: "TRAININGS",
            blogs: "BLOGS",
            shops: "SHOPS",
            contactMe: "CONTACT ME",
        },
    };

    onMount(() => {
        const storedLanguage = localStorage.getItem("language");
        if (storedLanguage) {
            currentLanguage.set(storedLanguage);
        }
        const storedActiveLink = localStorage.getItem("activeLink");
        const currentPath = window.location.pathname;
        if (storedActiveLink !== mapLink[currentPath]) {
            activeLink.set(mapLink[currentPath]);
        }
        else {
            activeLink.set(storedActiveLink);
        }
    });

    currentLanguage.subscribe((value) => {
        localStorage.setItem("language", value);
    });

    activeLink.subscribe((value) => {
        localStorage.setItem("activeLink", value);
    });

    function toggleMenu() {
        menuOpen = !menuOpen;
    }

    function closeMenu() {
        menuOpen = false;
    }
</script>

<nav class="navbar">
    <div class="logo">
        <a href="/">NL MUNG</a>
    </div>
    <ul class="nav-links {menuOpen ? 'open' : ''}">
        <li class:active={$activeLink === "home"}>
            <a href="/" on:click={() => { activeLink.set("home"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.home : translations.mm.home}
            </a>
        </li>
        <li class:active={$activeLink === "meetMung"}>
            <a href="/meet-mung" on:click={() => { activeLink.set("meetMung"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.meetMung : translations.mm.meetMung}
            </a>
        </li>
        <li class:active={$activeLink === "oneOnOneCoaching"}>
            <a href="/one-on-one-coaching" on:click={() => { activeLink.set("oneOnOneCoaching"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.oneOnOneCoaching : translations.mm.oneOnOneCoaching}
            </a>
        </li>
        <li class:active={$activeLink === "teamCoaching"}>
            <a href="/team-coaching" on:click={() => { activeLink.set("teamCoaching"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.teamCoaching : translations.mm.teamCoaching}
            </a>
        </li>
        <li class:active={$activeLink === "trainings"}>
            <a href="/trainings" on:click={() => { activeLink.set("trainings"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.trainings : translations.mm.trainings}
            </a>
        </li>
        <li class:active={$activeLink === "blogs"}>
            <a href="/blogs" on:click={() => { activeLink.set("blogs"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.blogs : translations.mm.blogs}
            </a>
        </li>
        <li class:active={$activeLink === "shops"}>
            <a href="/shops" on:click={() => { activeLink.set("shops"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.shops : translations.mm.shops}
            </a>
        </li>
        <li class:active={$activeLink === "contactMe"}>
            <a href="/contact-us" on:click={() => { activeLink.set("contactMe"); closeMenu(); }}>
                {$currentLanguage === 'en' ? translations.en.contactMe : translations.mm.contactMe}
            </a>
        </li>
    </ul>
    <div class="icon" on:click={switchLanguage}>
        <a href="#"><img src="/assets/img/icons/globe.svg" alt="Icon" /></a>
    </div>
    <div class="burger" on:click={toggleMenu}>
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
    </div>
    {#if menuOpen}
        <div class="close" on:click={closeMenu}>
            &times;
        </div>
    {/if}
</nav>

<style>
    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primary-color);
        padding: 0 20px;
        position: relative;
        border-radius: 20px;
    }

    .logo a {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        color: var(--secondary-color);
        text-decoration: none;
    }

    .nav-links {
        list-style: none;
        display: flex;
        gap: 20px;
    }

    .nav-links li {
        position: relative;
    }

    .nav-links a {
        text-decoration: none;
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    .nav-links a:hover {
        background: var(--tertiary-color);
        border-radius: 12px;
        color: #0c1674;
        padding: 10px;
    }

    .nav-links .active a {
        background: var(--tertiary-color);
        border-radius: 12px;
        color: #0c1674;
        padding: 10px;
    }

    .icon img {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .burger {
        display: none;
        cursor: pointer;
        flex-direction: column;
        gap: 5px;
    }

    .burger div {
        width: 25px;
        height: 3px;
        background-color: var(--secondary-color);
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    .close {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 36px;
        color: var(--secondary-color);
        cursor: pointer;
        z-index: 1001;
    }

    @media (min-width: 769px) and (max-width: 1337px) {
        .nav-links a {
            font-size: 13px; /* Adjust this value as needed */
        }

        .logo a {
            font-size: 22px; /* Adjust this value as needed */
        }

        .nav-links a:hover,
        .nav-links .active a {
            padding: 8px; /* Adjust padding to maintain design consistency */
        }
    }


    @media (max-width: 768px) {
        .nav-links {
            position: fixed;
            right: 0;
            top: 0;
            height: 100vh;
            background-color: var(--primary-color);
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            transform: translateX(100%);
            transition: transform 0.5s ease-in;
            z-index: 1000;
        }

        .nav-links.open {
            transform: translateX(0);
        }

        .burger {
            display: flex;
        }

        .burger.toggle .line1 {
            transform: rotate(-45deg) translate(-5px, 6px);
        }

        .burger.toggle .line2 {
            opacity: 0;
        }

        .burger.toggle .line3 {
            transform: rotate(45deg) translate(-5px, -6px);
        }

        .navbar .nav-links li {
            margin: 20px 0;
        }

        .close {
            display: block;
        }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        .nav-links {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2px;
            justify-items: left;
        }

        .nav-links li {
            margin: 5px 0;
        }
    }
</style>
