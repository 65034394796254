<script>
    import TestimonialCard from './TestimonialCard.svelte';
    import { onMount } from 'svelte';

    export let testimonials = [];
    let currentPage = 1;
    let totalPages = Math.ceil(testimonials.length / 4);

    function nextPage() {
        currentPage = currentPage < totalPages ? currentPage + 1 : 1;
    }

    function prevPage() {
        currentPage = currentPage > 1 ? currentPage - 1 : totalPages;
    }
</script>

<div class="testimonial-slider">
    <button class="arrow-left" on:click={prevPage}>⬅</button>
    <div class="testimonial-container">
        {#each testimonials.slice((currentPage - 1) * 4, currentPage * 4) as testimonial}
            <div class="testimonial-card-wrapper">
                <TestimonialCard testimonial={testimonial} />
            </div>
        {/each}
    </div>
    <button class="arrow-right" on:click={nextPage}>➡</button>
</div>

<style>
    .testimonial-slider {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 20px;
        overflow: hidden;
        width: 100%;
    }

    @media (max-width: 768px) {
        .testimonial-slider {
            flex-direction: column;
        }
    }

    .testimonial-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        width: 100%;
    }

    @media (max-width: 1024px) {
        .testimonial-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 768px) {
        .testimonial-container {
            grid-template-columns: 1fr;
        }
    }

    .testimonial-card-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .arrow-left,
    .arrow-right {
        background: transparent;
        border: none;
        color: var(--secondary-color);
        background-color: var(--primary-color);
        border-radius: 50%;
        font-size: 24px;
        cursor: pointer;
    }

    .arrow-left:hover,
    .arrow-right:hover {
        color: var(--tertiary-color);
    }
</style>
