<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let quotes = [];

    async function fetchQuotes() {
        const response = await fetch(`${urlRoot}/quotes`);
        quotes = await response.json();
        if (quotes.length !== 0) {
            quotes.forEach(quote => {
                quote.created = new Date(quote.created).toLocaleString();
                quote.image = `${urlRoot}/files/${quote.image}`;
            });
        }
    }

    async function deleteQuote(id) {
        if (confirm("Are you sure you want to delete this quote?")) {
            const response = await fetch(`${urlRoot}/quotes/${id}`, {
                method: "DELETE"
            });

            if (response.ok) {
                alert("Quote deleted successfully");
                fetchQuotes();
            } else {
                alert("Failed to delete quote");
            }
        }
    }
    onMount(fetchQuotes);
</script>

<Breadcrumb path="/admin/quotes" />
<div class="quotes-list">
    <h2>Quote List</h2>
    <button on:click={() => navigate('/admin/quotes/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Is Featured</th>
                <th>Image</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each quotes as quote, index (quote.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{quote.is_featured ? "Yes" : "No"}</td>
                    <td><img src={quote.image} alt={quote.name} width="100" /></td>
                    <td>
                        <button on:click={() => navigate(`/admin/quotes/edit/${quote.id}`)}>Edit</button>
                        <button on:click={() => deleteQuote(quote.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .quotes-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
