<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { onMount } from "svelte";
    export let id;

    let is_featured = false;
    let created = "";
    let image = "";
    let previewImage = "";
    let article_id = null;
    let articles = [];

    async function fetchArticles() {
        const response = await fetch(`${urlRoot}/articles`);
        articles = await response.json();
    }

    async function fetchQuote() {
        const response = await fetch(`${urlRoot}/quotes/${id}`);
        const quote = await response.json();
        is_featured = quote.is_featured;
        created = quote.created;
        image = quote.image;
        previewImage = `${urlRoot}/files/${quote.image}`;
        article_id = quote.article_id;
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                previewImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const fileInput = document.getElementById("image");

        let imageId = image; // Use the existing image ID by default

        if (fileInput.files.length > 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInput.files[0]);

            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });

            if (!fileResponse.ok) {
                alert("Failed to upload image");
                return;
            }

            const fileData = await fileResponse.json();
            imageId = fileData.id; // Update the image ID if a new image is uploaded
        }

        const data = {
            is_featured,
            created,
            image: imageId
        };

        if (article_id) {
            data.article_id = article_id;
        }

        const response = await fetch(`${urlRoot}/quotes/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Quote updated successfully");
            navigate("/admin/quotes");
        } else {
            alert("Failed to update quote");
        }
    }

    async function fetchAll() {
        await fetchArticles();
        await fetchQuote();
    }
    onMount(fetchAll);
</script>

<Breadcrumb path="/admin/quotes/edit" />
<div class="data-form">
    <h2>Update Quote</h2>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="is_featured">Is Featured</label>
            <input type="checkbox" id="is_featured" bind:checked={is_featured} />
        </div>
        <div class="form-group">
            <label for="article_id">Article</label>
            <select id="article_id" bind:value={article_id}>
                <option value="">Select an article</option>
                {#each articles as article}
                    <option value={article.id}>{article.name}</option>
                {/each}
            </select>
        </div>
        <div class="form-group">
            <label for="image">Image (294px x 196px [1.5:1])</label>
            <input type="file" id="image" on:change={handleFileChange}  accept="image/*" bind:value={image}/>
            {#if previewImage}
                <img src={previewImage} alt="Event Image" class="event-image"/>
            {/if}
        </div>
        <button type="submit">Update</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }

    .event-image {
        margin-top: 10px;
        max-width: 100%;
        height: auto;
    }
</style>
