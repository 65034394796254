<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let subCategories = [];

    async function fetchSubCategories() {
        const response = await fetch(`${urlRoot}/sub_categories`);
        subCategories = await response.json();
    }

    async function deleteSubCategory(id) {
        if (confirm("Are you sure you want to delete this sub category?")) {
            let response;
            try {
                response = await fetch(`${urlRoot}/sub_categories/${id}`, {
                    method: "DELETE"
                });
            }
            catch (error) {
                alert("Sub Category is in use. Cannot delete.");
                return;
            }
            finally {
                if (response.status !== 200) {
                    alert("Sub Category is in use. Cannot delete.");
                }
                
                if (response.ok) {
                    alert("Sub Category deleted successfully");
                    fetchSubCategories();
                } else {
                    alert("Failed to delete sub category");
                }
            }
        }
    }
    onMount(fetchSubCategories);
</script>

<Breadcrumb path="/admin/sub-categories" />
<div class="sub-categories-list">
    <h2>Sub Categories List</h2>
    <button on:click={() => navigate('/admin/sub-categories/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Main Category</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each subCategories as category, index (category.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{category.name}</td>
                    <td>{category.main_category.name}</td>
                    <td>
                        <button on:click={() => navigate(`/admin/sub-categories/edit/${category.id}`)}>Edit</button>
                        <button on:click={() => deleteSubCategory(category.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .sub-categories-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
