<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { onMount } from "svelte";

    let name = "";
    let main_category_id = "";
    let mainCategories = [];

    async function handleSubmit(event) {
        event.preventDefault();

        const data = {
            name,
            main_category_id
        };

        const response = await fetch(`${urlRoot}/sub_categories`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Sub category created successfully");
            navigate("/admin/sub-categories");
        } else {
            alert("Failed to create sub category");
        }
    }

    async function fetchMainCategories() {
        const response = await fetch(`${urlRoot}/main_categories`);
        mainCategories = await response.json();
    }

    onMount(fetchMainCategories);
</script>

<Breadcrumb path="/admin/sub-categories/create" />
<div class="data-form">
    <h2>Create Sub Category</h2>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name" bind:value={name} required/>
        </div>
        <div class="form-group">
            <label for="main_category_id">Main Category</label>
            <select id="main_category_id" bind:value={main_category_id} required>
                <option value="">Choose Main Category</option>
                {#each mainCategories as mainCategory}
                    <option value={mainCategory.id}>{mainCategory.name}</option>
                {/each}
            </select>
        </div>
        <button type="submit">Create</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }

    .event-image {
        margin-top: 10px;
        max-width: 100%;
        height: auto;
    }

    select {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }
</style>

