<script>
    import { Router, Route } from "svelte-routing";
    import ProductList from "./list.svelte";
    import ProductCreate from "./create.svelte";
    import ProductEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={ProductList} />
    <Route path="/create" component={ProductCreate} />
    <Route path="/edit/:id" component={ProductEdit} />
</Router>
