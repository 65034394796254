<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let contentHomes = [];

    async function fetchContentHomes() {
        const response = await fetch(`${urlRoot}/content_home`);
        contentHomes = await response.json();
    }

    async function deleteContentHome(id) {
        const response = await fetch(`${urlRoot}/content_home/${id}`, {
            method: "DELETE"
        });
        if (response.ok) {
            contentHomes = contentHomes.filter(contentHome => contentHome.id !== id);
        } else {
            console.error("Failed to delete content home");
        }
    }

    onMount(fetchContentHomes);
</script>

<Breadcrumb path="/admin/content-home" />
<div class="content-home-list">
    <h2>Content Home List</h2>
    <button on:click={() => navigate('/admin/content-home/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Banner Text (EN)</th>
                <th>Banner Text (MM)</th>
                <th>Banner Button (EN)</th>
                <th>Banner Button (MM)</th>
                <th>Banner Image</th>
                <th>Banner Button Link</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each contentHomes as contentHome}
                <tr>
                    <td>{contentHome.id}</td>
                    <td>{contentHome.banner_text.en}</td>
                    <td>{contentHome.banner_text.mm}</td>
                    <td>{contentHome.banner_button.en}</td>
                    <td>{contentHome.banner_button.mm}</td>
                    <td>{contentHome.banner_image}</td>
                    <td>{contentHome.banner_button_link}</td>
                    <td>
                        <button on:click={() => navigate(`/admin/content-home/edit/${contentHome.id}`)}>Edit</button>
                        <button on:click={() => deleteContentHome(contentHome.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .content-home-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
