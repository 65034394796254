<script>
    import CoachingSpotlight from "../../components/CoachingSpotlight.svelte";
    import ButtonsRow from "../../components/ButtonsRow.svelte";
    import TitleSection from "../../components/TitleSection.svelte";
    import TextSection from "../../components/TextSection.svelte";
    import Testimonial from "../../components/Testimonial.svelte";
    import Appointment from "../../components/Appointment.svelte";
    import { urlRoot } from "../../constants";
    import { onMount } from "svelte";

    const buttons = [
        { text: { en: "STRATEGIC PLANNING", mm: "STRATEGIC PLANNING" }, active: false, link: "/team-coaching/#strategic-planning" },
        { text: { en: "NLP 4 ORGANIZATION CULTURE", mm: "NLP 4 ORGANIZATION CULTURE" }, active: false, link: "/team-coaching/#nlp-4-organization-culture" },
        { text: { en: "LEARDERSHIP DEVELOPMENT", mm: "LEARDERSHIP DEVELOPMENT" }, active: false, link: "/team-coaching/#leadership-development" }
    ];
    let team_coaching_businesses = 0;
    let team_coaching_count = 0;
    let coachingData = {
        title: {
            en: "TEAM COACHING",
            mm: "TEAM COACHING"
        },
        stats: [
            { label: { en: "Businesses", mm: "Businesses" }, value:  `${team_coaching_businesses}+` },
            { label: { en: "Coaching Sessions", mm: "Coaching Sessions" }, value: `${team_coaching_count}+` }
        ],
        button: {
            en: "GET AN APPOINTMENT",
            mm: "Appointment ရယူရန်",
            link: "/team-coaching/#appointment",
        },
        description: {
            en: "Empower your team to excel with our integrated coaching approach, centered on achieving clarity and alignment, fostering cultural transformation, and enhancing skills for sustainable growth in team leadership development.\nI'm learning from international institutions to serve you.",
            mm: "ခေါင်းဆောင်မှုတွင် စဉ်ဆက်မပြတ်တိုးတက်စေဖို့ အတတ်ပညာများမြှင့်တင်ပေးရင်း တိုးတက်စေမည့်ယဉ်ကျေးမှုဓလေ့များ ဖန်တီးကာ ရှင်းလင်းမှု၊ သဟဇာတဖြစ်မှုတို့ကို ပေါင်းစပ်အခြေခံထားတဲ့ Coaching ပုံစံဖြင် သင့်အဖွဲ့အစည်းကို အကောင်းဆုံး ပံ့ပိုးပေးနေပါတယ်။\nမိတ်ဆွေကို အထိရောက်ဆုံး ပံ့ပိုးပေးနိုင်ဖို့ နိုင်ငံတကာရှိ အဖွဲ့အစည်းများကနေ သင်ယူမှုတွေ လုပ်နေပါတယ်။"
        },
        buttonMilestones: {
            en: "SEE MUNG’S MILESTONES",
            mm: "SEE MUNG’S MILESTONES",
            link: "/meet-mung/#milestones"
        },
        logos: [
            "/assets/img/institutions/1.jpeg",
            "/assets/img/institutions/2.jpeg",
            "/assets/img/institutions/3.jpeg",
            "/assets/img/institutions/4.jpeg",
            "/assets/img/institutions/5.jpeg",
            "/assets/img/institutions/6.jpeg",
            "/assets/img/institutions/7.jpeg",
            "/assets/img/institutions/8.jpeg",
            "/assets/img/institutions/9.jpeg",
            "/assets/img/institutions/10.jpeg",
            "/assets/img/institutions/11.jpeg",
        ]
    };

    async function fetchData() {
        let response = await fetch(`${urlRoot}/stats`);
        let data = await response.json();
        team_coaching_businesses = data.team_coaching_businesses;
        team_coaching_count = data.team_coaching_count;
        coachingData.stats[0].value = `${team_coaching_businesses}+`;
        coachingData.stats[1].value = `${team_coaching_count}+`;
        let banner_image = null;
        response = await fetch(`${urlRoot}/banners`);
        data = await response.json();
        if (data.team_coaching_banner_image) {
            banner_image = `${urlRoot}/files/${data.team_coaching_banner_image}`;
        }
        else {
            banner_image = "/assets/img/coaching-spotlight.jpg";
        }
        coachingData.spotlightImage = banner_image;
    }

    const strategicPlanning= {
        title: {
            en: "STRATEGIC PLANNING",
            mm: "STRATEGIC PLANNING"
        },
        appointmentBtn: {
            en: "GET AN APPOINTMENT",
            mm: "Appointment ရယူပါ။",
            link: "/team-coaching/#appointment"
        },
        subtitle: {
            en: "SYNERGY IN STRATEGIC: TRANSFORM VISION INTO ACTION",
            mm: "မဟာဗျူဟာတွင်း ပေါင်းစည်းမှု – အနောဂတ်မျှော်မှန်းချက်တွေကို လက်တွေ့ဖြစ်အောင် ပြောင်းလဲလိုက်ပါ။ " 
        },
        paragraphs: {
            en: [
                "Strategic Planning Team Coaching is indispensable for organizations aiming to chart a course towards success in today's dynamic business landscape. By bringing together key stakeholders, fostering collaboration, and aligning collective efforts, this coaching approach ignites innovation, synergy, and strategic clarity.",
                "Teams engaged in Strategic Planning Team Coaching benefit from a multifaceted journey of discovery, empowerment, and action. They gain a deep understanding of their organization's vision, mission, and goals, enabling them to envision the future with clarity and purpose. Through interactive workshops, tailored exercises, and facilitated discussions, team members cultivate a shared understanding of strategic priorities, identify opportunities, and co-create actionable plans to achieve them.",
                "Beyond mere planning, Strategic Planning Team Coaching instills a culture of agility, resilience, and adaptability within the team. Armed with newfound insights and strategies, team members are equipped to navigate uncertainty, seize emerging opportunities, and drive meaningful change. They emerge as a cohesive force, empowered to translate strategic intentions into tangible results that propel the organization towards its envisioned future.",
                "In essence, Strategic Planning Team Coaching is not just about setting goals; it's about unleashing the collective potential of teams, fostering innovation, and orchestrating transformative change that propels organizations towards sustained success and relevance in an ever-evolving market landscape."
            ],
            mm: [
                "အပြောင်းအလဲမြန်ဆန်တဲ့ ဒီနေ့ခေတ်စီးပွါးရေးအခင်းအကျင်းမှာ အောင်မြင်မှုဆီကို ဦးတည်ရုန်းကန်နေရတဲ့ လုပ်ငန်းတိုင်းအတွက် Strategic Planning Team Coaching ဟာ မရှိလို့မဖြစ်အောင် အရေးပါပါတယ်။ ဒီ Coaching က လုပ်ငန်းရဲ့ အဓိကပါဝင်သူတွေကို စုစည်းခြင်း၊ ပူးပေါင်းဆောင်ရွက်မှုကို မြှင့်တင်ခြင်း၊ စုပေါင်းအားထုတ်မှုတွေကို သဟဇာတဖြစ်အောင် ချိန်ညှိပေးခြင်းတို့ဖြင့် တီထွင်ဆန်းသစ်မှု၊ ပေါင်းစပ်မှုရလဒ်နှင့် ဗျူဟာကျတဲ့ရှင်းလင်းမှုတို့ကို ပေးစွမ်းနိုင်ပါတယ်။",
                "Strategic Planning Team Coaching နှင့်သွားတဲ့ team တွေဟာ စူးစမ်းဖော်ထုတ်ခြင်း၊ စွမ်းရည်မြှင့်တင်ခြင်း၊ လက်တွေ့လုပ်ဆောင်ခြင်းတို့ ဘက်စုံပေါင်းစပ်ထားတဲ့ ဖြစ်စဉ်ကနေ သိသာထင်ရှားတဲ့ အကျိုးကျေးဇူးတွေ ရရှိကြပါတယ်။ ပြီးတော့ မိမိတို့ အဖွဲ့အစည်းရဲ့ မျှော်မှန်းချက်တွေ၊ လုပ်ငန်းတာဝန်တွေ၊ ပန်းတိုင်တွေကို နက်ရှိုင်းစွာနားလည်ပြီး ရှင်းလင်းတဲ့ ရည်ရွယ်ချက်နှင့် အနာဂတ်ကို ပုံဖော်လာနိုင်မှာ ဖြစ်ပါတယ်။ Team member တွေဟာ အပြန်အလှန်ဆွေးနွေးဖလှယ်တဲ့ Workshop တွေ၊ မိမိတို့နှင့်ကိုက်ညီတဲ့ လေ့ကျင့်ခန်းတွေ၊ ဆွေးနွေးမှုတွေကနေ ဦးစားပေးဆောင်ရွက်ရမဲ့ အရာတွေကို ဗျူဟာကျကျ ဖော်ထုတ်တတ်လာမယ်၊ အခွင့်အလမ်းတွေကို မြင်လာမယ်၊ အဲဒါတွေကို ရရှိဖို့ team နှင့်အတူလုပ်ဆောင်ရမဲ့ Action Plan တွေကို ပူးပေါင်း ချမှတ်နိုင်မှာဖြစ်ပါတယ်။",
                "Strategic Planning Team Coaching ဆိုတာ စာရွက်ပေါ် plan တွေ ချရေးရုံပဲ မဟုတ်ပါဘူး။ မိမိတို့ရဲ့ team အတွင်း လျင်မြန်သွက်လက်မှု၊ ခံနိုင်ရည်ရှိမှု၊ လိုက်လျောညီထွေရှိမှု စတဲ့အလေ့အကျင့်တွေကိုပါ မွေးထုတ်ပေးပါတယ်။ အတွေးခေါ်အသစ်၊ ဗျူဟာအသစ်တွေ ပေါင်းစပ်ပြီး မရေရာမှုကြားက အခွင့်အရေးတွေကို အမိအရ ဆုပ်ကိုင်လာနိုင်တာ၊ အဓိပ္ပါယ်ပြည့်ဝတဲ့ အပြောင်းအလဲတွေ ဖန်တီးလာနိုင်တာကို ကြုံတွေ့ကြရမှာပါ။ အဲဒီအပြောင်းအလဲတွေဟာ team member တစ်ဦးချင်းစီကနေ အပေးအယူမျှတစွာ ပေါ်ထွက်လာမှာဖြစ်ပြီး အဖွဲ့အစည်းရဲ့ ဗျူဟာကျတဲ့ရည်မှန်းချက်တွေကို သိသာထင်ရှားတဲ့ ရလဒ်တွေအဖြစ် ပြောင်းလဲကာ မိမိတို့ မျှော်မှန်းတဲ့ အနာဂတ်ကိုရောက်အောင် တွန်းပို့ပေးမှာဖြစ်ပါတယ်။",
                "အနှစ်သာရအားဖြင့် Strategic Planning Team Coaching ဟာ ပန်းတိုင်တွေ သတ်မှတ်ရုံနှင့် ပြီးသွားတာမဟုတ်ပါဘူး။ မိမိတို့ team member တစ်ဦးချင်းရဲ့ အမြင့်ဆုံးအလားအလာတွေကို ဖော်ထုတ်ပေါင်းစပ်ပြီး အမြဲတမ်းပြောင်းလဲနေတဲ့ စျေးကွက်ထဲမှာ တီထွင်ဆန်းသစ်မှုတွေနှင့်အတူ မိမိတို့ အဖွဲ့အစည်းအတွက် ရေရှည်တည်တံ့တဲ့ အောင်မြင်မှုတွေရရှိအောင် ပြုပြင်ပြောင်းလဲပေးတဲ့ ဖြစ်စဉ်တစ်ခုပါ။",
            ]
        },
        appointmentBtnTwo: {
            text: {
                en: "GET STRATEGIC PLANNING APPOINTMENT",
                mm: "STRATEGIC PLANNING APPOINTMENT ရယူရန်"
            },
            link: "/team-coaching/#appointment",
            show: true
        }
    };

    const nlpOrganizaionculture = {
        title: {
            en: "NLP 4 ORGANIZATION CULTURE",
            mm: "NLP 4 ORGANIZATION CULTURE"
        },
        appointmentBtn: {
            en: "GET AN APPOINTMENT",
            mm: "Appointment ရယူပါ",
            link: "/team-coaching/#appointment"
        },
        subtitle: {
            en: "CULTIVATE CULTURE, DRIVE SUCCESS",
            mm: "အလေ့အထကောင်းတွေ ပျိုးထောင်ပြီး အောင်မြင်မှုရယူပါ။" 
        },
        paragraphs: {
            en: [
                "NLP for Organizational Culture offers a transformative approach to shaping the very essence of your workplace environment. By leveraging the principles and techniques of Neuro-Linguistic Programming (NLP), this innovative coaching methodology delves deep into the core beliefs, values, and behaviors that define your organization's culture.",
                'Through NLP, teams embark on a journey of self-discovery and empowerment, uncovering unconscious patterns, biases, and limitations that may be hindering growth and collaboration. By embracing the presuppositions of NLP, such as "Every behavior has a positive intention" and "People already have all the resources they need to succeed," teams learn to reframe challenges, foster empathy, and cultivate a culture of trust, respect, and resilience',
                "NLP Team Coaching for Organizational Culture is not a one-size-fits-all solution; it's a tailor-made approach designed to address the unique needs and dynamics of your team. Facilitated by experienced Myanmar 1st NLP Double Master Trainer, coaching sessions are interactive, engaging, and highly experiential, empowering team members to explore new perspectives, communicate more effectively, and align their actions with shared organizational values and goals.",
                "Through this transformative process, teams emerge stronger, more cohesive, and better equipped to navigate change, overcome obstacles, and drive sustainable growth. NLP for Organizational Culture is not just about changing behaviors; it's about igniting a cultural revolution that empowers individuals, fosters collaboration, and propels your organization towards its highest potential."
            ],
            mm: [
                "NLP for Organizational Culture ကတော့ ကောင်းမွန်တဲ့လုပ်ငန်းခွင်တစ်ခုဖြစ်လာဖို့ အဓိကကျတဲ့အရာတွေကို ပြုပြင်ပြောင်းလဲပေးတဲ့ နည်းလမ်းတစ်ခုပါ။ Neuro-Linguistic Programming (NLP) ရဲ့ နည်းစနစ်နှင့် ကျင့်စဉ်တွေကို အသုံးပြုပြီး မိမိလုပ်ငန်းထဲမှာရှိတဲ့ လူတစ်ဦးချင်းရဲ့ ယုံကြည်မှု၊ စံတန်ဖိုးနှင့် အပြုအမူတွေ ကောင်းမွန်လာအောင် အတွင်းဆုံးအထိ ပြုပြင်ပြောင်းလဲပေးတဲ့ Coaching တစ်မျိုးဖြစ်ပါတယ်။",
                "NLP က team member တစ်ဦးချင်း မိမိကိုမိမိ ရှာဖွေတွေ့ရှိစေပြီး စွမ်းဆောင်ရည်တွေ မြင့်တက်လာစေပါတယ်။ မိမိတို့ရဲ့ တိုးတက်မှုကို အဟန့်အတားဖြစ်စေတဲ့ ကိုယ်တိုင်က မသိသေးတဲ့ အမူအကျင့်ပုံစံတွေ၊ ယုံကြည်မှုတွေ၊ ကန့်သတ်မှုတွေကို ဖော်ထုတ်ပေးတယ်။ “အပြုအမူတိုင်းမှာ အပြုသဘောဆောင်တဲ့ ရည်ရွယ်ချက်ရှိတယ်၊” “လူတိုင်းမှာ အောင်မြင်ဖို့ လုံလောက်တဲ့ အရင်းမြစ်တွေရှိတယ်” စတဲ့ NLP ကြိုတင်ယူဆချက်တွေကို ကိုင်စွဲပြီး အသင်းအဖွဲ့တွေဟာ စိန်ခေါ်မှုတွေကို ပြောင်းလဲပုံဖော်ပြီး တစ်ဦးနှင့်တစ်ဦးကြား ကိုယ်ချင်းစာတရား၊ ယုံကြည်မှု၊ လေးစားမှု၊ ခံနိုင်ရည်ရှိမှု စတဲ့ အလေ့အထတွေကို ပျိုးထောင်နိုင်ပါတယ်။",
                "NLP Team Coaching for Organizational Culture မှာ အဖွဲ့အစည်းတိုင်းအတွက် တစ်မျိုးတည်းနှင့် ပြီးပြည့်စုံတဲ့ Coaching ရယ်လို့တော့မရှိပါဘူး။ ကိုယ့် team ရဲ့လိုအပ်ချက်အရ ကိုယ်နှင့်ကိုက်ညီမဲ့  နည်းလမ်းဖြစ်အောင်  ပြုပြင်ပြီး အသုံးပြုရတာပါ။ အတွေ့အကြုံရှိတဲ့ မြန်မာပြည်ရဲ့ နံပါတ်တစ် NLP Double Master Trainer တစ်ဦးမှ အပြန်အလှန်ဆွေးနွေးပြီး Coaching လုပ်ပေးသွားမှာဖြစ်ပါတယ်။ အဲဒီ Coaching Session တွေကတဆင့် team member တွေဟာ အမြင်သစ်တွေ ဖော်ထုတ်ပြီး ထိရောက်စွာ ပေါင်းသင်းဆက်ဆံတတ်လာမယ်၊ မိမိတို့တစ်ဦးချင်းရဲ့ လုပ်ဆောင်ချက်တိုင်းကို အဖွဲ့အစည်း တခုလုံးရဲ့ စံတန်ဖိုး၊ ရည်မှန်းချက်တွေနှင့် ကိုက်ညီအောင် ဆောင်ရွက်တတ်လာမှာ ဖြစ်ပါတယ်။",
                "ဒီပြုပြင်ပြောင်းလဲမှု ဖြစ်စဉ်ကနေ မိမိရဲ့ team ဟာ ပိုသန်မာပြီး အပေးအယူမျှလာတာ၊ အပြောင်းအလဲတွေကို ရင်ဆိုင်ဆိုင်လက်ခံပြီး အတားအဆီးတွေကို ကျော်လွှားနိုင်လာတာ၊ စဉ်ဆက်မပြတ် တိုးတက်ပြောင်းလဲမှုတွေဆီ ဦးတည်လာတာကို မြင်တွေ့ရမှာပါ။ NLP for Organizational Culture ဟာ အပြုအမူတွေကို ပြောင်းလဲပေးရုံသက်သက် မဟုတ်ပါဘူး။ တစ်ဦးချင်းရဲ့ စွမ်းဆောင်ရည်တွေကို မြှင့်တင်ပေးလို့ ပူးပေါင်းဆောင်ရွက်မှုတွေအားကောင်းစေပြီး မိမိတို့ အဖွဲ့အစည်းတခုလုံးကို အမြင့်ဆုံးအလားအလာအထိ ဆွဲတင်ပေးနိုင်တဲ့ ဓလေ့အပြောင်းအလဲကြီးတစ်ခုဖြစ်ပါတယ်။"
            ]
        },
        appointmentBtnTwo: {
            text: {
                en: "GET NLP 4 ORGANIZATION CULTURE APPOINTMENT",
                mm: "NLP 4 ORGANIZATION CULTURE APPOINTMENT ရယူရန်"
            },
            link: "/team-coaching/#appointment",
            show: true
        }
    };

    const leadershipDevelopment= {
        title: {
            en: "LEADERSHIP DEVELOPMENT FACILITATION",
            mm: "LEADERSHIP DEVELOPMENT FACILITATION"
        },
        appointmentBtn: {
            en: "GET AN APPOINTMENT",
            mm: "Appointment ရယူပါ",
            link: "/team-coaching/#appointment"
        },
        subtitle: {
            en: "CRAFTING LEADERS, SHAPING SUCCESS",
            mm: "ခေါင်းဆောင်များအားဖန်းတီးပြီး အောင်မြင်မှုကို ပုံဖော်ပါ။" 
        },
        paragraphs: {
            en: [
                "Leadership Development Facilitation (LDF) offers a personalized approach to cultivating exceptional leadership within your organization. Our process begins with a comprehensive assessment of your leadership team's skills, strengths, and areas for growth. Using proven methodologies, including renowned John C. Maxwell leadership courses as our foundation, we tailor our facilitation sessions to address the specific needs and aspirations of your team.",
                "Through interactive workshops, engaging discussions, and practical exercises, our experienced facilitators guide your leaders on a transformative journey of self-discovery and growth. They learn to unlock their full potential, enhance their communication skills, and cultivate the mindset and behaviors of effective leaders.",
                "At the heart of our approach is a commitment to empowering leaders to lead with integrity, authenticity, and vision. By equipping them with the tools, insights, and strategies they need to succeed, we empower your leadership team to drive positive change, inspire others, and achieve extraordinary results.",
                "Transform your leadership culture and unleash the full potential of your organization with Leadership Development Facilitation."
            ],
            mm: [
                "Leadership Development Facilitation (LDF) ဟာ သင့်အဖွဲ့အစည်းအတွင်း ထူးခြားကောင်းမွန်တဲ့ ခေါင်းဆောင်မှုစွမ်းရည်ကို မွေးမြူဖို့ အထူးရည်ရွယ်တဲ့ နည်းလမ်းတစ်ခုဖြစ်ပါတယ်။ ဒီနည်းမှာဆိုရင် အဖွဲ့အစည်းအတွင်းမှာရှိတဲ့ ခေါင်းဆောင်တွေရဲ့ ခေါင်းဆောင်မှုစွမ်းရည်၊ ကျွမ်းကျင်မှုနှင့် အားသာချက်တွေ၊ တိုးတက်ရန်လိုအပ်နေသေးတဲ့နယ်ပယ်တွေကို အရင်ဆုံး အကဲဖြတ်ပါတယ်။ John C. Maxwell ရဲ့ ခေါင်းဆောင်မှု အခြေခံနှင့် သင်ရိုးတွေအပါအဝင် တွင်တွင်ကျယ်ကျယ် အသုံးပြုပြီးသားနည်းစနစ်များဖြင့် team တစ်ခုချင်းရဲ့ လိုအပ်ချက်ပေါ်မူတည်ပြီး ခေါင်းဆောင်မှုနှင့်ပတ်သက်တဲ့ Coaching တွေ ဆောင်ရွက်ပေးပါတယ်။",
                "မိတ်ဆွေရဲ့ team ထဲက ခေါင်းဆောင်တွေအားလုံး တက်ကြွစွာ ပါဝင်ဆွေးနွေးပြီး လက်တွေ့လေ့ကျင့်ခန်းတွေ လုပ်ဆောင်နိုင်အောင် ကျနော်တို့ရဲ့ အတွေ့အကြုံပြည့်ဝတဲ့ Facilitator များမှ ဦးဆောင်ဆွေးနွေးပေးမှာ ဖြစ်ပါတယ်။ ခေါင်းဆောင်းတွေအနေနှင့် မိမိကိုမိမိ ရှာဖွေဆန်းစစ်ပြီး တိုးတက်ပြောင်းလဲခြင်း ခရီးစဉ်ကို စတင်လျှောက်လှမ်းနိုင်မှာ ဖြစ်ပါတယ်။ ခေါင်းဆောင်တစ်ဦးချင်းက သူတို့ရဲ့ အမြင့်ဆုံး အလားအလာကို ဖော်ထုတ်ပြီး လူမှုဆက်ဆံရေးကောင်းမွန်ဖို့၊ ထိရောက်စွာဦးဆောင်နိုင်ဖို့အတွက် လိုအပ်တဲ့ စိတ်ပိုင်းဆိုင်ရာ၊ ရုပ်ပိုင်းဆိုင်ရာ အမူအကျင့်တွေကို လေ့ကျင့် ပျိုးထောင်နိုင်မှာ ဖြစ်ပါတယ်။",
                "ကျနော်တို့ရဲ့ အဓိကကျတဲ့ တာဝန်ကတော့ ခေါင်းဆောင်တွေကို သမာသမတ်ရှိမှု၊ စစ်မှန်မှုတို့နှင့်  အနာဂတ်မျှော်မှန်းချက် ရှင်းလင်းစွာ ဦးဆောင်နိုင်အောင် ကူညီပေးဖို့ဖြစ်ပါတယ်။ ဒီခေါင်းဆောင်တွေကို လုပ်ငန်းအောင်မြင်မှုအတွက် လိုအပ်တဲ့ နည်းစနစ်တွေ၊ အတွေးအမြင်တွေ၊ နည်းဗျူဟာတွေကို တပ်ဆင်ပေးပြီး အပြုသဘောဆောင်တဲ့ အပြောင်းအလဲတွေဖန်တီးနိုင်အောင်၊ အခြားသူတွေကိုပါ လှုံ့ဆော်နိုင်ပြီး တစ်ကြိမ်တစ်ခါမှ မရခဲ့ဖူးတဲ့ ရလဒ်ကောင်းတွေ ရရှိအောင် စွမ်းအားမြှင့်တင်ပေးမှာဖြစ်ပါတယ်။",
                "Leadership Development Facilitation ဖြင့် ခေါင်းဆောင်မှုအလေ့အထကို ပြောင်းလဲပြီး သင့်အဖွဲ့အစည်းရဲ့ အမြင့်ဆုံးအလားအလာအထိ တက်လှမ်းပါ။"
            ]
        },
        appointmentBtnTwo: {
            text: {
                en: "GET LEADERSHIP DEVELOPMENT FACILITATION APPOINTMENT",
                mm: "LEADERSHIP DEVELOPMENT FACILITATION APPOINTMENT ရယူရန်"
            },
            link: "/team-coaching/#appointment",
            show: true
        }
    };

    onMount(fetchData);


</script>

<svelte:head>
    <title>Team Coaching | NL Mung | nlmung.com</title>
    <meta name="description" content="Strategic Planning and Team Coaching give a deep understanding of an organization's vision, mission, and goals, enabling them to TRANSFORM INTO ACTION." />
</svelte:head>

<CoachingSpotlight
    coachingData={coachingData}
/>
<ButtonsRow
    buttons={buttons}
/>
<TitleSection
    title={strategicPlanning.title}
    button={strategicPlanning.appointmentBtn}
    id="strategic-planning"
/>
<TextSection
    title={strategicPlanning.subtitle}
    paragraphs={strategicPlanning.paragraphs}
    button={strategicPlanning.appointmentBtnTwo}
/>
<TitleSection
    title={nlpOrganizaionculture.title}
    button={nlpOrganizaionculture.appointmentBtn}
    id="nlp-4-organization-culture"
/>
<TextSection
    title={nlpOrganizaionculture.subtitle}
    paragraphs={nlpOrganizaionculture.paragraphs}
    button={nlpOrganizaionculture.appointmentBtnTwo}
/>
<TitleSection
    title={leadershipDevelopment.title}
    button={leadershipDevelopment.appointmentBtn}
    id="leadership-development"
/>
<TextSection
    title={leadershipDevelopment.subtitle}
    paragraphs={leadershipDevelopment.paragraphs}
    button={leadershipDevelopment.appointmentBtnTwo}
/>
<Testimonial />
<Appointment
    appointmentFor="Team Coaching"
    id="appointment"
/>
