<script>
    export let buttons = [];
    let hoveredCategory = null;

    function showSubcategories(category) {
        hoveredCategory = category;
    }

    function hideSubcategories() {
        hoveredCategory = null;
    }
</script>

<div class="main-categories">
    {#each buttons.filter(button => !button.sub) as button}
        <div
            class="main-category-button"
            on:mouseenter={() => showSubcategories(button)}
            on:mouseleave={hideSubcategories}
        >
            {button.text.en}
            {#if hoveredCategory === button}
                <div class="sub-categories">
                    {#each buttons.filter(subButton => subButton.link && subButton.main === button.text.en) as subButton}
                        <a href={subButton.link} class="sub-category-button">
                            {subButton.text.en}
                        </a>
                    {/each}
                </div>
            {/if}
        </div>
    {/each}
</div>

<style>
    .main-categories {
        display: grid;
        justify-content: center;
        gap: 10px;
        position: sticky;
        top: 0;
        background-color: white;
        padding: 10px;
        z-index: 10;
        font-family: "Roboto Flex", sans-serif;
        grid-template-columns: repeat(6, 0fr);
    }

    @media (max-width: 768px) {
        .main-categories {
            grid-template-columns: repeat(3, 0fr);
        }
    }

    .main-category-button {
        background-color: var(--tertiary-color);
        color: var(--primary-color);
        padding: 10px;
        border-radius: 5px;
        font-size: 18px;
        cursor: pointer;
        position: relative;
        min-width: 100px;
        text-align: center;
    }

    .main-category-button:hover {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    .sub-categories {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        background-color: var(--secondary-color);
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
        border-radius: 5px;
        z-index: 20;
        font-family: "Roboto Flex", sans-serif;
    }

    .sub-category-button {
        background-color: var(--tertiary-color);
        color: var(--primary-color);
        text-decoration: none;
        padding: 10px;
        border-radius: 3px;
        font-size: 16px;
        z-index: 20;
        min-width: 100px;
    }

    .sub-category-button:hover {
        background-color: var(--primary-color);
        color: var(--tertiary-color);
    }
</style>
