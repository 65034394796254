import { writable } from 'svelte/store';

// Create writable stores for mainPhoneNumber and mainEmail
export let urlRoot = 'https://nlmung.com/api';
export let mainPhoneNumber = writable('');
export let mainEmail = writable('');

export async function fetchAndSetPhoneAndEmail() {
    try {
        const response = await fetch(`${urlRoot}/constants`);
        const data = await response.json();
        data.forEach(constant => {
            if (constant.name === 'mainPhoneNumber') {
                mainPhoneNumber.set(constant.value);
                console.log(mainPhoneNumber);
            } else if (constant.name === 'mainEmail') {
                mainEmail.set(constant.value);
            }
        });
    } catch (error) {
        console.error("Error fetching constants:", error);
    }
}
