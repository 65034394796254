<script>
    import MilestoneCard from './MilestoneCard.svelte';

    export let milestones;
</script>

<div class="milestone-grid">
    {#each milestones as milestone}
        <MilestoneCard {milestone} />
    {/each}
</div>

<style>
    .milestone-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    @media (max-width: 1024px) {
        .milestone-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 768px) {
        .milestone-grid {
            grid-template-columns: 1fr;
        }
    }
</style>
