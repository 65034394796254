<script>
    import { currentLanguage } from "../stores/languageStore";
    
    export let event;
    
    const translations = {
        en: {
            seeDetails: "SEE DETAILS"
        },
        mm: {
            seeDetails: "အသေးစိတ်ကြည့်ပါ"
        }
    };
    console.log(event);
</script>

<div class="card">
    <div class="image-container">
        <img src={event.image}
            alt={$currentLanguage === 'en' ? event.title.en : event.title.mm}
        />
    </div>
    <div class="content">
        <div class="meta">
            <span class="date">🗓️ {event.date}</span>
            <span class="type">{event.type}</span>
        </div>
        <h3>{$currentLanguage === 'en' ? event.title.en : event.title.mm}</h3>
        <p>
            {$currentLanguage === 'en' ? event.description.en : event.description.mm}
            <br>
            <br>
            <br>
            {#if event.trainer}
                {$currentLanguage === 'en' ? `Trainer: ${event.trainer}` : `Trainer: ${event.trainer}`}
                <br>
                <br>
            {/if}
            {$currentLanguage === 'en' ? `Location: ${event.location}` : `နေရာ: ${event.location}`}
        </p>
        <a class="cta" href={event.link} target="_blank">
            <div class="circle">
                <img src="/assets/img/icons/arrow-up.svg" alt="Arrow" />
            </div>
            <span>{$currentLanguage === 'en' ? translations.en.seeDetails : translations.mm.seeDetails}</span>
        </a>
    </div>
</div>

<style>
    .card {
        background: var(--primary-color);
        border-radius: 20px;
        overflow: hidden;
        color: var(--secondary-color);
        display: flex;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
        flex-direction: column;
    }

    .image-container {
        width: 100%;
        height: 560px !important;
        overflow: hidden;
    }

    .image-container img {
        width: 100%;
        height: 560px !important;
    }
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .content {
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .meta {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }

    .date, .type {
        background: var(--tertiary-color);
        color: #0c1674;
        border-radius: 12px;
        padding: 5px 10px;
        font-size: 14px;
        font-family: "Roboto Flex", sans-serif;
        margin-bottom: 5px;
    }

    h3 {
        margin: 10px 0;
        font-size: 20px;
        font-weight: 700;
    }

    p {
        font-size: 14px;
        margin: 10px 0;
        flex-grow: 1;
    }

    .cta {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: var(--secondary-color);
        align-self: flex-end; /* Move button to the right */
        margin-top: 10px;
    }

    .circle {
        background: var(--tertiary-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .cta img {
        width: 16px;
        height: 16px;
    }

    @media (min-width: 600px) {
        .card {
            flex-direction: row;
        }

        .image-container {
            width: 40%;
        }

        .content {
            width: 60%;
        }

        h3 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }

        .cta {
            font-size: 16px;
        }
    }

    @media (min-width: 768px) {
        .card {
            flex-direction: row;
        }

        .image-container {
            width: 50%;
        }

        .content {
            width: 50%;
        }

        h3 {
            font-size: 28px;
        }

        p {
            font-size: 18px;
        }

        .cta {
            font-size: 18px;
        }
    }
</style>
