<script>
    import TitleSection from "./TitleSection.svelte";
    import TextSection from "./TextSection.svelte";
    import TestimonialSlider from "./TestimonialSlider.svelte";
    import Button from "./Button.svelte";
    import { buildQuery } from "../utils";
    import { urlRoot } from "../constants";
    import { onMount } from "svelte";

    const title = {
        en: "TESTIMONIALS",
        mm: "သက်သေခံချက်များ"
    };

    const paragraphs = {
        en: [
            "As a Business Coach and Trainer, I have been blessed to work with many great Business Owners and Leaders. The blessing of being a Business Coach and Trainer is having the opportunity to learn from the great personalities we work with. My journey would not be the same without their sharing. I sincerely thank them from the bottom of my heart."
        ],
        mm: [
            "Business Coach & Trainer တစ်ဦးအနေနှင့်  သူမတူအောင်ထူးချွန်တဲ့ လုပ်ငန်းရှင်တွေ၊ ခေါင်းဆောင်ကြီးတွေအများကြီးနှင့် အလုပ် တွဲလုပ်ခွင့်ရခဲ့ပါတယ်။ ဒီလူတွေနှင့် အလုပ်တွဲလုပ်ပြီး ကျနော် အကြီးမားဆုံးရခဲ့တဲ့ ဆုလက်ဆောင်ကတော့ သူတို့ဆီက အလွန်ကောင်းမွန်တဲ့ ကိုယ်ရည်ကိုယ်သွေးတွေကို အနီးကပ်သင်ယူလေ့လာခွင့် ရခဲ့တာပါပဲ။ ဒီအရာတွေကပဲ ကျနော့်ကို ဒီနေ့အထိ အကောင်းဆုံးရှင်သန်စေခဲ့တာပါ။ သူတို့အားလုံးကို ဒီနေရာကနေ လှိုက်လှိုက်လှဲလှဲ ကျေးဇူးတင်ပါတယ်။"
        ]
    };


    const appointmentBtn = {
        en: "Share Your Transformation",
        mm: "သင့်ရဲ့ ပြောင်းလဲမှုကို မျှဝေပါ",
        link: "/testimonial",
    };

    const appointmentBtnMobile = {
        text: {
            en: "Share Your Transformation",
            mm: "သင့်ရဲ့ ပြောင်းလဲမှုကို မျှဝေပါ",
        },
        link: '/testimonial',
        openNewTab: false
    };

    let isLoaded = false;
    let testimonials = [];

    async function fetchTestimonials() {
        const filters = {
            publish_status: true
        };
        const query = buildQuery(`${urlRoot}/testimonials`, filters);
        const response = await fetch(query);
        testimonials = await response.json();
        testimonials.forEach(testimonial => {
            testimonial.title = {
                en: testimonial.programs,
                mm: testimonial.programs_mm
            };
            testimonial.description = {
                en: testimonial.take_away,
                mm: testimonial.take_away
            };
            testimonial.organization = testimonial.organization;
            testimonial.position = testimonial.role;
            testimonial.image = `${urlRoot}/files/${testimonial.photo}`;
        });
        isLoaded = true;
        console.log(testimonials);
    }

    const isMobile = window.innerWidth < 768;

    onMount(fetchTestimonials);

</script>

<TitleSection
    title={title}
    button={appointmentBtn}
    id="testimonials"
/>
<TextSection
    paragraphs={paragraphs}
/>
{#if isLoaded}
    <TestimonialSlider
        testimonials={testimonials}
    />
{/if}

{#if isMobile}
    <div class="text-center">
        <Button
            button={appointmentBtnMobile}
        />
    </div>
{/if}
