<script>
    export let testimonial;
    export let onClose;

    function closeModal(event) {
        event.stopPropagation();
        onClose();
    }
</script>

<div class="modal-overlay" on:click={closeModal}>
    <div class="modal-content" on:click|stopPropagation>
        <h3 class="modal-title">{testimonial.title.en}</h3>
        <p class="modal-description">{testimonial.description.en}</p>
        <h4 class="modal-description">How is Mung's service different from others?</h4>
        <p class="modal-description">{testimonial.differences}</p>
        <div class="modal-footer">
            <img src={testimonial.image} alt={testimonial.name} class="modal-image" />
            <div class="modal-info">
                <p class="modal-name">{testimonial.name}</p>
                <p class="modal-position">{testimonial.position}</p>
                <p class="modal-position">{testimonial.organization}</p>
            </div>
        </div>
        <button class="close-button" on:click={closeModal}>Close</button>
    </div>
</div>

<style>
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background: var(--secondary-color);
        border-radius: 10px;
        padding: 20px;
        max-width: 500px;
        width: 100%;
        max-height: 80%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        overflow-y: auto;
        color: var(--primary-color);
    }

    .modal-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .modal-description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .modal-footer {
        display: flex;
        align-items: center;
    }

    .modal-image {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 10px;
    }

    .modal-info {
        display: flex;
        flex-direction: column;
    }

    .modal-name {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
    }

    .modal-position {
        font-size: 12px;
        margin: 0;
    }

    .close-button {
        background: var(--primary-color);
        border: none;
        color: var(--secondary-color);
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;
    }
</style>
