<script>
    import Hero from "../components/Hero.svelte";
    import Milestone from "../components/Milestone.svelte";
    import TitleSection from "../components/TitleSection.svelte";
    import TextSection from "../components/TextSection.svelte";
    import Events from "../components/Events.svelte";
    import ImagesSlider from "../components/ImagesSlider.svelte";
    import { urlRoot } from "../constants";
    import { onMount } from "svelte";

    let isLoaded = false;

    const heroSection = {
        en: {
            heading: "I HELP BUSINESS OWNERS AND LEADERS, TO CREATE STRATEGIC ROAD MAP TO SUCCESS AND HAPPINESS.",
            button: "MEET MUNG"
        },
        mm: {
            heading: "လုပ်ငန်းရှင်နှင့် ခေါင်းဆောင်များအား အောင်မြင်ပျော်ရွှင်မှုအတွက် မဟာဗျူဟာမြောက်လမ်းပြမြေပုံများ တည်ဆောက်နိုင်ရန် ကူညီပေးနေပါတယ်။ ",
            button: "MEET MUNG"
        },
        link: "/meet-mung"
    };
    const empowerData = {
        title: {
            en: "EMPOWER YOUR VISION, NAVIGATE CHALLENGES, ACHIEVE SUCCESS",
            mm: "အနာဂတ်မျှော်မှန်းချက်ကို မြှင့်တင်ပါ၊ စိန်ခေါ်မှုတွေကို ရှာဖွေပါ၊ အောင်မြင်မှုကို အရယူပါ။"
        },
        paragraphs: {
            en: [
                "Gain clarity, align your vision, and craft a strategic roadmap for success, uncovering underlying beliefs with Business Tools and NLP techniques. Ensure you and your team's unified purpose, foster collaboration, and amplify synergy through business coaching and training.",
                "Empower yourself to make informed decisions, navigate uncertainty, and lead with authenticity and resilience, while fostering a culture of accountability and ownership within your team.",
                "Experience adaptability, overcome challenges, and seize growth opportunities, ensuring sustainable prosperity for your business.",
                "Say goodbye to confusion, inefficiency, and missed opportunities, and welcome clarity, alignment, and empowered leadership into your business journey of success and happiness with my transformative coaching approach."
            ],
            mm: [
                "Business နှင့် NLP နည်းစနစ်တွေကို အသုံးပြုခြင်းဖြင့် သင့်ရဲ့ အရင်းခံယုံကြည်ချက်တွေကို ဖော်ထုတ်ပြီး ရှင်းလင်းမှုရယူပါ၊ သင့်မျှော်မှန်းချက်တွေကို သတ်မှတ်ပါ၊ အောင်မြင်မှုအတွက် မဟာဗျူဟာမြောက် လမ်းပြမြေပုံ တည်ဆောက်ပါ။ Business Coaching နှင့် Training များမှ တဆင့် သင်နှင့် သင့်ရဲ့ team တခုလုံး ရည်ရွယ်ချက် ထပ်တူကျပြီး ပူးပေါင်းဆောင်ရွက်မှု အားကောင်းကောင်းနှင့် ပေါင်းစပ်မှုရလဒ်တွေကို ချဲ့ထွင်ပါ။",
                "လုပ်ငန်းခွင်အတွင်း တာဝန်ယူမှုနှင့် လုပ်ငန်းရှင်စိတ်ရှိမှု အလေ့အထကို ပျိုးထောင်ပြီး မသေချာမှုတွေကြား မှန်ကန်တဲ့ဆုံးဖြတ်ချက်များချရန်နှင့် စစ်မှန်မှု၊ ခံနိုင်ရည်ရှိမှုတို့ဖြင့် အကောင်းဆုံး ဦးဆောင်နိုင်ရန် သင့်အရည်အသွေးကို မြှင့်တင်ပါ။",
                "ရေရှည်တည်တံ့တဲ့ လုပ်ငန်းအောင်မြင်မှုအတွက် စိန်ခေါ်မှုတွေကို ကျော်လွှားပြီး အခွင့်အရေးတွေကို အမိအရ ဖမ်းဆုပ်နိုင်မဲ့ ပြုပြင်ပြောင်းလဲမှုတွေကို ဒီနေ့ပဲ တွေ့ကြုံခံစားလိုက်ပါ။",
                "မရေရာတာတွေ၊ မထိရောက်တာတွေ၊ လက်လွှတ်လိုက်ရတဲ့ အခွင့်အရေးတွေ ဒါတွေကို အပြီးတိုင်နှုတ်ဆက်လိုက်ပါ။ ကျနော့်ရဲ့ တိုးတက်ပြောင်းလဲစေသော Coaching တွေကတဆင့် ရှင်းလင်းတဲ့ အနာဂတ်မျှော်မှန်းချက်၊ အားကောင်းတဲ့ ခေါင်းဆောင်မှုနှင့်အတူ မိတ်ဆွေရဲ့ လုပ်ငန်းအောင်မြင်မှုနှင့် ပျော်ရွှင်မှုတွေကို အတူဖန်တီးကြဖို့ ဖိတ်ခေါ်လိုက်ပါတယ်။"
            ],
        }
    };

    const clientTitle = {
        en: "My Clients",
        mm: "My Clients"
    };

    const clientParagraph = {
        en: [
            "I have been blessed to work with great businesses and leaders. Here are some of the businesses. I've had the privilege to work with"
        ],
        mm: [
            "ဒီနေ့အထိ ကျနော်နှင့် လက်တွဲလာကြတဲ့ စီးပွားရေးလုပ်ငန်းများနှင့် ခေါင်းဆောင်များကို ဖော်ပြပေးလိုက်ပါတယ်။ ဒီလို အတုယူစရာကောင်းတဲ့ ခေါင်းဆောင်များနှင့် အလုပ်တွဲလုပ်ခွင့်ရတာ ကျနော့်အတွက်တော့ အခွင့်ထူး တစ်ခုပါပဲ။"
        ]
    }

    let clients = []

    async function fetchClients() {
        const response = await fetch(`${urlRoot}/clients`);
        clients = await response.json();
        if (clients.length !== 0) {
            clients.forEach(client => {
                client.created = new Date(client.created).toLocaleString();
                client.image = `${urlRoot}/files/${client.image}`;
                client.en = client.name;
                client.mm = client.name;
            });
        }
        isLoaded = true;
    }

    onMount(fetchClients);

</script>

<svelte:head>
    <title>Home | NL Mung | nlmung.com</title>
    <meta name="description" content="HELP BUSINESS OWNERS AND LEADERS, TO CREATE STRATEGIC ROAD MAP TO SUCCESS AND HAPPINESS." />
</svelte:head>
<Hero
    translations={heroSection}
/>
<Milestone />
<TextSection
    title={empowerData.title}
    paragraphs={empowerData.paragraphs}
/>
<Events />
<TitleSection
    title={clientTitle}
    id="clients"
/>
<TextSection
    paragraphs={clientParagraph}
/>
{#if isLoaded}
    <ImagesSlider
        images={clients}
        numOfColumn={6}
        numOfImagePerPage={18}
        autoSlide={false}
    />
{/if}
