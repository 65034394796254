<script>
    import { Router, Route } from "svelte-routing";
    import LearningMilestoneList from "./list.svelte"
    import LearningMilestoneEdit from "./edit.svelte"
    import LearningMilestoneCreate from "./create.svelte"
</script>

<Router>
    <Route path="/" component={LearningMilestoneList} />
    <Route path="/edit/:id" component={LearningMilestoneEdit} />
    <Route path="/create" component={LearningMilestoneCreate} />
</Router>
