<script>
    import { Router, Route } from "svelte-routing";
    import BlogVideoList from "./list.svelte";
    import BlogVideoCreate from "./create.svelte";
    import BlogVideoEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={BlogVideoList} />
    <Route path="/create" component={BlogVideoCreate} />
    <Route path="/edit/:id" component={BlogVideoEdit} />
</Router>
