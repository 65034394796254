<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let articles = [];

    async function fetchArticles() {
        const response = await fetch(`${urlRoot}/articles`);
        articles = await response.json();
        if (articles.length !== 0) {
            articles.forEach(article => {
                article.created = new Date(article.created).toLocaleString();
                article.image = `${urlRoot}/files/${article.image}`;
            });
        }
    }

    async function deleteArticle(id) {
        if (confirm("Are you sure you want to delete this article?")) {
            const response = await fetch(`${urlRoot}/articles/${id}`, {
                method: "DELETE"
            });

            if (response.ok) {
                alert("Article deleted successfully");
                fetchArticles();
            } else {
                alert("Failed to delete product");
            }
        }
    }
    onMount(fetchArticles);
</script>

<Breadcrumb path="/admin/articles" />
<div class="articles-list">
    <h2>Article List</h2>
    <button on:click={() => navigate('/admin/articles/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Content</th>
                <th>Published</th>
                <th>Image</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each articles as article, index (article.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{article.name}</td>
                    <td>{article.description}</td>
                    <td>{article.content}</td>
                    <td>{article.is_published ? "Yes" : "No"}</td>
                    <td><img src={article.image} alt={article.name} width="100" /></td>
                    <td>
                        <button on:click={() => navigate(`/admin/articles/edit/${article.id}`)}>Edit</button>
                        <button on:click={() => deleteArticle(article.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .articles-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
