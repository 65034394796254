<script>
    import { currentLanguage } from "../stores/languageStore";

    export let title = {
        en: "",
        mm: ""
    };
    export let paragraphs = {
        en: [],
        mm: []
    };
    export let button = {
        text: { en: "", mm: "" },
        link: "",
        show: false
    };

    function formatText(text) {
        return text.split("\n").map((paragraph) => {
            return `<p>${paragraph}</p>`;
        }).join("");
    }
</script>

<section class="text-section">
    {#if title.en || title.mm}
        <div class="title-container">
            <h2>{$currentLanguage === 'en' ? title.en : title.mm}</h2>
        </div>
    {/if}
    {#each ($currentLanguage === 'en' ? paragraphs.en : paragraphs.mm) as paragraph}
        <p>{@html formatText(paragraph)}</p>
    {/each}
    {#if button.show}
        <a href={button.link} class="button-cta">
            <div class="circle">
                <img src="/assets/img/icons/arrow-light.svg" alt="Arrow" />
            </div>
            <span>{$currentLanguage === 'en' ? button.text.en : button.text.mm}</span>
        </a>
    {/if}
</section>

<style>
    .text-section {
        padding: 20px;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border-radius: 20px;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        font-weight: 700;
        margin: 0;
    }

    h3 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 20px;
        font-weight: 700;
        margin: 0;
    }

    .button-cta {
        display: inline-flex; /* Adjust display to inline-flex */
        align-items: center;
        background: transparent;
        border: 2px solid var(--tertiary-color);
        border-radius: 10px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary-color);
        text-decoration: none;
        background-color: var(--tertiary-color);
        color: var(--primary-color);
        white-space: nowrap; /* Prevent the button from breaking into multiple lines */
    }

    .circle {
        background: var(--primary-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .button-cta img {
        width: 16px;
        height: 16px;
    }

    p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        margin-bottom: 10px;
    }
</style>
