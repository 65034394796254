<script>
    import { Router, Route } from "svelte-routing";
    import MainCategoryList from "./list.svelte";
    import MainCategoryCreate from "./create.svelte";
    import MainCategoryEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={MainCategoryList} />
    <Route path="/create" component={MainCategoryCreate} />
    <Route path="/edit/:id" component={MainCategoryEdit} />
</Router>
