<script>
    import { currentLanguage } from "../stores/languageStore";
    import { urlRoot } from "../constants";
    import { onMount } from "svelte";

    export let coachingData = {
        title: {
            en: "ONE ON ONE COACHING",
            mm: "ONE ON ONE COACHING"
        },
        stats: [
            { label: { en: "Leaders", mm: "Leaders" }, value: "86+" },
            { label: { en: "Coaching Sessions", mm: "Coaching Sessions" }, value: "239+" }
        ],
        button: {
            en: "GET AN APPOINTMENT",
            mm: "Appointment ရယူရန်",
            link: "/one-on-one-coaching/#appointment",
        },
        description: {
            en: "BEGIN YOUR JOURNEY WITH OUR BUSINESS COACHING, DISC ASSESSMENT AND NLP COACHING\nTransform your business and life strategically with 1 on 1 coaching: Craft a clear roadmap to success and happiness, empower your leadership, and foster a culture of accountability. Let's unlock your full potential together for a prosperous future\nI'm learning from international institutions to serve you.",
            mm: "ပြောင်းလဲမှုခရီးစဉ်ကို ကျနော်တို့ရဲ့ Business Coaching၊ DISC Assessment၊ NLP Coaching တို့နှင့် စတင်လိုက်ပါ။\nOne on One Coaching ဖြင့် သင့်လုပ်ငန်းနှင့်ဘဝကို မဟာဗျူဟာကျကျ ပြောင်းလဲလိုက်ပါ။ တာဝန်ခံမှုအပြည့်နှင့် သင့်ရဲ့ခေါင်းဆောင်မှုစွမ်းရည်ကို မြှင့်တင်ပြီး အောင်မြင်ပျော်ရွှင်မှုအတွက် ရှင်းလင်းတဲ့လမ်းပြမြေပုံ တည်ဆောက်ပါ။ သာယာတဲ့ အနာဂတ်အတွက် သင့်မှာရှိတဲ့ အလားအလာတွေအားလုံးကို ကျနော်တို့နှင့်အတူ ဖော်ထုတ်ကြဖို့ ဖိတ်ခေါ်ပါတယ်။\nမိတ်ဆွေကို အထိရောက်ဆုံး ပံ့ပိုးပေးနိုင်ဖို့ နိုင်ငံတကာရှိ အဖွဲ့အစည်းများကနေ သင်ယူမှုတွေ လုပ်နေပါတယ်။"
        },
        buttonMilestones: {
            en: "SEE MUNG’S MILESTONES",
            mm: "SEE MUNG’S MILESTONES",
            link: "/meet-mung/#milestones"
        },
        logos: [
            "/assets/img/institutions/1.jpeg",
            "/assets/img/institutions/2.jpeg",
            "/assets/img/institutions/3.jpeg",
            "/assets/img/institutions/4.jpeg",
            "/assets/img/institutions/5.jpeg",
            "/assets/img/institutions/6.jpeg",
            "/assets/img/institutions/7.jpeg",
            "/assets/img/institutions/8.jpeg",
            "/assets/img/institutions/9.jpeg",
            "/assets/img/institutions/10.jpeg",
            "/assets/img/institutions/11.jpeg",
        ],
        spotlightImage: "/assets/img/coaching-spotlight.jpg"
    };

    function formatText(text) {
        return text.split("\n").map((paragraph) => {
            return `<p>${paragraph}</p>`;
        }).join("");
    }

    let isMobile = window.innerWidth <= 768;
    let isLoaded = false;

    async function fetchAwards() {
        const response = await fetch(`${urlRoot}/awards`);
        const data = await response.json();
        let logoList = [];
        data.forEach(award => {
            award.image = `${urlRoot}/files/${award.image}`;
            let logo = {
                src: award.image,
                alt: award.name
            }
            logoList.push(logo);
        });
        coachingData.logos = logoList;
        isLoaded = true;
    }

    onMount(fetchAwards);
</script>

<div class="spotlight">
    {#if isMobile}
        <div class="image">
            <img src={coachingData.spotlightImage} alt="Coaching Spotlight" />
        </div>
    {/if}
    <div class="content">
        <div class="title-section">
            <h1>{$currentLanguage === 'en' ? coachingData.title.en : coachingData.title.mm}</h1>
            <a class="appointment-cta" href={coachingData.button.link}>
                <div class="circle">
                    <img src="/assets/img/icons/arrow.svg" alt="Arrow" />
                </div>
                <span>{$currentLanguage === 'en' ? coachingData.button.en : coachingData.button.mm}</span>
            </a>
        </div>
        <div class="stats">
            {#each coachingData.stats as stat}
                <div class="stat">
                    <span class="label">{$currentLanguage === 'en' ? stat.label.en : stat.label.mm}</span>
                    <span class="value">{stat.value}</span>
                </div>
            {/each}
        </div>
        <p class="description">
            {@html formatText($currentLanguage === 'en' ? coachingData.description.en : coachingData.description.mm)}
        </p>
        <div class="logos-milestones">
            {#if isLoaded}
                <div class="logos">
                    {#each coachingData.logos as logo}
                        <img src={logo.src} alt={logo.alt} />
                    {/each}
                </div>
            {/if}
            <a class="cta-milestones" href={coachingData.buttonMilestones.link}>
                <div class="circle">
                    <img src="/assets/img/icons/arrow-light.svg" alt="Arrow" />
                </div>
                <span>{$currentLanguage === 'en' ? coachingData.buttonMilestones.en : coachingData.buttonMilestones.mm}</span>
            </a>
        </div>
    </div>
    {#if !isMobile}
        <div class="image">
            <img src={coachingData.spotlightImage} alt="Coaching Spotlight" />
        </div>
    {/if}
</div>

<style>
    .spotlight {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        border-radius: 20px;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .spotlight {
            flex-direction: column;
            padding: 20px;
        }
    }

    .content {
        flex: 1;
        padding-right: 20px;
    }

    @media (max-width: 768px) {
        .content {
            padding-right: 0;
            margin-bottom: 20px;
        }
    }

    .image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 768px) {
        .image {
            max-width: 100%;
        }
    }

    .image img {
        border-radius: 20px;
        max-width: 100%;
        max-height: 400px;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        .image img {
            border-radius: 20px;
            margin-bottom: 20px;
        }
    }

    .title-section {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        .title-section {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 36px;
        margin: 0;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 24px;
        }
    }

    .appointment-cta {
        display: flex;
        align-items: center;
        background: transparent;
        border: 2px solid var(--tertiary-color);
        border-radius: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary-color);
    }

    @media (max-width: 768px) {
        .appointment-cta {
            margin-top: 20px;
        }
    }

    .circle {
        background: var(--tertiary-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        .circle {
            width: 24px;
            height: 24px;
        }
    }

    .appointment-cta img {
        width: 16px;
        height: 16px;
    }

    .stats {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        width: 100%;
    }

    @media (max-width: 768px) {
        .stats {
            flex-direction: column;
        }
    }

    .stat {
        background: var(--tertiary-color);
        border-radius: 12px;
        padding: 20px;
        text-align: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        .stat {
            flex: 0 0 100%;
            margin-bottom: 20px;
        }
    }

    a {
        text-decoration: none;
    }

    .value {
        font-size: 36px;
        font-weight: 700;
        color: var(--primary-color);
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .value {
            font-size: 24px;
        }
    }

    .label {
        font-size: 16px;
        color: var(--primary-color);
        display: block;
    }

    @media (max-width: 768px) {
        .label {
            font-size: 14px;
        }
    }

    .logos-milestones {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        flex-wrap: nowrap;
    }

    @media (max-width: 768px) {
        .logos-milestones {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .logos {
        display: flex;
        gap: 10px;
        flex-wrap: nowrap;
        overflow-x: auto;
        max-width: 70%; /* Adjust as necessary */
    }

    @media (max-width: 768px) {
        .logos {
            max-width: 100%;
        }
    }

    .logos img {
        background-color: var(--secondary-color);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        .logos img {
            width: 20px;
            height: 20px;
        }
    }

    .cta-milestones {
        display: flex;
        align-items: center;
        background: transparent;
        border: 2px solid var(--tertiary-color);
        background-color: var(--tertiary-color);
        color: var(--primary-color);
        border-radius: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        font-weight: 500;
        flex-shrink: 0; /* Prevent the button from shrinking */
    }

    @media (max-width: 768px) {
        .cta-milestones {
            margin-top: 20px;
        }
    }

    .cta-milestones .circle {
        background: var(--primary-color);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        .cta-milestones .circle {
            width: 24px;
            height: 24px;
        }
    }

    .cta-milestones img {
        width: 16px;
        height: 16px;
    }

    .description {
        font-size: 18px;
        margin-top: 20px;
        line-height: 1.5;
    }

    @media (max-width: 768px) {
        .description {
            font-size: 16px;
        }
    }
</style>
