<script>
    import { Router, Route } from "svelte-routing";
    import PressList from "./list.svelte";
    import PressCreate from "./create.svelte";
    import PressEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={PressList} />
    <Route path="/create" component={PressCreate} />
    <Route path="/edit/:id" component={PressEdit} />
</Router>
