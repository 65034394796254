<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { onMount } from "svelte";
    export let id;

    let name = "";
    let description = "";
    let tag = "";
    let time = "";
    let link = "";
    let created = "";
    let image = "";
    let location = "";
    let trainer = "";
    let previewImage = "";

    async function fetchEvent() {
        const response = await fetch(`${urlRoot}/events/${id}`);
        const event = await response.json();
        name = event.name;
        description = event.description;
        tag = event.tag;
        time = event.time;
        link = event.link;
        created = event.created;
        location = event.location;
        trainer = event.trainer;
        image = event.image; // Bind the image ID
        previewImage = image ? `${urlRoot}/files/${image}` : ""; // Set the preview image URL
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                previewImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const fileInput = document.getElementById("image");

        let imageId = image; // Use the existing image ID by default

        if (fileInput.files.length > 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInput.files[0]);

            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });

            if (!fileResponse.ok) {
                alert("Failed to upload image");
                return;
            }

            const fileData = await fileResponse.json();
            imageId = fileData.id; // Update the image ID if a new image is uploaded
        }

        const data = {
            name,
            description,
            tag,
            time,
            link,
            created,
            location,
            trainer,
            image: imageId
        };

        const response = await fetch(`${urlRoot}/events/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Event updated successfully");
            navigate("/admin/events");
        } else {
            alert("Failed to update event");
        }
    }

    onMount(fetchEvent);
</script>

<Breadcrumb path="/admin/events/edit" />
<div class="data-form">
    <h2>Update Event</h2>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name" bind:value={name} required/>
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <input type="text" id="description" bind:value={description} required/>
        </div>
        <div class="form-group">
            <label for="tag">Tag</label>
            <input type="text" id="tag" bind:value={tag} required/>
        </div>
        <div class="form-group">
            <label for="time">Time</label>
            <input type="text" id="time" bind:value={time} required/>
        </div>
        <div class="form-group">
            <label for="link">Link</label>
            <input type="text" id="link" bind:value={link} required/>
        </div>
        <div class="form-group">
            <label for="location">Location</label>
            <input type="text" id="location" bind:value={location} required/>
        </div>
        <div class="form-group">
            <label for="trainer">Trainer</label>
            <input type="text" id="trainer" bind:value={trainer} />
        </div>
        <div class="form-group">
            <label for="image">Image (419px x 560px[0.75:1])</label>
            <input type="file" id="image" on:change={handleFileChange} bind:value={image} accept="image/*" />
            {#if previewImage}
                <img src={previewImage} alt="Event Image" class="event-image"/>
            {/if}
        </div>
        <button type="submit">Update</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }

    .event-image {
        margin-top: 10px;
        max-width: 100%;
        height: auto;
    }
</style>
