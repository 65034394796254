import { writable } from "svelte/store";

// Read the current language from localStorage or default to "en"
const initialLanguage = localStorage.getItem("currentLanguage") || "en";

export const currentLanguage = writable(initialLanguage);

// Update localStorage whenever currentLanguage changes
currentLanguage.subscribe((value) => {
    localStorage.setItem("currentLanguage", value);
});

export function switchLanguage() {
    currentLanguage.update((lang) => (lang === "en" ? "mm" : "en"));
}
