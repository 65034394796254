<script>
    import { writable } from 'svelte/store';
    import { afterUpdate } from 'svelte';
    export let totalItems = 0;
    export let itemsPerPage = 10;
    export let currentPage = writable(1);

    let totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [];

    function changePage(page) {
        if (page >= 1 && page <= totalPages) {
            currentPage.set(page);
        }
    }

    function getPages() {
        if (totalPages <= 4) {
            return [...Array(totalPages).keys()].map(i => i + 1);
        } else {
            const pages = [];
            if ($currentPage == 1) {
                pages.push(1, 2, '...', totalPages);
            }
            else if ($currentPage == 2) {
                pages.push(1, 2, 3, '...', totalPages);
            }
            else if ($currentPage == totalPages) {
                pages.push(1, '...', totalPages - 1, totalPages);
            }
            else if ($currentPage == totalPages - 1) {
                pages.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
            }
            else {
                pages.push(1, '...', $currentPage - 1, $currentPage, $currentPage + 1, '...', totalPages);
            }
            return pages;
        }
    }

    $: totalPages = Math.ceil(totalItems / itemsPerPage);
    $: pages = getPages();

    const isMobile = window.innerWidth <= 768;

    afterUpdate(() => {
        pages = getPages();
    });
</script>

<div class="pagination">
    <button class="page-btn" on:click={() => changePage($currentPage - 1)} disabled={$currentPage === 1}>
        {#if isMobile}
            «
        {:else}
            « PREV
        {/if}
    </button>
    {#each pages as page}
        {#if page === '...'}
            <span class="page-number ellipsis">...</span>
        {:else}
            <button 
                class="page-number {($currentPage === page) ? 'current' : ''}" 
                on:click={() => changePage(page)}>
                {page}
            </button>
        {/if}
    {/each}
    <button class="page-btn" on:click={() => changePage($currentPage + 1)} disabled={$currentPage === totalPages}>
        {#if isMobile}
            »
        {:else}
            NEXT »
        {/if}
    </button>
</div>

<style>
    .pagination {
        display: flex;
        justify-content: center;
        gap: 5px;
        margin-top: 5px;
    }

    .page-btn {
        background: var(--tertiary-color);
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        color: #0c1674;
    }

    .page-btn:disabled {
        background: #d3d3d3;
        cursor: not-allowed;
    }

    .page-number {
        background: var(--tertiary-color);
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        color: #0c1674;
        transition: background 0.3s;
    }

    .page-number.current {
        background: var(--primary-color);
        color: var(--secondary-color);
    }

    .ellipsis {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-family: "Roboto Flex", sans-serif;
        color: #0c1674;
    }
</style>
