<script>
    import { currentLanguage } from '../stores/languageStore';
    import { onMount } from 'svelte';
    import { urlRoot } from '../constants';
    export let title = "YOUR SUCCESS NAVIGATOR - NL MUNG";
    export let description = {
        "en": "My mission is to strategically work with business leaders, unlocking their potential, realizing their visions, and helping them attain their next level of success and happiness through coaching and training.",
        "mm": "ကျနော့်ခံယူချက်တာဝန်ကတော့ လုပ်ငန်းဦးဆောင်သူတွေနှင့် မဟာဗျူဟာကျကျ အလုပ်လုပ်ရင်း သူတို့ရဲ့ အလားအလာတွေကိုဖော်ထုတ်၊ အနာဂတ်မျှော်မှန်းချက်တွေကို ရှာဖွေကြပြီး တစ်ဆင့်မြင့်တဲ့ အောင်မြင်ပျော်ရွှင်မှုတွေကိုရရှိအောင် Coaching နှင့် Training များဖြင့် ကူညီပေးဖို့ပဲဖြစ်ပါတယ်။"
    }
    export let buttonText = "GET IN TOUCH";
    export let buttonLink = "/contact-us";

    let socialLinks = [
        { name: 'FACEBOOK', icon: 'assets/img/icons/facebook.svg', link: 'https://www.facebook.com/nlmungbusinesscoach?mibextid=LQQJ4d' },
        { name: 'YOUTUBE', icon: 'assets/img/icons/youtube.svg', link: 'https://youtube.com/@nlmunggualnam?si=8WoWJCvVRe_P0a_Ew' },
        { name: 'TWITTER', icon: 'assets/img/icons/twitter.svg', link: 'https://x.com/NLMungGualnam' },
        { name: 'LINKEDIN', icon: 'assets/img/icons/linkedin.svg', link: 'https://www.linkedin.com/in/mung-gualnam/?originalSubdomain=mm' }
    ];

    let mainCategories = [];

    async function fetchMainCategories() {
        const response = await fetch(`${urlRoot}/main_categories`);
        mainCategories = await response.json();
        console.log('Main Categories:', mainCategories);
    }

    onMount(fetchMainCategories);
</script>

<div class="footer-container">
    <div class="footer-content">
        <h1>{title}</h1>
        <p>{ $currentLanguage === 'en' ? description.en : description.mm }</p>
    </div>
    <div class="footer-button">
        <a href={buttonLink} class="button">
            {buttonText}
            <img src="/assets/img/icons/arrow-up.svg" alt="Arrow" />
        </a>
    </div>
</div>

<div class="footer-links">
    <div class="footer-column left">
        <h2>Home</h2>
        <a href="/#events">Events</a>
        <a href="/#clients">My Clients</a>
    </div>
    <div class="footer-column">
        <h2>Meet Mung</h2>
        <a href="/meet-mung/#milestones">Working Milestones</a>
        <a href="/meet-mung/#learning-milestone">Learning Milestones</a>
        <a href="/meet-mung/#awarding-body">Awarding Bodies</a>
        <a href="/meet-mung/#testimonials">Testimonials</a>
        <a href="/meet-mung/#press">Collaboration</a>
    </div>
    <div class="footer-column left-mobile">
        <h2>1 on 1 Coaching</h2>
        <a href="/one-on-one-coaching/#business-coaching">Business Coaching</a>
        <a href="/one-on-one-coaching/#disc-assessment">DISC Assessment</a>
        <a href="/one-on-one-coaching/#nlp-coaching">NLP Coaching</a>
        <a href="/one-on-one-coaching/#appointment">Get an Appointment</a>
    </div>
    <div class="footer-column">
        <h2>Team Coaching</h2>
        <a href="/team-coaching/#strategic-planning">Strategic Planning</a>
        <a href="/team-coaching/#nlp-4-organization-culture">NLP 4 Organization Culture</a>
        <a href="/team-coaching/#leadership-development">Leadership Development Facilitation</a>
        <a href="/team-coaching/#appointment">Get an Appointment</a>
    </div>
    <div class="footer-column left-mobile">
        <h2>Trainings</h2>
        <a href="/trainings/#leadership-development-training">Leadership Development Training</a>
        <a href="/trainings/#business-development-training">Business Development Training</a>
        <a href="/trainings/#nlp-certification-training">NLP Certification Training</a>
        <a href="trainings/#appointment">Get an Appointment</a>
    </div>
    <div class="footer-column left-mobile">
        <h2>Blogs</h2>
        <a href="/blogs/#quotes">Quotes</a>
        <a href="/blogs/#articles">Articles</a>
        <a href="/blogs/#videos">Videos</a>
    </div>
    <div class="footer-column">
        <h2>Shop</h2>
        {#each mainCategories as category}
            <a href="/shops/#{category.name.toLowerCase().replace(/\s+/g, '-')}">{category.name}</a>
        {/each}
    </div>
</div>

<div class="social-links-container">
    {#each socialLinks as link}
        <a href={link.link} class="social-link" target="_blank">
            <img src={link.icon} alt="{link.name} Icon" class="social-icon" />
            <div class="social-button">
                <img src="/assets/img/icons/arrow-up.svg" alt="Arrow" />
            </div>
            <p>{link.name}</p>
        </a>
    {/each}
</div>

<div class="final-section">
    <p>© 2024 Mung. All rights reserved.</p>
    <div class="final-links">
        <a href="https://www.nlmung.com/articles/view/dd7aabc0-3c84-4534-ae8d-b44eefdc1590" target="_blank">Terms & Conditions</a>
        <span>|</span>
        <a href="https://www.nlmung.com/articles/view/51d2e86b-fc84-4196-9f31-8ad5d0964057" target="_blank">Privacy Policy</a>
    </div>
</div>

<style>
    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        padding: 20px;
        border-radius: 20px;
        margin-top: 20px;
        border: 2px solid var(--primary-color);
    }

    @media (max-width: 768px) {
        .footer-container {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .footer-content {
        flex: 1;
    }

    .footer-content h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 36px;
        font-weight: 600;
        margin: 0 0 10px;
    }

    .footer-content p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        margin: 0;
    }

    .footer-button {
        flex-shrink: 0;
    }

    @media (max-width: 768px) {
        .footer-button {
            margin-top: 20px;
        }
    }

    .footer-button .button {
        display: flex;
        align-items: center;
        background-color: var(--tertiary-color);
        color: var(--primary-color);
        padding: 10px 20px;
        border-radius: 10px;
        text-decoration: none;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    .footer-button .button .icon {
        margin-left: 10px;
        font-size: 20px;
    }

    .footer-button .button:hover {
        background-color: var(--secondary-color)FFF;
        color: var(--primary-color);
    }

    img {
        margin-left: 5px;
    }

    .footer-links {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 20px;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border-radius: 20px;
        border: 2px solid var(--primary-color);
    }

    @media (max-width: 768px) {
        .footer-links {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .left {
        padding-left: 20px;
    }

    .left-mobile {
        padding-left: 0px;
    }

    @media (max-width: 768px) {
        .left-mobile {
            padding-left: 20px;
        }
    }

    .footer-column h2 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 10px;
    }

    .footer-column a {
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        color: var(--primary-color);
        text-decoration: none;
        display: block;
        margin: 5px 0;
    }

    .footer-column a:hover {
        text-decoration: underline;
    }

    .social-links-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 20px;
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    @media (max-width: 768px) {
        .social-links-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .social-link {
        text-decoration: none;
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: var(--secondary-color);
        border-radius: 10px;
        border: 2px solid var(--primary-color);
    }

    .social-link img.social-icon {
        width: 30px;
        height: 30px;
    }

    .social-link p {
        flex: 1;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        font-weight: 600;
        margin: 0 10px;
    }

    .social-link a.social-button {
        grid-template-columns: none;
        display: flex;
        align-items: center;
        background-color: var(--tertiary-color);
        color: var(--primary-color);
        padding: 10px;
        border-radius: 50%;
        text-decoration: none;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    .social-link img {
        background-color: var(--tertiary-color);
        border-radius: 5px;
        padding: 5px;
    }

    .final-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border-radius: 10px;
        border: 2px solid var(--primary-color);
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .final-section {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .final-section p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        margin: 0;
    }

    .final-links {
        display: flex;
        align-items: center;
    }

    .final-links a {
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        color: var(--primary-color);
        text-decoration: none;
        margin-left: 10px;
    }

    .final-links a:hover {
        text-decoration: underline;
    }

    .final-links span {
        margin: 0 5px;
    }
</style>