<script>
    import TitleSection from "./TitleSection.svelte";
    import Pagination from "./Pagination.svelte";
    import TextSection from "./TextSection.svelte";
    import { writable } from "svelte/store";
    import { onMount } from "svelte";
    import { urlRoot } from "../constants";

    import { buildQuery } from "../utils";

    export let title = {
        en: "COLLABORATION",
        mm: "COLLABORATION"
    };

    const appointmentBtn = {
        en: "GET AN APPOINTMENT",
        mm: "Appointment ရယူပါ။",
        link: "/meet-mung/#appointment",
    };

    export let contents = [];

    export let textParagraphs = {
        en: [
            "Let's amplify our impact together! If you'd like to leverage my channels, just give me a heads up. And if you want me to join your channels, I'm more than happy to (whenever time permits).", 
            "Let's coordinate our busy schedules and make magic happen!"
        ],
        mm: [
            "အကျိုးပြုနိုင်မှုကို အတူတူမြှင့်တင်ကြတာပေါ့! ကျနော့် Channel တွေကို အသုံးပြုချင်တယ်ဆိုရင်.. ပြီးတော့ သင့် Channel တွေမှာ ကျနော့်ကို ပါဝင်စေချင်တယ်ဆိုရင် ပျော်ရွှင်စွာနှင့် ကျနော်လက်ခံမှာပါ (အချိန်တော့ ညှိကြတာပေါ့)။ ကျနော်တို့ရဲ့ အချိန်ဇယားတွေကို ညှိနှိုင်းပြီး အကောင်းဆုံးတွေ တင်ဆက်ကြတာပေါ့။"
        ],
    };

    const showModal = writable(false);
    const selectedImageSrc = writable("");

    async function fetchPress() {
        const filters = {
            publish_status: true
        };
        const query = buildQuery(`${urlRoot}/press`, filters);
        const response = await fetch(query);
        contents = await response.json();
        if (contents.length !== 0) {
            contents.forEach((item) => {
                if (item.type === "image") {
                    item.src = `${urlRoot}/files/${item.image}`;
                }
                if (item.type === "video") {
                    item.src = item.video_url;
                }
            });
        }
    }

    function openImageModal(src) {
        selectedImageSrc.set(src);
        showModal.set(true);
    }

    function closeImageModal() {
        showModal.set(false);
    }

    const ITEMS_PER_PAGE = 6;
    const currentPage = writable(1);

    function paginate(items, page = 1, itemsPerPage = ITEMS_PER_PAGE) {
        const offset = (page - 1) * itemsPerPage;
        return items.slice(offset, offset + itemsPerPage);
    }

    $: paginatedContents = paginate(contents, $currentPage);

    onMount(fetchPress);
</script>

<TitleSection
    title={title}
    id="press"
    button={appointmentBtn}
/>

<TextSection
    paragraphs={textParagraphs}
/>

<div class="video-gallery">
    {#each paginatedContents as content}
        <div class="video-container small">
            {#if content.type === 'image'}
                <img 
                    src={content.src} 
                    alt="image" 
                    class="gallery-image" 
                    on:click={() => openImageModal(content.src)} 
                />
            {:else if content.type === 'video'}
                <iframe 
                    width="100%" 
                    height="400" 
                    src={content.src} 
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                ></iframe>
            {/if}
        </div>
    {/each}
</div>

<Pagination
    totalItems={contents.length}
    itemsPerPage={ITEMS_PER_PAGE}
    currentPage={currentPage}
/>

{#if $showModal}
    <div class="modal-overlay" on:click={closeImageModal}>
        <div class="modal-content" on:click|stopPropagation>
            <img src={$selectedImageSrc} alt="Full Image" class="full-image"/>
            <button class="close-button" on:click={closeImageModal}>Close</button>
        </div>
    </div>
{/if}

<style>
    .video-gallery {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 10px;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .video-gallery {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .video-container {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        overflow: hidden;
        color: var(--secondary-color);
    }

    .gallery-image {
        width: 100%;
        height: 400px;
        object-fit: cover;
        cursor: pointer;
    }

    .small {
        grid-column: span 4;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        position: relative;
        background: var(--secondary-color);
        border-radius: 10px;
        padding: 20px;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;
    }

    .full-image {
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: var(--primary-color);
        border: none;
        color: var(--secondary-color);
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
    }
</style>
