<script>
    import TitleSection from "./TitleSection.svelte";
    import Pagination from "./Pagination.svelte";
    import Button from "./Button.svelte";
    import CheckOut from "./CheckOut.svelte";
    import { writable } from "svelte/store";
    import { currentLanguage } from "../stores/languageStore";
    import { afterUpdate, onMount } from "svelte";

    export let title = {
        en: "SHOPS > BOOKS > BPH BOOKS",
        mm: "SHOPS > BOOKS > BPH BOOKS"
    };

    export let id = '';

    export let books = [];
    export let displayMainCategory = false;
    export let mainCategoryDisplayName = '';
    export let mainCategoryName = '';

    export let itemsPerPage = 4;
    const currentPage = writable(1);
    const showModal = writable(false);
    const selectedImageSrc = writable("");
    export const selectedBook = writable(null);
    let paginatedBooks = [];

    function paginateBooks() {
        const start = ($currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        paginatedBooks = books.slice(start, end);
    }

    function checkOut(book) {
        selectedBook.set(book);
    }

    const isMobile = window.innerWidth < 768;

    onMount(paginateBooks);
    afterUpdate(paginateBooks);

    function openImageModal(src) {
        selectedImageSrc.set(src);
        showModal.set(true);
    }

    function closeImageModal() {
        showModal.set(false);
    }
</script>

{#if displayMainCategory}
    <TitleSection
        title={mainCategoryDisplayName}
        backgroundColor={'var(--secondary-color)'}
        textColor={'var(--primary-color)'}
        id={mainCategoryName.toLowerCase().replace(/\s+/g, '-')}
    />
{/if}
<TitleSection
    title={title}
    backgroundColor={'var(--secondary-color)'}
    textColor={'var(--primary-color)'}
    id={id}
/>

{#if $selectedBook}
    <CheckOut
        item={$selectedBook}
    />
{:else}
    <div class="books-gallery">
        {#each paginatedBooks as book}
            <div class="books-container">
                {#if book.media.type === 'video'}
                    <iframe width="100%" height={isMobile ? 200 : 300} src={book.media.src} frameborder="0" allowfullscreen></iframe>
                {:else}
                    <img
                        width="100%"
                        height={isMobile ? 200 : 300}
                        src={book.media.src}
                        alt="Book Image"
                        class="gallery-image" 
                        on:click={() => openImageModal(book.media.src)}
                    />
                {/if}
                <div class="text-section">
                    <h3>{$currentLanguage === 'en' ? book.heading.en : book.heading.mm}</h3>
                    <p>{$currentLanguage === 'en' ? book.description.en : book.description.mm}</p>
                    <p>Price: {book.price} {book.currency}</p>
                    <div class="button-group">
                        <div></div>
                        <div></div>
                        <Button
                            button={book.button}
                            handleClick={() => checkOut(book)}
                        />
                    </div>
                </div>
            </div>
        {/each}
    </div>
{/if}

<Pagination
    totalItems={books.length}
    itemsPerPage={itemsPerPage}
    currentPage={currentPage}
/>

{#if $showModal}
    <div class="modal-overlay" on:click={closeImageModal}>
        <div class="modal-content" on:click|stopPropagation>
            <img src={$selectedImageSrc} alt="Full Image" class="full-image"/>
            <button class="close-button" on:click={closeImageModal}>Close</button>
        </div>
    </div>
{/if}
<style>
    .books-gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .books-gallery {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .books-container {
        display: flex;
        flex-direction: column;
        background-color: var(--primary-color);
        border-radius: 10px;
        overflow: hidden;
        color: var(--secondary-color);
        position: relative; /* Add this */
        padding-bottom: 60px; /* Ensure space for the button */
    }

    @media (max-width: 768px) {
        .books-container {
            border-radius: 10px;
            padding-bottom: 40px; /* Ensure space for the button on mobile */
        }
    }

    .text-section {
        padding: 20px;
        flex-grow: 1; /* Ensure the text section takes available space */
    }

    @media (max-width: 768px) {
        .text-section {
            padding: 10px;
        }
    }

    .books-container h3 {
        margin: 0 0 10px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .books-container h3 {
            font-size: 16px;
        }
    }

    .books-container p {
        margin: 0 0 10px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    @media (max-width: 768px) {
        .books-container p {
            font-size: 12px;
        }
    }

    .button-group {
        position: absolute; /* Add this */
        bottom: 10px; /* Add this */
        right: 10px; /* Add this */
        display: flex;
        gap: 10px; /* Optional, for spacing between buttons */
    }

    @media (max-width: 768px) {
        .button-group {
            bottom: 5px; /* Adjust for mobile */
            right: 5px; /* Adjust for mobile */
        }
    }

    .gallery-image {
        width: 100%;
        height: 400px;
        object-fit: cover;
        cursor: pointer;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        position: relative;
        background: var(--secondary-color);
        border-radius: 10px;
        padding: 20px;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;
    }

    .full-image {
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: var(--primary-color);
        border: none;
        color: var(--secondary-color);
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
    }
</style>
