// src/stores/activeLinkStore.js
import { writable } from "svelte/store";

// Read the active link from localStorage or default to "home"
const initialActiveLink = localStorage.getItem("activeLink") || "home";

export const activeLink = writable(initialActiveLink);

// Update localStorage whenever activeLink changes
activeLink.subscribe((value) => {
    localStorage.setItem("activeLink", value);
});
