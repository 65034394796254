import './App.css';
import './constants.css';
import App from './App.svelte';
import { fetchAndSetColors } from './utils';
import { fetchAndSetPhoneAndEmail } from './constants';

// Fetch and set colors
fetchAndSetColors();
fetchAndSetPhoneAndEmail();

const app = new App({
  target: document.body,
  props: {
    name: 'world'
  }
});

export default app;
