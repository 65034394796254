<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let items = [];

    async function fetchItems() {
        const response = await fetch(`${urlRoot}/awards`);
        items = await response.json();
        if (items.length !== 0) {
            items.forEach(item => {
                item.created = new Date(item.created).toLocaleString();
                item.image = `${urlRoot}/files/${item.image}`;
            });
        }
    }

    async function deleteItem(id) {
        if (confirm("Are you sure you want to delete this award?")) {
            const response = await fetch(`${urlRoot}/awards/${id}`, {
                method: "DELETE"
            });

            if (response.ok) {
                alert("Award deleted successfully");
                fetchItems();
            } else {
                alert("Failed to delete award");
            }
        }
    }
    onMount(fetchItems);
</script>

<Breadcrumb path="/admin/awards" />
<div class="items-list">
    <h2>Awards List</h2>
    <button on:click={() => navigate('/admin/awards/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Rank</th>
                <th>Image</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each items as item, index (item.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.rank}</td>
                    <td><img src={item.image} alt={item.name} width="100" /></td>
                    <td>
                        <button on:click={() => navigate(`/admin/awards/edit/${item.id}`)}>Edit</button>
                        <button on:click={() => deleteItem(item.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .items-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
