<script>
  import { navigate } from "svelte-routing";
</script>

<div class="admin-panel">
  <h1>Admin Panel</h1>
  <button on:click={() => navigate('/admin/appointments')}>Manage Appointments</button>
  <button on:click={() => navigate('/admin/payments')}>Manage Orders</button>
  <button on:click={() => navigate('/admin/testimonials')}>Manage Testimonials</button>
  <br>
  <button on:click={() => navigate('/admin/main-categories')}>Manage Main Categories</button>
  <button on:click={() => navigate('/admin/sub-categories')}>Manage Sub Categories</button>
  <button on:click={() => navigate('/admin/products')}>Manage Products</button>
  <br>
  <button on:click={() => navigate('/admin/quotes')}>Manage Quotes</button>
  <button on:click={() => navigate('/admin/articles')}>Manage Articles</button>
  <button on:click={() => navigate('/admin/blog-videos')}>Manage Blog Videos</button>
  <br>
  <button on:click={() => navigate('/admin/stats')}>Manage Stats</button>
  <button on:click={() => navigate('/admin/events')}>Manage Events</button>
  <button on:click={() => navigate('/admin/clients')}>Manage Clients</button>
  <button on:click={() => navigate('/admin/collaborations')}>Manage Collaborations</button>
  <button on:click={() => navigate('/admin/learning-milestones')}>Manage Learning Milestones</button>
  <button on:click={() => navigate('/admin/awards')}>Manage Awards</button>
  <button on:click={() => navigate('/admin/banners')}>Manage Banners</button>
  <br>
  <button on:click={() => navigate('/admin/colors')}>Manage Colors</button>
  <button on:click={() => navigate('/admin/constants')}>Manage Constants</button>
</div>

<style>
  .admin-panel {
      padding: 20px;
  }

  h1 {
      font-family: "Roboto Flex", sans-serif;
      color: var(--primary-color);
  }

  button {
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--primary-color);
      color: var(--secondary-color);
      font-family: "Roboto Flex", sans-serif;
      cursor: pointer;
      margin-top: 5px;
  }

  button:hover {
      background-color: var(--tertiary-color);
  }
</style>
