<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { onMount } from "svelte";
    export let id;

    let name = "";
    let description = "";
    let video_url = "";
    let is_published = false;
    let created = "";

    async function fetchBlogVideo() {
        const response = await fetch(`${urlRoot}/blog_videos/${id}`);
        const data = await response.json();
        name = data.name;
        description = data.description;
        video_url = data.video_url;
        is_published = data.is_published;
        created = data.created;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        video_url = video_url.replace("youtu.be", "www.youtube.com/embed");

        const data = {
            name,
            description,
            video_url,
            is_published,
            created
        };

        const response = await fetch(`${urlRoot}/blog_videos/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Blog Video updated successfully");
            navigate("/admin/blog-videos");
        } else {
            alert("Failed to update blog video.");
        }
    }

    onMount(fetchBlogVideo);
</script>

<Breadcrumb path="/admin/blog-videos/update" />
<div class="data-form">
    <h2>Update Blog Video</h2>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name" bind:value={name} />
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <input type="text" id="description" bind:value={description} />
        </div>
        <div class="form-group">
            <label for="video_url">Video URL</label>
            <input type="text" id="video_url" bind:value={video_url} />
        </div>
        <div class="form-group">
            <label for="is_published">Is Published</label>
            <input type="checkbox" id="is_published" bind:checked={is_published} />
        </div>
        <button type="submit">Update</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }

    .event-image {
        margin-top: 10px;
        max-width: 100%;
        height: auto;
    }
</style>

