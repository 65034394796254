<script>
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';

    export let path = '';

    let breadcrumbs = writable([]);

    onMount(() => {
        const paths = path.split('/').filter(Boolean);
        const breadcrumbList = paths.map((segment, index) => {
            return {
                name: segment.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
                link: '/' + paths.slice(0, index + 1).join('/')
            };
        });
        breadcrumbs.set(breadcrumbList);
    });
</script>

<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        {#each $breadcrumbs as breadcrumb, index}
            {#if index === $breadcrumbs.length - 1}
                <li class="breadcrumb-item active" aria-current="page">{breadcrumb.name}</li>
            {:else}
                <li class="breadcrumb-item">
                    <a href={breadcrumb.link}>{breadcrumb.name}</a>
                </li>
            {/if}
        {/each}
    </ol>
</nav>

<style>
    .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        list-style: none;
        background-color: #e9ecef;
        border-radius: 0.25rem;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        color: #6c757d;
        content: "/";
    }
    .breadcrumb-item a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }
    .breadcrumb-item a:hover {
        color: #0056b3;
        text-decoration: underline;
    }
    .breadcrumb-item.active {
        color: #6c757d;
    }
</style>
