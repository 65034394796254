<script>
    import { Router, Route } from "svelte-routing";
    import AwardList from "./list.svelte";
    import AwardCreate from "./create.svelte";
    import AwardEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={AwardList} />
    <Route path="/create" component={AwardCreate} />
    <Route path="/edit/:id" component={AwardEdit} />
</Router>
