<script>
    import { Router, Route } from "svelte-routing";
    import SubCategoryList from "./list.svelte";
    import SubCategoryCreate from "./create.svelte";
    import SubCategoryEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={SubCategoryList} />
    <Route path="/create" component={SubCategoryCreate} />
    <Route path="/edit/:id" component={SubCategoryEdit} />
</Router>
