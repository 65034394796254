<script>
    import CategoryButtons from "../../components/CategoryButtons.svelte";
    import Books from "../../components/Books.svelte";
    import Testimonial from "../../components/Testimonial.svelte";
    import { urlRoot } from "../../constants";
    import { onMount } from "svelte";

    let myBook = {
        en: "SHOPS > BOOKS > MY BOOKS",
        mm: "SHOPS > BOOKS > MY BOOKS"
    };

    let bphBooks = {
        en: "SHOPS > BOOKS > BPH BOOKS",
        mm: "SHOPS > BOOKS > BPH BOOKS"
    };

    let selectionBooks = {
        en: "SHOP > BOOKS > SELECTION BOOKS",
        mm: "SHOP > BOOKS > SELECTION BOOKS"
    };

    let mungEvents = {
        en: "SHOPS > UPCOMING EVENTS >  MUNG'S EVENTS",
        mm: "SHOPS > UPCOMING EVENTS >  MUNG'S EVENTS"
    };

    let breakthroughEvents = {
        en: "SHOPS > UPCOMING EVENTS > BREAKTHROUGH' EVENTS",
        mm: "SHOPS > UPCOMING EVENTS > BREAKTHROUGH' EVENTS"
    };

    let othersEvents = {
        en: "SHOPS > UPCOMING EVENTS > OTHER EVENTS",
        mm: "SHOPS > UPCOMING EVENTS > OTHER EVENTS"
    };

    let selfPaceLearning = {
        en: "SHOPS > SELF PACE LEARNING",
        mm: "SHOPS > SELF PACE LEARNING"
    };

    let bags = {
        en: "SHOPS > ACCESSORIES > BAGS",
        mm: "SHOPS > ACCESSORIES > BAGS"
    }

    let cards = {
        en: "SHOPS > ACCESSORIES > CARDS",
        mm: "SHOPS > ACCESSORIES > CARDS"
    }

    let shirts = {
        en: "SHOPS > ACCESSORIES > SHIRTS",
        mm: "SHOPS > ACCESSORIES > SHIRTS"
    }

    let buttons = [];
    let mainCategories = [];
    let subCategories = [];
    let products = [];
    let dataLoaded = false;

    async function fetchMainCategories() {
        const response = await fetch(`${urlRoot}/main_categories`);
        mainCategories = await response.json();
        console.log('Main Categories:', mainCategories);
    }

    async function fetchSubCategories() {
        const response = await fetch(`${urlRoot}/sub_categories`);
        subCategories = await response.json();

        subCategories.forEach(subCategory => {
            // Set up breadcrumb
            subCategory.breadcrumb = {
                en: `${subCategory.main_category.name} > ${subCategory.name}`,
                mm: `${subCategory.main_category.name} > ${subCategory.name}`
            };
        });
        console.log('Sub Categories:', subCategories);

    }

    async function fetchProducts() {
        const response = await fetch(`${urlRoot}/products`);
        products = await response.json();
        console.log('Products:', products);
    }

    function updateButtons() {
        buttons = [];

        // Add main categories to buttons
        for (let mainCategory of mainCategories) {
            buttons.push({
                text: { en: mainCategory.name, mm: mainCategory.name },
                active: false,
                sub: false
            });
        }

        // Initialize subcategory indices for each main category
        let subCategoryIndices = new Array(mainCategories.length).fill(0);

        let remainingSubCategories = subCategories.length;

        // Add subcategories in a round-robin fashion
        while (remainingSubCategories > 0) {
            for (let i = 0; i < mainCategories.length; i++) {
                let mainCategory = mainCategories[i];
                let subCategoriesForMain = subCategories.filter(sub => sub.main_category_id === mainCategory.id);

                if (subCategoryIndices[i] < subCategoriesForMain.length) {
                    let subCategory = subCategoriesForMain[subCategoryIndices[i]];
                    buttons.push({
                        text: { en: subCategory.name, mm: subCategory.name },
                        active: false,
                        sub: true,
                        link: `/shops/#${subCategory.name.toLowerCase().replace(/\s+/g, '-')}`,
                        main: mainCategory.name
                    });
                    subCategoryIndices[i]++;
                    remainingSubCategories--;
                }
            }
        }

        

        // if subcategory does not have any products, remove the subcategory button

        for (let i = 0; i < subCategories.length; i++) {
            let subCategory = subCategories[i];
            let productsForSub = products.filter(product => product.sub_category_id === subCategory.id);
            if (productsForSub.length === 0) {
                buttons = buttons.filter(button => button.text.en !== subCategory.name);
            }
        }

        // if main category does not have any subcategories, remove the main category button
        // also remove if main category have subcategories but all subcategories do not have any products

        for (let i = 0; i < mainCategories.length; i++) {
            let mainCategory = mainCategories[i];
            let subCategoriesForMain = subCategories.filter(sub => sub.main_category_id === mainCategory.id);
            if (subCategoriesForMain.length === 0) {
                buttons = buttons.filter(button => button.text.en !== mainCategory.name);
            }
            else {
                let hasProducts = false;
                for (let j = 0; j < subCategoriesForMain.length; j++) {
                    let subCategory = subCategoriesForMain[j];
                    let productsForSub = products.filter(product => product.sub_category_id === subCategory.id);
                    if (productsForSub.length > 0) {
                        hasProducts = true;
                        break;
                    }
                }
                if (!hasProducts) {
                    buttons = buttons.filter(button => button.text.en !== mainCategory.name);
                }
            }
        }
    }

    function updateProducts() {
        products.forEach(product => {
            product.heading = {
                en: product.heading,
                mm: product.heading
            };
            product.description = {
                en: product.description,
                mm: product.description
            };
            product.button = {
                text: {
                    en: "BUY NOW",
                    mm: "BUY NOW"
                }
            };
            if (product.type === "video") {
                product.media = {
                    src: product.video_link,
                    type: "video"
                };
            } else {
                product.media = {
                    src: `${urlRoot}/files/${product.image}`,
                    type: "image"
                };
            }
        });
        const mainCategoryMap = {};
        subCategories.forEach(subCategory => {
            subCategory.books = products.filter(product => product.sub_category_id === subCategory.id);
            if (subCategory.books && subCategory.books.length > 0) {
                console.log('Subcategory:', subCategory);
                if (!mainCategoryMap[subCategory.main_category_id]) {
                    subCategory.displayMainCategory = true;
                    mainCategoryMap[subCategory.main_category_id] = true;
                }
                else {
                    subCategory.displayMainCategory = false;
                }
            }
            subCategory.mainCategoryDisplayName = {
                en: subCategory.main_category.name,
                mm: subCategory.main_category.name
            };
            console.log(`Subcategory ${subCategory.name} books:`, subCategory.books);
        });
    }

    let numColumns = 0;
    async function fetchAll() {
        await fetchMainCategories();
        await fetchSubCategories();
        await fetchProducts();
        updateProducts();
        updateButtons();
        // update numColumns to be the number of main categories
        numColumns = mainCategories.length;
        console.log('Buttons:', buttons);
        console.log('Num Columns:', numColumns);
        dataLoaded = true;
    }

    onMount(fetchAll);
</script>

<svelte:head>
    <title>Shop | NL Mung | nlmung.com</title>
    <meta name="description" content="Anchor your knowledge with selective accessories, books, and self-paced learning materials from a renowned business coach. Enhance your style and skills with expert-curated products." />
</svelte:head>

<CategoryButtons buttons={buttons} />

{#if dataLoaded}
    {#each subCategories as subCategory}
        {#if subCategory.books && subCategory.books.length > 0}
            <Books
                title={subCategory.breadcrumb}
                id={subCategory.name.toLowerCase().replace(/\s+/g, '-')}
                books={subCategory.books}
                displayMainCategory={subCategory.displayMainCategory}
                mainCategoryDisplayName={subCategory.mainCategoryDisplayName}
                mainCategoryName={subCategory.main_category.name}
            />
        {/if}
    {/each}
{/if}

<Testimonial />
