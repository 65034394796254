<script>
    import { Router, Route } from "svelte-routing";
    import ContentHomeList from "./list.svelte";
    import ContentHomeCreate from "./create.svelte";
    import ContentHomeEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={ContentHomeList} />
    <Route path="/create" component={ContentHomeCreate} />
    <Route path="/edit/:id" component={ContentHomeEdit} />
</Router>
