<script>
    import { createEventDispatcher } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../constants";

    let username = "";
    let password = "";
    const dispatch = createEventDispatcher();

    async function login() {
        console.log("Logging in...");
        const response = await fetch(`${urlRoot}/token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `username=${username}&password=${password}`,
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem("token", data.access_token);
            dispatch("login");
            navigate("/admin");
        } else {
            alert("Invalid credentials");
        }
    }
</script>

<div class="login-container">
    <h2>Login</h2>
    <form on:submit|preventDefault={login} class="login-form">
        <div class="form-group">
            <label for="username">Username</label>
            <input type="text" id="username" bind:value={username} placeholder="Username" required />
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" id="password" bind:value={password} placeholder="Password" required />
        </div>
        <button type="submit" class="login-btn">Login</button>
    </form>
</div>

<style>
    .login-container {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    .login-btn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .login-btn:hover {
        background-color: var(--tertiary-color);
    }
</style>
