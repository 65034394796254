<script>
    import { currentLanguage } from "../stores/languageStore";
    import { navigate } from "svelte-routing";
    import { urlRoot, mainPhoneNumber, mainEmail } from "../constants";

    let name = "";
    let phoneNumber = "";
    let email = "";
    let address = "";
    let receipt = "";
    let quantity = 1;
    export let item = {};

    async function handleSubmit(event) {
        event.preventDefault();

        const fileInput = document.getElementById("receipt");

        if (fileInput.files.length === 0) {
            alert("Please upload a receipt.");
            return;
        }

        // Create FormData to handle file upload
        const fileFormData = new FormData();
        fileFormData.append("file", fileInput.files[0]); // Change key to "file"

        const fileResponse = await fetch(`${urlRoot}/files`, {
            method: "POST",
            body: fileFormData
        });

        if (fileResponse.ok) {
            const fileData = await fileResponse.json();
            const receiptPhotoId = fileData.id;

            const data = {
                product_name: item.heading.en,
                amount: item.price * quantity,
                currency: item.currency,
                name: name,
                phone_number: phoneNumber,
                email: email,
                address: address,
                quantity: quantity,
                receipt_photo: receiptPhotoId
            };

            const response = await fetch(`${urlRoot}/payments`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                alert("Order placed successfully!");
                navigate("/shops");
                location.reload();
            } else {
                alert("Failed to place order.");
            }
        } else {
            alert("Failed to upload receipt.");
        }
    }

    async function cancelOrder() {
        navigate("/shops");
        location.reload();
    }
</script>

<div class="checkout-form-container">
    <form class="checkout-form" on:submit={handleSubmit}>
        <div class="header">
            <div class="header-left">
                <h1>BILLING AND SHIPPING</h1>
            </div>
            <div class="header-right">
                <!-- cancel button-->
                <img src="/assets/img/icons/cart.svg" alt="Cart Icon" />
            </div>
        </div>
        <div class="form-content">
            <div class="contact-info">
                <h2>CONTACT INFORMATION</h2>
                <p><strong>CALL US:</strong><br /><small>{ $mainPhoneNumber }</small></p>
                <p><strong>EMAIL US:</strong><br /><small>{ $mainEmail }</small></p>
            </div>
            <div class="product-info">
                <h2>PRODUCT INFORMATION</h2>
                <table class="product-table">
                    <thead>
                        <tr>
                            <th>PRODUCT NAME</th>
                            <th>SUBTOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="product-details">
                                {#if item.media.type === "image"}
                                    <img src={item.media.src} alt={item.heading.en} />
                                {:else}
                                    <img src="/assets/img/event1.jpeg" alt="Book Icon" />
                                {/if}
                                <div>
                                    <p>{item.heading.en}</p>
                                    <p>
                                        <input
                                            type="number"
                                            class="quantity"
                                            name="quantity"
                                            id="quantity"
                                            bind:value={quantity}
                                        >
                                    </p>
                                </div>
                            </td>
                            <td>{item.price} {item.currency}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>SUBTOTAL</td>
                            <td>{item.price * quantity} {item.currency}</td>
                        </tr>
                        <tr>
                            <td>SHIPPING</td>
                            <td>{item.shipping_type}</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>
                            <td>{item.price * quantity} {item.currency}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="form-fields">
                <div class="form-group">
                    <label for="name">
                        {#if $currentLanguage === "en"}
                            YOUR NAME:
                        {:else}
                            အမည်:
                        {/if}
                    </label>
                    <input type="text" id="name" bind:value={name} placeholder="NL Mung" required />
                </div>
                <div class="form-group">
                    <label for="phoneNumber">
                        {#if $currentLanguage === "en"}
                            YOUR PHONE NUMBER:
                        {:else}
                            ဖုန်းနံပါတ်:
                        {/if}
                    </label>
                    <input type="text" id="phoneNumber" bind:value={phoneNumber} placeholder="094414589" required />
                </div>
                <div class="form-group">
                    <label for="email">
                        {#if $currentLanguage === "en"}
                            YOUR EMAIL (IF YOU HAVE ANY):
                        {:else}
                            အီးမေးလ် (ရှိလျှင်):
                        {/if}
                    </label>
                    <input type="email" id="email" bind:value={email} placeholder="maungthu@gmail.com" required />
                </div>
                <div class="form-group">
                    <label for="address">
                        {#if $currentLanguage === "en"}
                            YOUR ADDRESS:
                        {:else}
                            လိပ်စာ:
                        {/if}
                    </label>
                    <textarea id="address" bind:value={address} placeholder="Yangon" required></textarea>
                </div>
                <div class="form-group">
                    <label for="receipt">
                        {#if $currentLanguage === "en"}
                            UPLOAD YOUR RECEIPT (SCREENSHOT OR PHOTO):
                        {:else}
                            ငွေပေးပို့ထားသည့် receipt အား screenshot (သို့) ဓာတ်ပုံရိုက်၍ တင်ပါ:
                        {/if}
                    </label>
                    <input type="file" id="receipt" bind:value={receipt} accept="image/*" required />
                </div>
                <button type="submit">
                    {#if $currentLanguage === "en"}
                        PLACE ORDER
                    {:else}
                        အော်ဒါတင်မည်
                    {/if}
                </button>
                <button type="button" on:click={cancelOrder}>
                    {#if $currentLanguage === "en"}
                        CANCEL ORDER
                    {:else}
                        ORDER ပယ်ဖျက်မည်
                    {/if}
                </button>
            </div>
        </div>
    </form>
</div>

<style>
    .checkout-form-container {
        justify-content: space-between;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        padding: 20px;
        border: var(--primary-color) 2px solid;
        border-radius: 20px;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .checkout-form-container {
            border-radius: 10px;
        }
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header-left {
            flex-direction: column;
            gap: 10px;
        }
    }

    .header-left h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        font-weight: 600;
        line-height: 87px;
        color: var(--primary-color);
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        .header-left h1 {
            font-size: 24px;
            line-height: 36px;
            margin-right: 0;
        }
    }

    .header-right {
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header-right {
            flex-direction: column;
            gap: 10px;
        }

        .header-right img {
            width: 80px;
            height: 80px;
        }
    }

    .form-content {
        display: flex;
        width: 100%;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .form-content {
            flex-direction: column;
            gap: 20px;
        }
    }

    .contact-info {
        flex: 1;
        padding: 20px;
    }

    .contact-info h2 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .contact-info p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 21px;
        margin: 10px 0;
    }

    .contact-info p small {
        font-size: 18px;
    }

    .product-info {
        flex: 2;
        gap: 20px;
    }

    @media (max-width: 768px) {
        .product-info {
            gap: 10px;
        }
    }

    .product-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
    }

    .product-table th, .product-table td {
        border: 1px solid #ddd;
        padding: 8px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        text-align: left;
    }

    .product-table th {
        background-color: var(--tertiary-color);
        color: var(--secondary-color);
        font-weight: bold;
    }

    .product-details {
        display: flex;
        align-items: center;
    }

    .product-details img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .product-details div {
        display: flex;
        flex-direction: column;
    }

    .product-table tfoot tr {
        border-top: 2px solid #ddd;
    }

    .form-fields {
        flex: 3;
        display: block;
        gap: 20px;
        padding: 20px;
        align-items: start;
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    .form-group label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        color: var(--primary-color);
        font-weight: 500;
        margin-bottom: 5px;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        padding: 10px;
        border-radius: 10px;
        border: none;
        background-color: var(--tertiary-color);
        color: var(--secondary-color) !important;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    .form-group input {
        height: 40px;
    }

    .form-group select {
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24\' fill=\'black\'%3E%3Cpath d=\'M7 10l5 5 5-5z\'/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 16px;
        color: var(--secondary-color);
    }

    .form-group select::before {
        opacity: 0.5;
    }

    .form-group select:focus {
        outline: none;
    }

    .form-group select option {
        background-color: var(--tertiary-color);
        color: var(--secondary-color);
    }

    .form-group input::placeholder,
    .form-group select::placeholder,
    .form-group textarea::placeholder {
        color: var(--secondary-color);
        opacity: 0.5;
    }

    .textarea-group {
        grid-column: span 2;
        grid-row: span 2;
    }

    .form-group textarea {
        resize: none;
        height: 100px;
    }

    .checkbox-group {
        display: flex;
        align-items: center;
    }

    .checkbox-group input {
        margin-right: 10px;
    }

    .checkbox-group label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        color: var(--tertiary-color);
    }

    button {
        grid-column: span 2;
        padding: 20px;
        border-radius: 10px;
        border: none;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        cursor: pointer;
        text-transform: uppercase;
        margin-top: 10px;
    }

    button:hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border: var(--primary-color) 2px solid;
    }

    .quantity {
        height: 40px;
    }
</style>
