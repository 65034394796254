<script>
    import { currentLanguage } from "../stores/languageStore";
    import { urlRoot, mainPhoneNumber, mainEmail } from "../constants";

    let name = "";
    let role = "";
    let business = "";
    let modeOfContact = "";
    let contactInformation = "";
    let message = "";
    export let id = "";
    export let appointmentFor = "";
    let agreeToBeContacted = true;

    function isValidEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (modeOfContact === "Email" && !isValidEmail(contactInformation)) {
            if ($currentLanguage === "en") {
                alert("Please enter a valid email address.");
            } else {
                alert("ကျေးဇူးပြု၍မှန်ကန်သောအီးမေးလ်လိပ်စာကိုထည့်ပါ။");
            }
            return;
        }

        const data = {
            name: name,
            role: role,
            business: business,
            mode_of_contact: modeOfContact,
            contact_information: contactInformation,
            appointment_for: appointmentFor,
            message: message
        };
        const response = await fetch(`${urlRoot}/appointment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            clearForm();
            alertMessage();
        } else {
            alert("Failed to send message");
        }
    }

    function alertMessage() {
        if ($currentLanguage === "en") {
            alert("Our team will contact you soon!");
        } else {
            alert("ကျနော်တို့ team က မကြာခင် ဆက်သွယ်ပါလိမ့်မယ်!");
        }
    }

    function clearForm() {
        name = "";
        role = "";
        business = "";
        modeOfContact = "";
        contactInformation = "";
        message = "";
        appointmentFor = "";
        agreeToBeContacted = true;
    }
</script>

<div class="appointment-form-container" id={id}>
    <form class="appointment-form" on:submit|preventDefault={handleSubmit}>
        <div class="header">
            <div class="header-left">
                <h1>
                    {#if $currentLanguage === "en"}
                        APPOINTMENT FORM
                    {:else}
                        Appointment ရယူရန်
                    {/if}
                </h1>
                <img src="/assets/img/icons/conversation.svg" alt="Conversation Icon" />
            </div>
            <div class="header-right">
                <input type="checkbox" id="agreeToBeContacted" bind:checked={agreeToBeContacted} required />
                <label for="agreeToBeContacted">
                    {#if $currentLanguage === "en"}
                        I agree to be contacted
                    {:else}
                        ဆက်သွယ်မှုလက်ခံရန် သဘောတူပါသည်
                    {/if}
                </label>
            </div>
        </div>
        <div class="form-content">
            <div class="contact-info">
                <h2>CONTACT INFORMATION</h2>
                <p><strong>CALL US:</strong><br /><small>{ $mainPhoneNumber }</small></p>
                <p><strong>EMAIL US:</strong><br /><small>{ $mainEmail }</small></p>
            </div>
            <div class="form-fields">
                <div class="form-group">
                    <label for="name">
                        {#if $currentLanguage === "en"}
                            YOUR NAME:
                        {:else}
                            အမည်:
                        {/if}
                    </label>
                    <input type="text" id="name" bind:value={name} placeholder="NL Mung" required />
                </div>
                <div class="form-group">
                    <label for="modeOfContact">
                        {#if $currentLanguage === "en"}
                            MODE OF CONTACT:
                        {:else}
                            ဆက်သွယ်ရမည့်နည်းလမ်း:
                        {/if}
                    </label>
                    <select id="modeOfContact" bind:value={modeOfContact} required>
                        <option value="" disabled selected class="placeholder">Choose one</option>
                        <option value="Email">Email</option>
                        <option value="Phone">Phone</option>
                        <option value="Viber">Viber</option>
                        <option value="WhatsApp">WhatsApp</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="role">
                        {#if $currentLanguage === "en"}
                            YOUR ROLE:
                        {:else}
                            ရာထူး:
                        {/if}
                    </label>
                    <input type="text" id="role" bind:value={role} placeholder="Founder and CEO" required />
                </div>
                <div class="form-group">
                    <label for="contactInformation">
                        {#if $currentLanguage === "en"}
                            YOUR CONTACT INFORMATION:
                        {:else}
                            အီးမေး/ဖုန်းနံပါတ်:
                        {/if}
                    </label>
                    <input type="text" id="contactInformation" bind:value={contactInformation} placeholder="Enter your email or phone number or both" required />
                </div>
                <div class="form-group">
                    <label for="business">
                        {#if $currentLanguage === "en"}
                            YOUR BUSINESS:
                        {:else}
                            လုပ်ငန်း/အဖွဲ့အစည်း:
                        {/if}
                    </label>
                    <input type="text" id="business" bind:value={business} placeholder="Breakthrough Company Ltd." required />
                </div>
                <div class="form-group">
                    <label for="appointmentFor">
                        {#if $currentLanguage === "en"}
                            WHAT IS YOUR APPOINTMENT FOR:
                        {:else}
                            ရယူလိုသည့်ဝန်ဆောင်မှု:
                        {/if}
                    </label>
                    <select id="appointmentFor" bind:value={appointmentFor} required>
                        {#if appointmentFor === "One on One Coaching"}
                            <option value="One on One Coaching" selected>One on One Coaching</option>
                        {:else if appointmentFor === "Team Coaching"}
                            <option value="Team Coaching" selected>Team Coaching</option>
                        {:else if appointmentFor === "Training"}
                            <option value="Training" selected>Training</option>
                        {:else if appointmentFor === "Collaboration"}
                            <option value="Collaboration" selected>Collaboration</option>
                        {:else}
                            <option value="" disabled selected class="placeholder">Choose one</option>
                        {/if}
                        {#if appointmentFor !== "One on One Coaching"}
                            <option value="One on One Coaching">One on One Coaching</option>
                        {/if}
                        {#if appointmentFor !== "Team Coaching"}
                            <option value="Team Coaching">Team Coaching</option>
                        {/if}
                        {#if appointmentFor !== "Training"}
                            <option value="Training">Training</option>
                        {/if}
                        {#if appointmentFor !== "Collaboration"}
                            <option value="Collaboration">Collaboration</option>
                        {/if}
                    </select>
                </div>
                <div class="form-group textarea-group">
                    <label for="message">
                        {#if $currentLanguage === "en"}
                            YOUR MESSAGE:
                        {:else}
                            အကြောင်းအရာ:
                        {/if}
                    </label>
                    <textarea id="message" bind:value={message} placeholder="Enter Your Message Here ....." required></textarea>
                </div>
                <button type="submit">SEND YOUR MESSAGE</button>
            </div>
        </div>
    </form>
</div>

<style>
    .appointment-form-container {
        justify-content: space-between;
        background-color: var(--secondary-color);
        color: var(--primary-color);
        padding: 20px;
        border: var(--primary-color) 2px solid;
        border-radius: 20px;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .appointment-form-container {
            padding: 10px;
        }
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header-left {
            margin-bottom: 10px;
        }
    }

    .header-left h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        font-weight: 600;
        line-height: 87px;
        color: var(--primary-color);
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        .header-left h1 {
            font-size: 24px;
            line-height: 36px;
        }
    }

    .placeholder {
        color: var(--secondary-color);
        opacity: 0.5;
    }

    .header-left img {
        width: 50px;
    }

    @media (max-width: 768px) {
        .header-left img {
            width: 40px;
        }
    }

    .header-right {
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        .header-right {
            margin-top: 10px;
        }
    }

    .header-right label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 25.78px;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        .header-right label {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .header-right input {
        width: 20px;
        height: 20px;
    }

    .header-right input:checked {
        background-color: var(--tertiary-color);
    }

    @media (max-width: 768px) {
        .header-right input {
            width: 18px;
            height: 18px;
        }
    }

    .form-content {
        display: flex;
        width: 100%;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .form-content {
            flex-direction: column;
        }
    }

    .contact-info {
        flex: 1;
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        .contact-info {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .contact-info h2 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 24px;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        .contact-info h2 {
            font-size: 20px;
        }
    }

    .contact-info p {
        font-family: "Roboto Flex", sans-serif;
        font-size: 21px;
        margin: 10px 0;
    }

    @media (max-width: 768px) {
        .contact-info p {
            font-size: 18px;
        }
    }

    .contact-info p small {
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .contact-info p small {
            font-size: 16px;
        }
    }

    .form-fields {
        flex: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto auto 1fr;
        gap: 20px;
        align-items: start;
    }

    @media (max-width: 768px) {
        .form-fields {
            flex: 1;
            display: block;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 768px) {
        .form-group {
            margin-bottom: 20px;
        }
    }

    .form-group label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        color: var(--primary-color);
        font-weight: 500;
        margin-bottom: 5px;
    }

    @media (max-width: 768px) {
        .form-group label {
            font-size: 16px;
        }
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        padding: 10px;
        border-radius: 10px;
        border: none;
        background-color: var(--tertiary-color);
        color: var(--secondary-color) !important;
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
    }

    @media (max-width: 768px) {
        .form-group input,
        .form-group select,
        .form-group textarea {
            font-size: 16px;
        }
    }

    .form-group input {
        height: 40px;
    }

    @media (max-width: 768px) {
        .form-group input {
            height: 60px;
        }
    }

    .form-group select {
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'black\'%3E%3Cpath d=\'M7 10l5 5 5-5z\'/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 16px;
        color: var(--secondary-color);
    }

    @media (max-width: 768px) {
        .form-group select {
            height: 60px;
        }
    }

    .form-group select::before {
        opacity: 0.5;
    }

    .form-group select:focus {
        outline: none;
    }

    .form-group select option {
        background-color: var(--tertiary-color);
        color: var(--secondary-color);
    }

    .form-group input::placeholder,
    .form-group select::placeholder,
    .form-group textarea::placeholder {
        color: var(--secondary-color);
        opacity: 0.5;
    }

    .textarea-group {
        grid-column: span 2;
        grid-row: span 2;
    }

    @media (max-width: 768px) {
        .textarea-group {
            grid-column: span 1;
            grid-row: span 2;
        }
    }

    .form-group textarea {
        resize: none;
        height: 100px;
    }

    @media (max-width: 768px) {
        .form-group textarea {
            height: 80px;
        }
    }

    .checkbox-group {
        display: flex;
        align-items: center;
    }

    .checkbox-group input {
        margin-right: 10px;
    }

    .checkbox-group label {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        color: var(--tertiary-color);
    }

    button {
        grid-column: span 2;
        padding: 20px;
        border-radius: 10px;
        border: none;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        cursor: pointer;
        text-transform: uppercase;
    }

    @media (max-width: 768px) {
        button {
            padding: 10px;
            font-size: 16px;
        }
    }

    button:hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border: var(--primary-color) 2px solid;
    }
</style>
