<script>
    import { Router, Route } from "svelte-routing";
    import ColorList from "./list.svelte"
    import ColorEdit from "./edit.svelte"
</script>

<Router>
    <Route path="/" component={ColorList} />
    <Route path="/edit/:id" component={ColorEdit} />
</Router>
