<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { onMount } from "svelte";

    let name = "";
    let description = "";
    let type = "";
    let video_url = "";
    let is_published = false;
    let previewImage = "";
    let types = [
        "image",
        "video"
    ]

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                previewImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const fileInput = document.getElementById("image");
        let imageId = null;

        if (fileInput.files.length !== 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInput.files[0]);

            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });

            if (!fileResponse.ok) {
                alert("Failed to upload image");
                return;
            }

            const fileData = await fileResponse.json();
            imageId = fileData.id;
        }

        video_url = video_url.replace("youtu.be", "www.youtube.com/embed");
        const data = {
            name,
            description,
            type,
            video_url,
            is_published,
            image: imageId
        };

        const response = await fetch(`${urlRoot}/press`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Press created successfully");
            navigate("/admin/collaborations");
        } else {
            alert("Failed to create press.");
        }
    }
</script>

<Breadcrumb path="/admin/collaborations/create" />
<div class="data-form">
    <h2>Create Press</h2>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name" bind:value={name} required />
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <input type="text" id="description" bind:value={description} required />
        </div>
        <div class="form-group">
            <label for="type">Type</label>
            <select id="type" bind:value={type}>
                <option value="">Select Type</option>
                {#each types as type}
                    <option value={type}>{type}</option>
                {/each}
            </select>
        </div>
        <div class="form-group">
            <label for="video_url">Video URL</label>
            <input type="text" id="video_url" bind:value={video_url} />
        </div>
        <div class="form-group">
            <label for="is_published">Is Published</label>
            <input type="checkbox" id="is_published" bind:checked={is_published} />
        </div>
        <div class="form-group">
            <label for="image">Image (600px x 400px[1.5:1])</label>
            <input type="file" id="image" on:change={handleFileChange} accept="image/*" required />
            {#if previewImage}
                <img src={previewImage} alt="Selected Image" class="event-image"/>
            {/if}
        </div>
        <button type="submit">Create</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }

    .event-image {
        margin-top: 10px;
        max-width: 100%;
        height: auto;
    }

    select {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }
</style>

