<script>
    import { Router, Route } from "svelte-routing";
    import EventList from "./list.svelte";
    import EventCreate from "./create.svelte";
    import EventEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={EventList} />
    <Route path="/create" component={EventCreate} />
    <Route path="/edit/:id" component={EventEdit} />
</Router>
