<script>
    import { Router, Route } from "svelte-routing";
    import ConstantList from "./list.svelte"
    import ConstantEdit from "./edit.svelte"
</script>

<Router>
    <Route path="/" component={ConstantList} />
    <Route path="/edit/:id" component={ConstantEdit} />
</Router>
