<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let products = [];

    async function fetchProducts() {
        const response = await fetch(`${urlRoot}/products`);
        products = await response.json();
        if (products.length !== 0) {
            products.forEach(product => {
                product.created = new Date(product.created).toLocaleString();
                product.image = `${urlRoot}/files/${product.image}`;
            });
        }
    }

    async function deleteProduct(id) {
        if (!confirm("Are you sure you want to delete this product?")) {
            return;
        }
        const response = await fetch(`${urlRoot}/products/${id}`, {
            method: "DELETE"
        });

        if (response.ok) {
            alert("Product deleted successfully");
            fetchProducts();
        } else {
            alert("Failed to delete product");
        }
    }
    onMount(fetchProducts);
</script>

<Breadcrumb path="/admin/products" />
<div class="products-list">
    <h2>Products List</h2>
    <button on:click={() => navigate('/admin/products/create')}>Create New</button>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Currency</th>
                <th>Video Link</th>
                <th>Type</th>
                <th>Shipping Type</th>
                <th>Stock</th>
                <th>Image</th>
                <th>Sub Category</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each products as product, index (product.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{product.heading}</td>
                    <td>{product.description}</td>
                    <td>{product.price}</td>
                    <td>{product.currency}</td>
                    <td>{product.video_link}</td>
                    <td>{product.type}</td>
                    <td>{product.shipping_type}</td>
                    <td>{product.stock}</td>
                    <td><img width="276" src={product.image} alt="Product Image" class="event-image"/></td>
                    <td>{product.sub_category.name}</td>
                    <td>
                        <button on:click={() => navigate(`/admin/products/edit/${product.id}`)}>Edit</button>
                        <button on:click={() => deleteProduct(product.id)}>Delete</button>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .products-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
