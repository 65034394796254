<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { onMount } from "svelte";
    export let id;

    let heading = "";
    let description = "";
    let price = 0;
    let currency = "";
    let video_link = "";
    let previewImage = "";
    let type = "";
    let stock = 0;
    let image = "";
    let sub_category_id = "";  
    let created = "";
    let shipping_type = "FREE SHIPPING";
    let shipping_types = [
        "FREE SHIPPING",
        "PAID BY CLIENT"
    ];
    let types = [
        "image",
        "video"
    ];

    async function fetchProduct() {
        const response = await fetch(`${urlRoot}/products/${id}`);
        const product = await response.json();
        heading = product.heading;
        description = product.description;
        price = product.price;
        currency = product.currency;
        video_link = product.video_link;
        type = product.type;
        image = product.image;
        stock = product.stock;
        sub_category_id = product.sub_category_id;
        shipping_type = product.shipping_type;
        previewImage = product.image ? `${urlRoot}/files/${product.image}` : "";
        created = product.created;
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                previewImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const fileInput = document.getElementById("image");

        let imageId = image; // Use the existing image ID by default

        if (fileInput.files.length > 0) {
            const fileFormData = new FormData();
            fileFormData.append("file", fileInput.files[0]);

            const fileResponse = await fetch(`${urlRoot}/files`, {
                method: "POST",
                body: fileFormData
            });

            if (!fileResponse.ok) {
                alert("Failed to upload image");
                return;
            }

            const fileData = await fileResponse.json();
            imageId = fileData.id; // Update the image ID if a new image is uploaded
        }

        if (video_link && video_link.includes("youtu.be")) {
            video_link = video_link.replace("youtu.be", "www.youtube.com/embed");
        }

        const data = {
            heading,
            description,
            price,
            currency,
            video_link,
            type,
            stock,
            sub_category_id,
            created,
            shipping_type,
            image: imageId
        };

        const response = await fetch(`${urlRoot}/products/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            alert("Product updated successfully");
            navigate("/admin/products");
        } else {
            alert("Failed to update product.");
        }
    }

    let subCategories = [];
    async function fetchSubCategories() {
        const response = await fetch(`${urlRoot}/sub_categories`);
        subCategories = await response.json();
    }

    async function  fetchAll() {
        await fetchProduct();
        await fetchSubCategories();
    }
    onMount(fetchAll);
</script>

<Breadcrumb path="/admin/products/edit"/>
<div class="data-form">
    <h2>Update Product</h2>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="heading">Name</label>
            <input type="text" id="heading" bind:value={heading} />
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <input type="text" id="description" bind:value={description} />
        </div>
        <div class="form-group">
            <label for="price">Price</label>
            <input type="number" id="price" bind:value={price} />
        </div>
        <div class="form-group">
            <label for="currency">Currency</label>
            <input type="text" id="currency" bind:value={currency} />
        </div>
        <div class="form-group">
            <label for="video_link">Video Link</label>
            <input type="text" id="video_link" bind:value={video_link} />
        </div>
        <div class="form-group">
            <label for="image">Image (447px x 400px [1.21:1])</label>
            <input type="file" id="image" on:change={handleFileChange} bind:value={image} accept="image/*" />
            {#if previewImage}
                <img src={previewImage} alt="Selected Image" class="event-image"/>
            {/if}
        </div>
        <div class="form-group">
            <label for="type">Type</label>
            <select id="type" bind:value={type} required>
                <option value="">Select Type</option>
                {#each types as type}
                    <option value={type}>{type}</option>
                {/each}
            </select>
        </div>
        <div class="form-group">
            <label for="shipping_type">Shipping Type</label>
            <select id="shipping_type" bind:value={shipping_type} required>
                <option value="">Select Shipping Type</option>
                {#each shipping_types as shipping_type}
                    <option value={shipping_type}>{shipping_type}</option>
                {/each}
            </select>
        </div>
        <div class="form-group">
            <label for="stock">Stock</label>
            <input type="number" id="stock" bind:value={stock} />
        </div>
        <div class="form-group">
            <label for="sub_category_id">Sub Category</label>
            <select id="sub_category_id" bind:value={sub_category_id}>
                {#each subCategories as subCategory}
                    <option value={subCategory.id}>{subCategory.name}</option>
                {/each}
            </select>
        </div>
        <button type="submit">Update</button>
    </form>
</div>

<style>
    .data-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    select {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }

    .event-image {
        margin-top: 10px;
        max-width: 100%;
        height: auto;
    }
</style>
