<script>
    import { onMount } from "svelte";
    import { urlRoot } from "../../../constants";
    import { downloadCSV, buildQuery, downloadFile } from "../../../utils";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let payments = [];
    let startDate = "";
    let endDate = "";
    let productName = "";
    let customerName = "";
    let resolveStatus = "";
    const titles = [
        "Ordered Date",
        "Product Name",
        "Amount",
        "Quantity",
        "Currency",
        "Customer Name",
        "Phone Number",
        "Email",
        "Address",
        "Order Status",
        "Receipt Photo"
    ];

    const headers = [
        "created",
        "product_name",
        "amount",
        "quantity",
        "currency",
        "name",
        "phone_number",
        "email",
        "address",
        "resolve_status",
        "receipt_url"
    ];

    async function fetchPayments() {
        const filters = { 
            start_date: startDate,
            end_date: endDate,
            product_name: productName,
            customer_name: customerName,
            resolved: resolveStatus
        };
        const query = buildQuery(`${urlRoot}/payments`, filters);

        const response = await fetch(query);
        payments = await response.json();
        payments.forEach(payment => {
            payment.created = new Date(payment.created).toLocaleString();
            payment.receipt_url = `${urlRoot}/files/${payment.receipt_photo}`;
            payment.resolve_status = payment.resolved ? "Resolved" : "Not Resolved";
        });
    }

    async function resolvePayment(id) {
        const response = await fetch(`${urlRoot}/payments/${id}/resolve`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ resolved: true })
        });
        if (response.ok) {
            fetchPayments();
        } else {
            console.error("Failed to resolve payment");
        }
    }

    function clearFilters() {
        startDate = "";
        endDate = "";
        productName = "";
        customerName = "";
        resolveStatus = "";
        fetchPayments();
    }

    onMount(fetchPayments);
</script>

<Breadcrumb path="/admin/orders" />
<div class="filter-container">
    <label>
        Start Date:
        <input type="date" bind:value={startDate} />
    </label>
    <label>
        End Date:
        <input type="date" bind:value={endDate} />
    </label>
    <label>
        Product Name:
        <input type="text" bind:value={productName} placeholder="Product Name" />
    </label>
    <label>
        Customer Name:
        <input type="text" bind:value={customerName} placeholder="Customer Name" />
    </label>
    <label>
        Resolved Status:
        <select bind:value={resolveStatus}>
            <option value="">Any</option>
            <option value="true">Resolved</option>
            <option value="false">Not Resolved</option>
        </select>
    </label>
    <button on:click={fetchPayments}>Apply Filters</button>
    <button on:click={clearFilters}>Clear Filters</button>
    <button on:click={downloadCSV(payments, titles, headers, 'payments.csv')}>Download CSV</button>
</div>
<div class="payment-list">
    <h2>Order List</h2>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Ordered Date</th>
                <th>Product Name</th>
                <th>Amount</th>
                <th>Quantity</th>
                <th>Currency</th>
                <th>Customer Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Address</th>
                <th>Order Status</th>
                <th>Receipt Photo</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {#each payments as payment, index (payment.id)}
                <tr>
                    <td>{index + 1}</td>
                    <td>{payment.created}</td>
                    <td>{payment.product_name}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.quantity}</td>
                    <td>{payment.currency}</td>
                    <td>{payment.name}</td>
                    <td>{payment.phone_number}</td>
                    <td>{payment.email}</td>
                    <td>{payment.address}</td>
                    <td>
                        {payment.resolve_status}
                    </td>
                    <td>
                        <button on:click={() => downloadFile(`${payment.receipt_url}`)}>
                            Download
                        </button>
                        <a href={`${payment.receipt_url}`} target="_blank">
                            See Receipt
                        </a>
                    </td>
                    <td>
                        {#if !payment.resolved}
                            <button on:click={() => resolvePayment(payment.id)}>
                                Resolve
                            </button>
                        {/if}
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .filter-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
    }

    .filter-container label {
        display: flex;
        flex-direction: column;
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
    }

    .filter-container input,
    .filter-container select {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .filter-container button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    .filter-container button:hover {
        background-color: var(--tertiary-color);
    }

    .payment-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
