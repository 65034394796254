<script>
    import { onMount } from "svelte";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";
    import { downloadCSV, buildQuery } from "../../../utils";

    let testimonials = [];
    let startDate = "";
    let endDate = "";
    let customerName = "";
    let publishStatus = "";
    const titles = [
        "Testimonial Date",
        "Name",
        "Programs",
        "Take Away",
        "Organization",
        "Differences",
        "Photo",
        "Company Logo",
        "Publish Status"
    ]

    const headers = [
        "created",
        "name",
        "programs",
        "take_away",
        "organization",
        "differences",
        "photo",
        "publish_status"
    ]

    async function fetchTestimonials() {
        const filters = {
            start_date: startDate,
            end_date: endDate,
            customer_name: customerName,
            publish_status: publishStatus
        };
        const query = buildQuery(`${urlRoot}/testimonials`, filters);
        const response = await fetch(query);
        testimonials = await response.json();
        testimonials.forEach(testimonial => {
            testimonial.created = new Date(testimonial.created).toLocaleString();
            testimonial.photo = `${urlRoot}/files/${testimonial.photo}`;
            testimonial.publish_status = testimonial.is_published ? "Published" : "Unpublished";
        });
    }

    async function publishTestimonial(id) {
        const response = await fetch(`${urlRoot}/testimonials/${id}/publish`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ resolved: true })
        });
        if (response.ok) {
            fetchTestimonials();
            alert("Testimonial published successfully");
        } else {
            alert("Failed to publish testimonial");
        }
    }

    async function unpublishTestimonial(id) {
        const response = await fetch(`${urlRoot}/testimonials/${id}/unpublish`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ resolved: false })
        });
        if (response.ok) {
            fetchTestimonials();
            alert("Testimonial unpublished successfully");
        } else {
            alert("Failed to unpublish testimonial");
        }
    }

    async function deleteTestimonial(id) {
        if (confirm("Are you sure you want to delete this testimonial?")) {
            const response = await fetch(`${urlRoot}/testimonials/${id}`, {
                method: "DELETE"
            });
            if (response.ok) {
                fetchTestimonials();
                alert("Testimonial deleted successfully");
            } else {
                alert("Failed to delete testimonial");
            }
        }
    }

    function clearFilters() {
        startDate = "";
        endDate = "";
        customerName = "";
        publishStatus = "";
        fetchTestimonials();
    }

    onMount(fetchTestimonials)
</script>

<Breadcrumb path="/admin/testimonials" />
<div class="filter-container">
    <label>
        Start Date:
        <input type="date" bind:value={startDate} />
    </label>
    <label>
        End Date:
        <input type="date" bind:value={endDate} />
    </label>
    <label>
        Customer Name:
        <input type="text" bind:value={customerName} placeholder="Customer Name" />
    </label>
    <label>
        Published:
        <select bind:value={publishStatus}>
            <option value="">All</option>
            <option value="true">Published</option>
            <option value="false">Unpublished</option>
        </select>
    </label>
    <button on:click={fetchTestimonials}>Apply Filters</button>
    <button on:click={clearFilters}>Clear Filters</button>
    <button on:click={() => downloadCSV(testimonials, titles, headers, "testimonials")}>Download CSV</button>
</div>
<div class="appointment-list">
    <h2>Testimonial List</h2>
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Testimonial Date</th>
                <th>Name</th>
                <th>Role</th>
                <th>Programs</th>
                <th>Take Away</th>
                <th>Organization</th>
                <th>Differences</th>
                <th>Photo</th>
                <th>Publish Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each testimonials as testimonial, index}
                <tr>
                    <td>{index + 1}</td>
                    <td>{testimonial.created}</td>
                    <td>{testimonial.name}</td>
                    <td>{testimonial.role ? testimonial.role : ''}</td>
                    <td>{testimonial.programs}</td>
                    <td>{testimonial.take_away}</td>
                    <td>{testimonial.organization}</td>
                    <td>{testimonial.differences}</td>
                    <td><img src={testimonial.photo} alt={testimonial.name} width="100" /></td>
                    <td>{testimonial.publish_status}</td>
                    <td>
                        {#if testimonial.is_published}
                            <button on:click={() => unpublishTestimonial(testimonial.id)}>Unpublish</button>
                        {:else}
                            <button on:click={() => publishTestimonial(testimonial.id)}>Publish</button>
                        {/if}
                        <button on:click={() => deleteTestimonial(testimonial.id)}>Delete</button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .filter-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
    }

    .filter-container label {
        display: flex;
        flex-direction: column;
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
    }

    .filter-container input,
    .filter-container select {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .filter-container button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    .filter-container button:hover {
        background-color: var(--tertiary-color);
    }
    .appointment-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
        margin-top: 5px;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
