<script>
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";

    let banner_text_en = "";
    let banner_text_mm = "";
    let banner_button_en = "";
    let banner_button_mm = "";
    let banner_image = "";
    let banner_button_link = "";

    async function createContentHome() {
        const response = await fetch(`${urlRoot}/content_home`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                banner_text: { en: banner_text_en, mm: banner_text_mm },
                banner_button: { en: banner_button_en, mm: banner_button_mm },
                banner_image,
                banner_button_link
            })
        });

        if (response.ok) {
            navigate('/admin/content-home');
        } else {
            console.error("Failed to create content home");
        }
    }
</script>

<div class="content-home-form">
    <h2>Create Content Home</h2>
    <form on:submit|preventDefault={createContentHome}>
        <div class="form-group">
            <label>Banner Text (EN)</label>
            <input type="text" bind:value={banner_text_en} required />
        </div>
        <div class="form-group">
            <label>Banner Text (MM)</label>
            <input type="text" bind:value={banner_text_mm} required />
        </div>
        <div class="form-group">
            <label>Banner Button (EN)</label>
            <input type="text" bind:value={banner_button_en} required />
        </div>
        <div class="form-group">
            <label>Banner Button (MM)</label>
            <input type="text" bind:value={banner_button_mm} required />
        </div>
        <div class="form-group">
            <label>Banner Image</label>
            <input type="text" bind:value={banner_image} required />
        </div>
        <div class="form-group">
            <label>Banner Button Link</label>
            <input type="text" bind:value={banner_button_link} required />
        </div>
        <button type="submit">Create</button>
    </form>
</div>

<style>
    .content-home-form {
        max-width: 400px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    label {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        margin-bottom: 5px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    input:focus {
        outline: none;
        border-color: var(--primary-color);
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
