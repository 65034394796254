<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { urlRoot } from "../../../constants";
    import Breadcrumb from "../../../components/Breadcrumb.svelte";

    let blogVideos = [];

    async function fetchBlogVideos() {
        const response = await fetch(`${urlRoot}/blog_videos`);
        blogVideos = await response.json();
    }

    async function deleteBlogVideo(id) {
        if (confirm("Are you sure you want to delete this blog video?")) {
            const response = await fetch(`${urlRoot}/blog_videos/${id}`, {
                method: "DELETE"
            });

            if (response.ok) {
                blogVideos = blogVideos.filter((blogVideo) => blogVideo.id !== id);
            } else {
                alert("Failed to delete blog video.");
            }
        }
    }
    onMount(fetchBlogVideos);
</script>

<Breadcrumb path="/admin/blog-videos" />
<div class="blog-videos-list">
    <h2>Blog Videos</h2>
    <button on:click={() => navigate("/admin/blog-videos/create")}>
        Create Blog Video
    </button>
    <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Video URL</th>
                <th>Is Published</th>
                <th>Created</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each blogVideos as blogVideo}
                <tr>
                    <td>{blogVideo.name}</td>
                    <td>{blogVideo.description}</td>
                    <td>{blogVideo.video_url}</td>
                    <td>{blogVideo.is_published ? "Yes" : "No"}</td>
                    <td>{blogVideo.created}</td>
                    <td>
                        <button on:click={() => navigate(`/admin/blog-videos/edit/${blogVideo.id}`)}>
                            Edit
                        </button>
                        <button on:click={() => deleteBlogVideo(blogVideo.id)}>
                            Delete
                        </button>
                    </td>
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style>
    .blog-videos-list {
        max-width: 1000px;
        margin: 50px auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h2 {
        font-family: "Roboto Flex", sans-serif;
        color: var(--primary-color);
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ccc;
        text-align: left;
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
    }

    th {
        background-color: var(--primary-color);
        color: var(--secondary-color);
    }

    td {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }

    button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        font-family: "Roboto Flex", sans-serif;
        cursor: pointer;
    }

    button:hover {
        background-color: var(--tertiary-color);
    }
</style>
