<script>
    import { Router, Route } from "svelte-routing";
    import ClientList from "./list.svelte";
    import ClientCreate from "./create.svelte";
    import ClientEdit from "./edit.svelte";
</script>

<Router>
    <Route path="/" component={ClientList} />
    <Route path="/create" component={ClientCreate} />
    <Route path="/edit/:id" component={ClientEdit} />
</Router>
