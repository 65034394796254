<script>
    import TitleSection from "./TitleSection.svelte";
    import EventContainer from "./EventContainer.svelte";

    const title = {
        en: "EVENTS",
        mm: "EVENTS"
    };
    export let id = 'events';
</script>

<TitleSection
    title={title}
    id={id}
/>
<EventContainer />
